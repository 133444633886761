<script setup lang="ts">
  import { NativeNavbarOverflowItem } from "@/types/Navbar.ts"
  import { App } from "@capacitor/app"
  import { PluginListenerHandle } from "@capacitor/core"
  import { onUnmounted, watchEffect } from "vue"
  import NativeNavbarMoreMenuLink from "@/components/native/navbar/items/NativeNavbarMoreMenuLink.vue"
  import LogoutButton from "@/components/shared/LogoutButton.vue"
  import { Icon } from "@/components/ui/icons"
  import {
    SheetClose,
    SheetContent,
    SheetHeader,
    SheetTitle,
  } from "@/components/ui/sheet"

  const props = defineProps<{
    isOpen: boolean
    navigationItems: NativeNavbarOverflowItem[]
  }>()

  const emit = defineEmits(["update:isOpen"])

  // when the menu is opened, listen for the back button and hijack it to
  // close the menu.
  let listenerHandle: PluginListenerHandle | null = null
  watchEffect(async () => {
    if (props.isOpen) {
      listenerHandle = await App.addListener("backButton", () => {
        if (props.isOpen) {
          emit("update:isOpen", false)
        }
      })
    } else {
      // When the menu is closed we MUST remove the listener, or it will no
      // longer allow normal navigation using the back button.
      await listenerHandle?.remove()
      listenerHandle = null
    }
  })

  // in case we navigate to somewhere not using the mobile layout while the menu
  // is open, clean up the listener
  onUnmounted(async () => {
    await listenerHandle?.remove()
  })
</script>

<template>
  <SheetContent
    id="native-navbar-more-menu"
    side="right"
    class="w-full overflow-y-auto overscroll-contain rounded-none pt-0"
    hide-close-button
  >
    <SheetHeader
      class="sticky top-0 z-50 flex-row justify-between bg-secondary-200 pt-6"
    >
      <span>&nbsp;</span>
      <SheetTitle class="text-center text-lg">Menu</SheetTitle>
      <SheetClose title="close menu" @click="emit('update:isOpen', false)">
        <Icon name="x"></Icon>
      </SheetClose>
    </SheetHeader>

    <div class="mt-3 grid gap-6">
      <NativeNavbarMoreMenuLink
        v-for="item in navigationItems"
        :key="item.to"
        :item="item"
        @click="emit('update:isOpen', false)"
      />
    </div>
    <LogoutButton class="mt-9 w-full" variant="primary" />
  </SheetContent>
</template>

<style>
  /**
   * I don't like it either but I can't do this calc with tailwind and the style
   * block can't be scoped as that then doesn't affect the underlying shadcn
   * component.
   */
  #native-navbar-more-menu {
    height: calc(100svh - var(--navbar-height, 60px)) !important;
  }
</style>
