/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Portal API Gateway
 * OpenAPI spec version: 2.0.0
 */

export type AgreementsResponseFuelType =
  (typeof AgreementsResponseFuelType)[keyof typeof AgreementsResponseFuelType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AgreementsResponseFuelType = {
  Gas: "Gas",
  Electricity: "Electricity",
} as const
