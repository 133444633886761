<script setup lang="ts">
  import { ExternalRoute } from "@/constants/externalRoute"
  import { Route } from "@/constants/route"
  import { useStepWizard } from "@/lib/useStepWizard"
  import { StepWizard } from "@/components/ui/step-wizard"

  const { modelValue } = useStepWizard()

  const steps: { route: string }[] = [
    { route: `${Route.spayg}/${Route.spaygWhy}` },
    { route: `${Route.spayg}/${Route.spaygSuitability}` },
    { route: `${Route.spayg}/${Route.spaygFuel}` },
    { route: `${Route.spayg}/${Route.spaygSwitchDate}` },
    { route: `${Route.spayg}/${Route.spaygTariff}` },
    { route: `${Route.spayg}/${Route.spaygReview}` },
  ]

  const onLeave = () => {
    window.location.assign(ExternalRoute.websiteHome)
  }
</script>

<template>
  <StepWizard
    :initial-step="modelValue"
    :steps="steps"
    title="Smart Pay As You Go"
    @leave-page="onLeave"
  >
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </StepWizard>
</template>
