<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import { SupportingLabel } from "@/types/InputComponentTypes"
  import { useVModel } from "@vueuse/core"
  import {
    NumberFieldRoot,
    useForwardPropsEmits,
    type NumberFieldRootEmits,
    type NumberFieldRootProps,
  } from "radix-vue"
  import { computed, type HTMLAttributes } from "vue"
  import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
  } from "@/components/ui/form"
  import FormSupportingLabel from "@/components/ui/form/FormSupportingLabel.vue"

  import NumberFieldContent from "./NumberFieldContent.vue"
  import NumberFieldDecrement from "./NumberFieldDecrement.vue"
  import NumberFieldIncrement from "./NumberFieldIncrement.vue"
  import NumberFieldInput from "./NumberFieldInput.vue"

  const props = defineProps<
    NumberFieldRootProps & {
      class?: HTMLAttributes["class"]
      defaultValue?: number
      modelValue?: number
      label: string
      name: string
      hasIcon?: boolean
      disabled?: boolean
      extraLabel?: string
      supportingLabels?: SupportingLabel[]
      keepSupportingLabelsOnError?: boolean
    }
  >()
  const emits = defineEmits<NumberFieldRootEmits>()

  const modelValue = useVModel(props, "modelValue", emits, {
    passive: true,
    defaultValue: props.defaultValue,
  })

  const delegatedProps = computed(() => ({
    defaultValue: props.defaultValue,
    min: props.min,
    max: props.max,
    step: props.step,
    disabled: props.disabled,
  }))

  const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <FormField
    v-model="modelValue"
    :name="name"
    :validate-on-blur="false"
    :validate-on-change="false"
    :validate-on-model-update="false"
  >
    <FormItem v-bind="$attrs">
      <FormControl
        v-slot="{
          error,
          formItemId,
          formMessageId,
          formDescriptionId,
          ariaInvalid,
        }"
      >
        <div class="flex items-baseline justify-between">
          <FormLabel id="number-field-label">{{ label }}</FormLabel>
          <span
            v-if="extraLabel"
            :id="formDescriptionId"
            class="label-text-strong text-secondary-500"
            data-testid="form-extra-label"
            :aria-label="`${extraLabel}.`"
          >
            {{ extraLabel }}
          </span>
        </div>
        <NumberFieldRoot
          v-bind="forwarded"
          :id="formItemId"
          v-model="modelValue"
          aria-labelledby="number-field-label"
          :class="cn('grid gap-1.5', props.class)"
        >
          <NumberFieldContent>
            <div
              v-if="hasIcon"
              data-testid="pound-icon"
              class="body-text absolute left-4 top-1/2 flex -translate-y-1/2 gap-4"
            >
              £
            </div>
            <NumberFieldInput
              :error="error"
              :has-icon="hasIcon"
              :aria-invalid="ariaInvalid"
            />
            <div
              class="absolute right-4 top-1/2 flex h-6 -translate-y-1/2 divide-x-2 sm:h-7"
            >
              <NumberFieldDecrement />
              <NumberFieldIncrement />
            </div>
          </NumberFieldContent>
        </NumberFieldRoot>
        <template
          v-if="
            supportingLabels?.length && (keepSupportingLabelsOnError || !error)
          "
        >
          <FormSupportingLabel
            v-for="(supportingLabel, index) in supportingLabels"
            :id="`${formMessageId}-${index}`"
            :key="`${formMessageId}-${index}`"
            :supporting-label="supportingLabel"
            :data-testid="`form-supporting-label-${index}`"
          >
          </FormSupportingLabel>
        </template>
        <FormMessage />
      </FormControl>
    </FormItem>
  </FormField>
</template>
