<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import {
    DialogContent,
    DialogOverlay,
    DialogPortal,
    useForwardPropsEmits,
    type DialogContentEmits,
    type DialogContentProps,
  } from "radix-vue"
  import { computed, type HTMLAttributes } from "vue"
  import { IconButton } from "@/components/ui/icon-button"

  import { SheetClose, sheetVariants, type SheetVariants } from "."

  interface SheetContentProps extends DialogContentProps {
    class?: HTMLAttributes["class"]
    side?: SheetVariants["side"]
    size?: SheetVariants["size"]
    hideCloseButton?: boolean
  }

  defineOptions({
    inheritAttrs: false,
  })

  const props = defineProps<SheetContentProps>()

  const emits = defineEmits<DialogContentEmits>()

  const delegatedProps = computed(() => {
    const { class: _, side: __, ...delegated } = props

    return delegated
  })

  const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <DialogPortal>
    <DialogOverlay
      class="fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
    />
    <DialogContent
      :class="cn(sheetVariants({ side, size }), props.class)"
      v-bind="{ ...forwarded, ...$attrs }"
    >
      <slot />

      <SheetClose v-if="!hideCloseButton">
        <IconButton
          variant="text"
          icon="x"
          label="Close sheet"
          size="md"
          :class="
            cn(
              'absolute right-5 top-5 text-current hover:bg-transparent active:bg-transparent dark:hover:bg-transparent dark:active:bg-transparent',
              props.class,
            )
          "
          data-testid="sheet-close-btn"
        >
        </IconButton>
      </SheetClose>
    </DialogContent>
  </DialogPortal>
</template>
