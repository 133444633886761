<script setup lang="ts">
  import {
    AccordionRoot,
    useForwardPropsEmits,
    type AccordionRootEmits,
    type AccordionRootProps,
  } from "radix-vue"

  const props = withDefaults(defineProps<AccordionRootProps>(), {
    type: "multiple",
  })
  const emits = defineEmits<AccordionRootEmits>()

  const forwarded = useForwardPropsEmits(props, emits)
</script>

<template>
  <AccordionRoot
    v-bind="forwarded"
    data-testid="accordion"
    class="[&>div:last-child]:border-0"
  >
    <slot />
  </AccordionRoot>
</template>
