<script setup lang="ts">
  import { DateFormats } from "@/lib/dateHelper"
  import { getInputAriaDescribedBy } from "@/lib/inputComponentUtils"
  import { cn } from "@/lib/utils"
  import { SupportingLabel } from "@/types/InputComponentTypes.ts"
  import { DateValue } from "@internationalized/date"
  import { useVModel } from "@vueuse/core"
  import dayjs from "dayjs"
  import { toDate } from "radix-vue/date"
  import { Button } from "@/components/ui/button"
  import { Calendar } from "@/components/ui/calendar"
  import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
  } from "@/components/ui/form"
  import FormSupportingLabel from "@/components/ui/form/FormSupportingLabel.vue"
  import { Icon } from "@/components/ui/icons"
  import {
    Popover,
    PopoverContent,
    PopoverTrigger,
  } from "@/components/ui/popover"

  const props = withDefaults(
    defineProps<{
      modelValue?: string | undefined
      defaultValue?: string | undefined
      dateFormat?: string | undefined
      name: string
      label: string
      required?: boolean
      disabled?: boolean
      initialFocus?: boolean
      placeholder?: string
      supportingLabels?: SupportingLabel[]
      keepSupportingLabelsOnError?: boolean
      showLabel?: boolean
      minValue?: string
      maxValue?: string
      isDateDisabled?: (date: string) => boolean
    }>(),
    {
      initialFocus: false,
      dateFormat: DateFormats.default,
      placeholder: "Pick a date",
      showLabel: true,
    },
  )
  const emits =
    defineEmits<(e: "update:modelValue", payload: string | undefined) => void>()
  const modelValue = useVModel(props, "modelValue", emits, {
    passive: true,
    defaultValue: props.defaultValue,
  })
  const getAriaDescribedBy = (formMessageId: string, error: string) =>
    getInputAriaDescribedBy({ error }, "", formMessageId)

  const internalIsDateDisabled = !props.isDateDisabled
    ? undefined
    : (date: DateValue) =>
        props.isDateDisabled!(dayjs(toDate(date)).format(props.dateFormat))
</script>

<template>
  <FormField
    v-slot="{ field }"
    v-model="modelValue"
    :name="name"
    :validate-on-blur="false"
    :validate-on-change="false"
    :validate-on-model-update="false"
  >
    <FormItem v-bind="$attrs">
      <FormControl v-slot="{ error, formItemId, ariaInvalid, formMessageId }">
        <FormLabel v-if="showLabel">
          {{ label }}
        </FormLabel>
        <Popover>
          <template #default="{ open }">
            <PopoverTrigger as-child>
              <Button
                :id="formItemId"
                :aria-label="label"
                :aria-describedby="getAriaDescribedBy(formMessageId, error)"
                :aria-invalid="ariaInvalid"
                :aria-required="required"
                variant="text"
                :disabled="disabled"
                :class="
                  cn(
                    'relative flex h-12 w-full justify-start rounded-sm border bg-secondary-100 px-4 py-3 pr-12 ring-primary-400 sm:h-13',
                    'font-sans text-sm text-secondary-400 sm:text-base',
                    'hover:border-secondary-400 hover:bg-secondary-100',
                    'focus-visible:border-primary-400 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-primary-400 focus-visible:ring-offset-0',
                    'active:border-primary-400 active:bg-secondary-100 active:ring-1',
                    'disabled:cursor-not-allowed disabled:border-solid disabled:border-secondary-400 disabled:bg-secondary-300 disabled:text-secondary-400',
                    modelValue && 'border-secondary-400',
                    (modelValue || open) &&
                      'text-secondary-700 dark:text-secondary-100 dark:hover:text-secondary-100',
                    open && [
                      'border-primary-400 ring-1 hover:border-primary-400',
                    ],
                    error && [
                      'border-red-400 ring-red-400 hover:border-red-400 focus-visible:border-red-400 focus-visible:ring-red-400 active:border-red-400',
                    ],
                  )
                "
              >
                <Icon
                  :id="`${name}-icon-button`"
                  size="lg"
                  name="Calendar"
                  :class="
                    cn([
                      'absolute top-1/2 -translate-y-1/2  text-secondary-700 focus-visible:outline-offset-2',
                      'focus-visible:outline-primary-400',
                      'dark:focus-visible:outline-primary-300',
                      'right-4',
                    ])
                  "
                />{{ modelValue || placeholder }}
              </Button>

              <template
                v-if="
                  supportingLabels?.length &&
                  (keepSupportingLabelsOnError || !error)
                "
              >
                <FormSupportingLabel
                  v-for="(supportingLabel, index) in supportingLabels"
                  :id="`${formMessageId}-${index}`"
                  :key="`${formMessageId}-${index}`"
                  :supporting-label="supportingLabel"
                  :data-testid="`form-supporting-label-${index}`"
                >
                </FormSupportingLabel>
              </template>
            </PopoverTrigger>
            <PopoverContent
              align="start"
              side="bottom"
              :avoid-collisions="false"
              :side-offset="8"
              class="w-auto p-0"
            >
              <Calendar
                v-bind="{ ...field }"
                :model-value="field.value"
                :initial-focus="initialFocus"
                :date-format="dateFormat"
                class="border-none"
                :min-value="minValue"
                :max-value="maxValue"
                :is-date-disabled="internalIsDateDisabled"
              />
            </PopoverContent>
          </template>
        </Popover>
        <FormMessage />
      </FormControl>
    </FormItem>
  </FormField>
</template>
