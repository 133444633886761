/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Portal API Gateway
 * OpenAPI spec version: 2.0.0
 */
import {
  useMutation,
  type MutationFunction,
  type UseMutationOptions,
  type UseMutationReturnType,
} from "@tanstack/vue-query"
import { unref, type MaybeRef } from "vue"

import { customInstance, type ErrorType } from ".././client"
import type { Error } from ".././model"
import type {
  BankingDetailsRequest,
  BankingDetailsResponse,
} from ".././model/omponents"
import { useMutationOptions } from ".././mutationOptions"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * Banking Details Validation
 * @summary Validate banking details
 */
export const validateBankingDetails = (
  bankingDetailsRequest: MaybeRef<BankingDetailsRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  bankingDetailsRequest = unref(bankingDetailsRequest)

  return customInstance<BankingDetailsResponse>(
    {
      url: `/api/v1/banking-details/validate`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: bankingDetailsRequest,
    },
    options,
  )
}

export const useValidateBankingDetailsMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof validateBankingDetails>>,
    TError,
    { data: BankingDetailsRequest },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof validateBankingDetails>>,
  TError,
  { data: BankingDetailsRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof validateBankingDetails>>,
    { data: BankingDetailsRequest }
  > = (props) => {
    const { data } = props ?? {}

    return validateBankingDetails(data, requestOptions)
  }

  const customOptions = useMutationOptions({ ...mutationOptions, mutationFn })

  return customOptions
}

export type ValidateBankingDetailsMutationResult = NonNullable<
  Awaited<ReturnType<typeof validateBankingDetails>>
>
export type ValidateBankingDetailsMutationBody = BankingDetailsRequest
export type ValidateBankingDetailsMutationError = ErrorType<Error>

/**
 * @summary Validate banking details
 */
export const useValidateBankingDetails = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof validateBankingDetails>>,
    TError,
    { data: BankingDetailsRequest },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof validateBankingDetails>>,
  TError,
  { data: BankingDetailsRequest },
  TContext
> => {
  const mutationOptions = useValidateBankingDetailsMutationOptions(options)

  return useMutation(mutationOptions)
}
