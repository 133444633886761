import { Capacitor } from "@capacitor/core"

const isNative = () => Capacitor.isNativePlatform()

/**
 * If window.mobileApp exists, that means we are currently inside an in-app
 * browser.
 **/
const isInAppBrowser = () => window.mobileApp !== undefined

export default { isNative, isInAppBrowser }
