<script setup lang="ts">
  import {
    NativeNavbarMainItem,
    NativeNavbarOverflowItem,
  } from "@/types/Navbar.ts"
  import { computed, ref } from "vue"
  import { useRoute } from "vue-router"
  import NativeNavbarLink from "@/components/native/navbar/items/NativeNavbarLink.vue"
  import NativeNavbarMoreMenu from "@/components/native/navbar/items/NativeNavbarMoreMenu.vue"
  import { Sheet } from "@/components/ui/sheet"

  const { navigationItems } = defineProps<{
    navigationItems: Array<NativeNavbarMainItem | NativeNavbarOverflowItem>
  }>()

  const mainNavigationItems = computed(() =>
    navigationItems.filter((item) => item.showOnMainNav),
  )

  const overflowNavigationItems = computed(() =>
    navigationItems.filter((item) => !item.showOnMainNav),
  )

  const route = useRoute()
  const isMoreMenuOpen = ref(false)
</script>

<template>
  <nav
    class="z-[60] flex w-full items-center justify-between gap-4 border-t border-secondary-300 bg-secondary-200 px-6 py-2"
  >
    <ul class="contents">
      <li v-for="item in mainNavigationItems" :key="item.to">
        <NativeNavbarLink
          :to="item.to"
          :label="item.label"
          :icon="item.icon"
          :is-active="!isMoreMenuOpen && route.path === item.to"
          @click="isMoreMenuOpen = false"
        />
      </li>

      <li>
        <NativeNavbarLink
          type="button"
          :is-active="isMoreMenuOpen"
          label="More"
          icon="More"
          @click="isMoreMenuOpen = !isMoreMenuOpen"
        />

        <Sheet v-model:open="isMoreMenuOpen">
          <NativeNavbarMoreMenu
            v-model:is-open="isMoreMenuOpen"
            :navigation-items="overflowNavigationItems"
          />
        </Sheet>
      </li>
    </ul>
  </nav>
</template>
