import {
  authSession,
  ensureFreshTokenIfAuthenticated,
} from "@/authentication/authenticationService"
import nativeHelper from "@/lib/nativeHelper.ts"
import TrackingService from "@/services/TrackingService"
import { unref } from "vue"
import { RouteLocationNormalizedGeneric } from "vue-router"

export const beforeEachGuard = async function (
  to: RouteLocationNormalizedGeneric,
) {
  const requiresAnon = to.matched.some((record) => record.meta.requiresAnon)
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)

  const queryString = window.location.search
  if (requiresAnon && authSession.hasRefreshTokenCookie()) {
    return "/"
  }

  /**
   * If we are currently inside an in-app browser, hitting the refresh endpoint
   * can cause conflicts with the app's session cookie so we don't want to do
   * it in that case, plus we can assume we're about to be closed down anyway,
   * since the app will want to handle whatever route we're currently on.
   **/
  if (requiresAuth && !nativeHelper.isInAppBrowser()) {
    await ensureFreshTokenIfAuthenticated()
    const isAuthenticated = unref(authSession.isAuthenticated)

    if (!isAuthenticated) {
      TrackingService.sendEvent("redirectToLogin")
      return `/login${queryString}`
    }
    if (isAuthenticated && to.query.redirect) {
      TrackingService.sendEvent("loginRedirectSuccessful")
      window.location.assign(
        `${decodeURIComponent(to.query.redirect as string)}`,
      )
      return false
    }
  }
}
