<script setup lang="ts">
  import nativeHelper from "@/lib/nativeHelper.ts"
  import { removeLeadingSlash, removeTrailingSlash } from "@/lib/utils.ts"
  import { getMyAccountPath, getPublicWebsiteUrl } from "@/lib/websiteUrls.ts"
  import shouldServeNexus from "@/middleware/shouldServeNexus.ts"
  import {
    isExternalRoute,
    isInternalRoute,
  } from "@/plugins/router/routesHelper.ts"
  import InAppBrowserService from "@/services/InAppBrowser.ts"
  import { InAppBrowser } from "@capgo/inappbrowser"
  import { computed } from "vue"

  const props = defineProps<{
    to: string
    target?: string
  }>()

  const isLiveRoute = computed(() => {
    // the legacy site doesn't have a trailing slash on the home route,
    // we just need to deal with that :(
    const routeForNexusCheck =
      props.to === "/"
        ? removeTrailingSlash(getMyAccountPath())
        : `${getMyAccountPath()}${removeLeadingSlash(props.to)}`

    return shouldServeNexus(
      routeForNexusCheck,
      location.hostname,
      location.hostname,
      import.meta.env,
    )
  })

  // legacy website URLS are opened in the in-app browser but also nexus routes
  // that are not yet live, or are feature flagged
  const isLegacyAppLink = computed(
    () =>
      isExternalRoute(props.to) ||
      (isInternalRoute(props.to) && !isLiveRoute.value),
  )

  // anything in the internal routes that is not explicitly disabled by the
  // shouldServeNexus function is rendered using a router-link
  const isInternalLink = computed(
    () => isInternalRoute(props.to) && isLiveRoute.value,
  )

  const isAnchorElement = computed(() => !isInternalLink.value)

  const correctedUrl = computed(() => {
    // an internal link which is not live should use the legacy my account url,
    // but will have been passed to us as Route.tariff for example and so will
    // not include the hostname or myaccount prefixes, we should add them
    if (isInternalRoute(props.to) && !isLiveRoute.value) {
      return `${getPublicWebsiteUrl()}${removeLeadingSlash(getMyAccountPath())}${removeLeadingSlash(props.to)}`
    }

    return props.to
  })

  const linkAttributes = computed(() =>
    isAnchorElement.value ? { href: correctedUrl.value } : {},
  )

  const onClick = async (event: MouseEvent) => {
    const targetUrl = correctedUrl.value

    if (nativeHelper.isNative() && isLegacyAppLink.value) {
      event.preventDefault()

      const fullyQualifiedUrl = targetUrl.startsWith("http")
        ? targetUrl
        : `${getPublicWebsiteUrl()}${removeLeadingSlash(targetUrl)}`

      await InAppBrowserService.openBrowser(fullyQualifiedUrl)
      await InAppBrowser.addListener(
        "urlChangeEvent",
        InAppBrowserService.onUrlChanged,
      )
    }
  }
</script>

<template>
  <component
    :is="isAnchorElement ? 'a' : 'router-link'"
    v-bind="linkAttributes"
    :to="correctedUrl"
    :target="props.target"
    @click="onClick"
  >
    <slot></slot>
  </component>
</template>
