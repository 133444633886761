<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import { useVModel } from "@vueuse/core"
  import {
    CheckboxIndicator,
    CheckboxRoot,
    useForwardPropsEmits,
    type CheckboxRootEmits,
    type CheckboxRootProps,
  } from "radix-vue"
  import { computed, type HTMLAttributes } from "vue"
  import {
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
  } from "@/components/ui/form"
  import Icon from "@/components/ui/icons/Icon.vue"

  defineOptions({
    inheritAttrs: false,
  })
  const props = defineProps<
    CheckboxRootProps & {
      class?: HTMLAttributes["class"]
      label: string
      name: string
      extraLabel?: string
    }
  >()
  const emits = defineEmits<CheckboxRootEmits>()

  const modelValue = useVModel(props, "checked", emits, {
    passive: true,
  })

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
  })

  const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <FormField
    v-slot="{ value, handleChange }"
    v-model="modelValue"
    type="checkbox"
    :name="name"
  >
    <FormItem
      v-bind="$attrs"
      :class="
        cn(
          'grid grid-cols-[auto,1fr] items-center gap-x-2 space-y-0 text-left',
          props.class,
        )
      "
    >
      <FormControl
        v-slot="{ error, formItemId, ariaInvalid, formDescriptionId }"
      >
        <CheckboxRoot
          v-bind="forwarded"
          :id="formItemId"
          :checked="value"
          :aria-invalid="ariaInvalid"
          :aria-describedby="[extraLabel && formDescriptionId]"
          :class="
            cn(
              'peer my-1 h-5 w-5 shrink-0 self-start rounded-xs border-[1.25px] border-secondary-300 bg-secondary-100 text-secondary-700 ring-offset-background',
              'focus-visible:border-secondary-100 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-400 focus-visible:ring-offset-0',
              'disabled:cursor-not-allowed disabled:border-secondary-400 disabled:bg-secondary-300 disabled:text-secondary-400',
              'data-[state=checked]:text-primary-foreground data-[state=checked]:enabled:border-primary-300 data-[state=checked]:enabled:bg-primary-300',
              'dark:border-secondary-400 dark:bg-secondary-500',
              'dark:data-[state=checked]:enabled:bg-primary-300',
              'dark:focus-visible:border-secondary-500 dark:focus-visible:outline-none dark:focus-visible:ring-primary-300',
              'dark:disabled:border-secondary-500 dark:disabled:bg-secondary-500 dark:disabled:text-secondary-400',
              error &&
                'border-secondary-100 ring-2 ring-red-400 dark:border-secondary-500 dark:ring-red-300',
            )
          "
          @update:checked="handleChange"
        >
          <CheckboxIndicator class="flex items-center justify-center">
            <Icon class="stroke-3" size="md" variant="secondary" name="Check" />
          </CheckboxIndicator>
        </CheckboxRoot>
        <FormLabel :class="cn(!value && 'font-normal')">
          <slot name="label">
            {{ label }}
          </slot>
        </FormLabel>
        <FormDescription
          :class="cn('col-start-2 ')"
          :data-testid="`${name}-checkbox-extra-label`"
        >
          {{ extraLabel }}
        </FormDescription>
        <FormMessage class="col-start-2 pl-0 pt-2" />
      </FormControl>
    </FormItem>
  </FormField>
</template>
