<script setup lang="ts">
  import { useInitStepWizard, useStepWizard } from "@/lib/useStepWizard"
  import { cn } from "@/lib/utils"
  import { StepWizard } from "@/types/StepWizard"
  import { watch, type HTMLAttributes } from "vue"
  import { useRoute } from "vue-router"
  import { IconButton } from "@/components/ui/icon-button"
  import { PageHeader } from "@/components/ui/page-header"

  import CloseWizard from "./CloseWizard.vue"

  const props = defineProps<{
    initialStep?: number
    steps: StepWizard[]
    title: string
    class?: HTMLAttributes["class"]
  }>()
  defineEmits(["leavePage"])

  useInitStepWizard(props.steps, props.initialStep)

  const {
    modelValue,
    isCloseDialogOpen,
    currentComponent,
    steps: wizardSteps,
  } = useStepWizard()

  // sync modelValue with route path when browser back/forward button is used
  const route = useRoute()
  watch(route, (val) => {
    if (wizardSteps.value[modelValue.value].route !== val.path) {
      const stepIndex = wizardSteps.value.findIndex(
        (step) => val.path === step.route,
      )
      modelValue.value = stepIndex > -1 ? stepIndex : modelValue.value
    }
  })
</script>

<template>
  <div
    :class="cn('w-full px-6 py-4 sm:px-18 sm:py-12', props.class)"
    data-testid="step-wizard"
  >
    <div class="flex items-baseline gap-2 sm:justify-between">
      <div class="hidden min-w-[40px] sm:invisible sm:flex" />
      <div class="w-full basis-full sm:max-w-3xl sm:basis-auto">
        <PageHeader :title="title" />
      </div>
      <IconButton
        icon="x"
        label="close"
        size="responsive"
        data-testid="step-wizard-close-button"
        @click="isCloseDialogOpen = true"
      />
    </div>

    <div class="mt-4 flex items-baseline gap-2 sm:mt-6 sm:justify-between">
      <div class="hidden min-w-[40px] sm:invisible sm:flex" />

      <div class="flex w-full flex-col sm:max-w-3xl">
        <slot :step-index="modelValue">
          <component :is="{ ...currentComponent }" v-if="currentComponent" />
        </slot>
      </div>

      <div class="hidden min-w-[40px] sm:invisible sm:flex" />
    </div>
    <CloseWizard v-model="isCloseDialogOpen" @leave-page="$emit('leavePage')" />
  </div>
</template>
