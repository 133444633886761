import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function preventBodyScroll(preventScroll: boolean) {
  if (preventScroll) {
    document.body.classList.add("overflow-hidden")
  } else {
    document.body.classList.remove("overflow-hidden")
  }
}

export const safeJSONParse = (string: string): unknown => {
  try {
    return JSON.parse(string)
  } catch (err) {
    return {
      details: string,
    }
  }
}

export function maskAllExceptLast2(
  code: number | string | null | undefined,
): string {
  if (code == null) return ""
  const stringCode = String(code)
  const masked = stringCode.slice(0, -2).replace(/./g, "*")
  const visible = stringCode.slice(-2)
  return masked + visible
}

export const formatCurrency = (
  amount: number,
  hideDecimals: boolean = false,
) => {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: hideDecimals ? 0 : 2,
    maximumFractionDigits: hideDecimals ? 0 : 2,
  }).format(amount)
}

export const formatCurrencyInPence = (
  amountInPence: number,
  fractionDigits = 2,
) => {
  const penceSymbol = "p"
  const amount = new Intl.NumberFormat("en-GB", {
    style: "decimal",
    maximumFractionDigits: fractionDigits,
  }).format(amountInPence)

  return `${amount}${penceSymbol}`
}

export const removeLeadingSlash = (str: string) => str.replace(/^\/+/, "")
export const removeTrailingSlash = (str: string) => str.replace(/\/$/, "")
export const ensureTrailingSlash = (str: string) => str.replace(/\/?$/, "/")
