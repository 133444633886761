import {
  createRouter,
  createWebHistory,
  Router,
  RouteRecordRaw,
} from "vue-router"

import { beforeMetaTitle } from "./metaTitle"
import { processAutoLogin } from "./processAutoLogin"
import { processRedirectParameter } from "./processRedirectParameter"
import { beforeEachGuard } from "./routerGuards"

const routerFactory = (routes: readonly RouteRecordRaw[]): Router => {
  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
    scrollBehavior() {
      return { top: 0 }
    },
  })

  router.beforeEach(processAutoLogin)
  router.beforeEach(processRedirectParameter)
  router.beforeEach(beforeEachGuard)
  router.beforeEach(beforeMetaTitle)

  return router
}

export default routerFactory
