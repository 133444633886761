<script setup lang="ts">
  import { ExternalRoute } from "@/constants/externalRoute"
  import { DateFormats, formatDate } from "@/lib/dateHelper"
  import { useCotMoveIn } from "@/lib/useCotMoveIn"
  import { useStepWizard } from "@/lib/useStepWizard"
  import TrackingService from "@/services/TrackingService"
  import { toTypedSchema } from "@vee-validate/zod"
  import dayjs from "dayjs"
  import customParseFormat from "dayjs/plugin/customParseFormat"
  import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
  import { useForm } from "vee-validate"
  import { computed } from "vue"
  import { z } from "zod"
  import { Alert, AlertDescription } from "@/components/ui/alert"
  import { Button } from "@/components/ui/button"
  import { DatePicker } from "@/components/ui/inputs/date-picker"
  import { Link } from "@/components/ui/link"
  import { StepWizardProgressCard } from "@/components/ui/step-wizard"

  dayjs.extend(customParseFormat)
  dayjs.extend(isSameOrAfter)

  const { goNext } = useStepWizard()
  const { cotMoveInRequest, cotMoveInExtras, updateMoveInRequest } =
    useCotMoveIn()

  const candidateFdorFormatted = computed(() =>
    cotMoveInExtras.value.candidateFDOR
      ? formatDate(cotMoveInExtras.value.candidateFDOR, undefined, "api")
      : dayjs(),
  )

  const showFdorAlert = computed(
    () =>
      cotMoveInRequest.value.firstDateOfResponsibility &&
      cotMoveInExtras.value.candidateFDOR &&
      dayjs(
        cotMoveInRequest.value.firstDateOfResponsibility,
        DateFormats.default,
      ).isBefore(dayjs(cotMoveInExtras.value.candidateFDOR, DateFormats.api)),
  )

  const showTypeformAlert = computed(
    () =>
      !showFdorAlert.value &&
      dayjs(
        cotMoveInRequest.value.firstDateOfResponsibility,
        DateFormats.default,
      ).isBefore(dayjs().subtract(1, "year"), "day"),
  )

  const formSchema = toTypedSchema(
    z.object({
      firstDateOfResponsibility: z
        .string({
          required_error: "Please enter a valid date",
          invalid_type_error: "Please enter a valid date",
        })
        .min(1, "Please enter a valid date")
        .refine(
          (fdor) =>
            !cotMoveInExtras.value.candidateFDOR ||
            dayjs(fdor, DateFormats.default).isSameOrAfter(
              dayjs(cotMoveInExtras.value.candidateFDOR, DateFormats.api),
            ),
          {
            message:
              "Please select a date after the previous occupier's move-out date",
          },
        )
        .refine(
          (fdor) =>
            dayjs(fdor, DateFormats.default).isSameOrAfter(
              dayjs().subtract(1, "year"),
              "day",
            ),
          {
            message: "This date is too far in the past",
          },
        ),
    }),
  )

  const { handleSubmit, validate } = useForm({
    validationSchema: formSchema,
  })

  const onChange = () => {
    validate()
  }

  const submit = handleSubmit(async (data) => {
    updateMoveInRequest({
      firstDateOfResponsibility: data.firstDateOfResponsibility,
    })
    goNext()
  })

  function redirectToMoveInTypeform() {
    TrackingService.sendEvent("move-in-typeform-redirect")
    window.open(ExternalRoute.movingInTypeform, "blank")
  }
</script>

<template>
  <StepWizardProgressCard title="First date of responsibility">
    <p class="mb-4 sm:mb-6">
      This will be the first date of your tenancy or the sale date. If you don't
      know the date yet, no worries, just come back to this form when you do.
      Submitting the form with the correct date will ensure you are correctly
      billed.
    </p>
    <form>
      <DatePicker
        v-model="cotMoveInRequest.firstDateOfResponsibility"
        name="firstDateOfResponsibility"
        label="Date"
        class="max-w-[348px]"
        :show-label="false"
        @update:model-value="onChange"
      />
    </form>
    <Alert v-if="showFdorAlert" variant="info" class="mt-4">
      <AlertDescription>
        It looks like someone is still in the property. Please select a date
        from {{ candidateFdorFormatted }}. If you believe your date is correct,
        please contact us on
        <Link class="link text-primary-600" to="tel:0330 111 5050"
          >0330 111 5050</Link
        >.
      </AlertDescription>
    </Alert>
    <Alert v-if="showTypeformAlert" variant="error" class="body-text mt-4">
      Given your first date of responsibility is more than one year in the past,
      we kindly ask that you
      <button class="link" variant="text" @click="redirectToMoveInTypeform()">
        complete this form</button
      >, and we’ll take care of the rest.
    </Alert>
    <template #next_button>
      <Button variant="tonal" size="md" @click="submit">Next</Button>
    </template>
  </StepWizardProgressCard>
</template>
