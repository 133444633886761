<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import type { HTMLAttributes } from "vue"

  import { badgeVariants, type BadgeVariants } from "."

  const props = defineProps<{
    variant?: BadgeVariants["variant"]
    class?: HTMLAttributes["class"]
  }>()
</script>

<template>
  <div :class="cn(badgeVariants({ variant }), props.class)" data-testid="badge">
    <slot />
  </div>
</template>
