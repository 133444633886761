import {
  ArrowLeft,
  ArrowRight,
  Bell,
  Calendar,
  CalendarClock,
  Camera,
  ChartColumnIncreasing,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  CircleCheckBig,
  CircleGauge,
  CircleHelp,
  Download,
  ExternalLink,
  Eye,
  EyeClosed,
  Flame,
  House,
  Info,
  Lightbulb,
  LoaderCircle,
  Menu,
  MessageSquare,
  Minus,
  Moon,
  OctagonAlert,
  PhoneIcon,
  Plus,
  SearchIcon,
  Send,
  Sun,
  TriangleAlert,
  Wallet,
  X,
  Zap,
} from "lucide-vue-next"
import PoundCircle from "@/components/ui/icons/PoundCircle.vue"

export const iconsMap = {
  Info: Info,
  Error: TriangleAlert,
  Warning: OctagonAlert,
  CircleCheck: CircleCheckBig,
  Gas: Flame,
  Electricity: Zap,
  Question: CircleHelp,
  Camera: Camera,
  ArrowLeft: ArrowLeft,
  ArrowRight: ArrowRight,
  Calendar: Calendar,
  OpenBrowser: ExternalLink,
  x: X,
  Check: Check,
  Plus: Plus,
  Download: Download,
  ChevronLeft: ChevronLeft,
  ChevronDown: ChevronDown,
  ChevronRight: ChevronRight,
  ChevronUp: ChevronUp,
  Eye: Eye,
  ClosedEye: EyeClosed,
  Day: Sun,
  Night: Moon,
  Tip: Lightbulb,
  Wallet: Wallet,
  Renew: CalendarClock,
  Meter: CircleGauge,
  Bell: Bell,
  Search: SearchIcon,
  Phone: PhoneIcon,
  Message: MessageSquare,
  Spinner: LoaderCircle,
  Send: Send,
  Minus: Minus,
  More: Menu,
  MobileHome: House,
  MobileReadings: CircleGauge,
  MobileUsage: ChartColumnIncreasing,
  MobilePayments: PoundCircle,
} as const

export type IconType = keyof typeof iconsMap
