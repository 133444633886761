<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import { type HTMLAttributes } from "vue"
  import Badge from "@/components/ui/badge/Badge.vue"
  import { Button } from "@/components/ui/button"
  import Icon from "@/components/ui/icons/Icon.vue"
  import { IconType } from "@/components/ui/icons/iconsMap"

  const props = defineProps<{
    class?: HTMLAttributes["class"]
    icon?: IconType
    actionClass?: string
    badgeText?: string
    buttonText?: string
  }>()

  defineEmits(["actionClick"])
</script>

<template>
  <td :class="cn('py-3 align-baseline', props.class)">
    <div class="flex flex-wrap items-center gap-2 text-secondary-500">
      <slot />
      <slot name="action">
        <Icon
          v-if="icon"
          :class="cn('min-w-6', actionClass)"
          size="lg"
          :name="icon"
          @click="$emit('actionClick')"
        />
        <Badge v-if="badgeText" variant="primary" :class="actionClass">
          {{ badgeText }}
        </Badge>
        <Button
          v-if="buttonText"
          :class="actionClass"
          size="sm"
          variant="outline"
          @click="$emit('actionClick')"
          >{{ buttonText }}</Button
        >
      </slot>
    </div>
  </td>
</template>
