<script setup lang="ts">
  import { useStepWizard } from "@/lib/useStepWizard"
  import { StepWizardReviewContent } from "@/types/StepWizard"

  defineProps<{
    contentInformation: StepWizardReviewContent[]
  }>()

  const { goToStep } = useStepWizard()
</script>

<template>
  <div class="space-y-8">
    <div
      v-for="(stepInformation, index) in contentInformation"
      :key="`${stepInformation.stepTitle}_${index}`"
      class="flex flex-col gap-2 sm:gap-3"
      :data-testid="`step-wizard-review-step_${index}`"
    >
      <div v-if="stepInformation.stepTitle" class="flex justify-between gap-3">
        <h3 class="h4">{{ stepInformation.stepTitle }}</h3>
        <button
          v-if="stepInformation.editStep != null"
          class="link"
          @click="goToStep(stepInformation.editStep)"
        >
          Edit
        </button>
      </div>
      <div
        v-for="(stepContent, contentIndex) in stepInformation.content"
        :key="`${stepContent.title}_${contentIndex}`"
        :data-testid="`step-wizard-review-content_${contentIndex}`"
      >
        <div class="flex justify-between gap-3 sm:mb-1">
          <h4 class="body-text-strong">
            {{ stepContent.title }}
          </h4>
          <button
            v-if="stepContent.editStep != null"
            class="link"
            @click="goToStep(stepContent.editStep)"
          >
            Edit
          </button>
        </div>
        <div class="flex flex-col sm:gap-1">
          <p
            v-for="(text, textIndex) in stepContent.text"
            :key="`${text}_${textIndex}`"
            class="label-text text-secondary-500"
          >
            {{ text }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
