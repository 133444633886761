<script setup lang="ts">
  import { AddressMeterPointDetails } from "@/api/model"
  import { ExternalRoute } from "@/constants/externalRoute"
  import { useCotMoveIn } from "@/lib/useCotMoveIn"
  import { useStepWizard } from "@/lib/useStepWizard"
  import { AddressFinderModel, AddressNotListed } from "@/types/AddressType"
  import { templateRef } from "@vueuse/core"
  import { computed, onBeforeMount, ref } from "vue"
  import { AddressFinder } from "@/components/ui/address-finder"
  import { Alert, AlertDescription } from "@/components/ui/alert"
  import Button from "@/components/ui/button/Button.vue"
  import { Link } from "@/components/ui/link"
  import { StepWizardProgressCard } from "@/components/ui/step-wizard"

  const defaultPostcode = ref<string>()
  const defaultAddress = ref<AddressMeterPointDetails>()

  onBeforeMount(async () => {
    const fetchedMoveInAddress = await fetchMoveInAddressFromCookie()
    if (fetchedMoveInAddress) {
      defaultAddress.value = fetchedMoveInAddress
      defaultPostcode.value = fetchedMoveInAddress.address.postcode
    }
  })

  const { goNext } = useStepWizard()
  const { updateAddress, moveInAddress, fetchMoveInAddressFromCookie } =
    useCotMoveIn()

  const modelValue = ref<AddressFinderModel>()

  const isAddressNotSupplied = computed(
    () =>
      modelValue.value === AddressNotListed ||
      (!!modelValue.value && !modelValue.value?.billingAccountId),
  )

  const addressFinder = templateRef("addressFinder")
  const onSubmit = async () => {
    await addressFinder.value?.onSubmitPostcode()
    if (modelValue.value && modelValue.value !== AddressNotListed) {
      updateAddress(modelValue.value)
      goNext()
    }
  }
</script>

<template>
  <form
    class="flex w-full flex-wrap justify-center"
    novalidate
    @submit.prevent="onSubmit"
  >
    <StepWizardProgressCard title="New address">
      <p class="mb-4 sm:mb-6">
        What is the address of the property you're moving into?
      </p>
      <AddressFinder
        ref="addressFinder"
        v-model="modelValue"
        :postcode="moveInAddress.postcode"
        :default-postcode="defaultPostcode"
        :default-address="defaultAddress"
      ></AddressFinder>
      <Alert v-if="isAddressNotSupplied" variant="warning" class="mt-4 sm:mt-8">
        <AlertDescription>
          We don't currently supply your address. Not a problem, you can
          <Link class="link" :to="ExternalRoute.quote"
            >get a quote and switch</Link
          >
          your home to So Energy in 4 simple steps!
        </AlertDescription>
      </Alert>
      <template #next_button>
        <Button
          variant="tonal"
          type="submit"
          size="md"
          :disabled="isAddressNotSupplied"
        >
          Next
        </Button>
      </template>
    </StepWizardProgressCard>
  </form>
</template>
