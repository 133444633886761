<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import {
    AccordionHeader,
    AccordionTrigger,
    type AccordionTriggerProps,
  } from "radix-vue"
  import { computed, type HTMLAttributes } from "vue"
  import { Icon } from "@/components/ui/icons"

  const props = defineProps<
    AccordionTriggerProps & { class?: HTMLAttributes["class"] }
  >()

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
  })
</script>

<template>
  <AccordionHeader class="flex">
    <AccordionTrigger
      v-bind="delegatedProps"
      :class="
        cn(
          'body-text flex flex-1 items-center justify-between py-2 font-normal transition-all sm:py-4 [&[data-state=open]>svg]:rotate-180',
          props.class,
        )
      "
    >
      <slot />
      <slot name="icon">
        <Icon name="ChevronDown" size="md" class="ml-2 shrink-0 sm:ml-3" />
      </slot>
    </AccordionTrigger>
  </AccordionHeader>
</template>
