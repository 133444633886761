<script setup lang="ts">
  import { HTMLAttributes } from "vue"
  import { Button } from "@/components/ui/button"
  import { Link } from "@/components/ui/link"

  const props = defineProps<{
    label: string
    class?: HTMLAttributes["class"]
    actionUrl: string
  }>()
</script>

<template>
  <div class="ml-3 mt-3 self-end sm:mt-0 sm:self-center">
    <Link :to="props.actionUrl" class="whitespace-nowrap">
      <Button
        tabindex="-1"
        variant="text"
        size="md"
        icon="ArrowRight"
        class="text-inherit hover:bg-transparent active:bg-transparent"
      >
        {{ props.label }}
      </Button>
    </Link>
  </div>
</template>
