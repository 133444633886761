<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import type { HTMLAttributes } from "vue"

  const props = defineProps<{
    class?: HTMLAttributes["class"]
  }>()
</script>

<template>
  <div
    :class="
      cn(
        'text-sm sm:text-base [&_a]:underline [&_p]:leading-relaxed',
        props.class,
      )
    "
    data-testid="alert-description"
  >
    <slot />
  </div>
</template>
