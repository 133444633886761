<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import type { HTMLAttributes } from "vue"
  import AlertClose from "@/components/ui/alert/AlertClose.vue"
  import AlertTitle from "@/components/ui/alert/AlertTitle.vue"
  import Icon from "@/components/ui/icons/Icon.vue"
  import { iconsMap } from "@/components/ui/icons/iconsMap.ts"

  import { alertVariants, type AlertVariants } from "."

  const props = defineProps<{
    class?: HTMLAttributes["class"]
    variant: AlertVariants["variant"]
    showCloseButton?: boolean
    title?: string
  }>()

  const iconVariant: Record<string, keyof typeof iconsMap> = {
    success: "CircleCheck",
    info: "Info",
    warning: "Warning",
    error: "Error",
  }
  const emit = defineEmits<(e: "closeButtonClicked") => void>()
</script>

<template>
  <div :class="cn(alertVariants({ variant }), props.class)" role="alert">
    <div class="flex w-full gap-3 text-left">
      <div class="flex h-6 items-center" :class="{ 'pt-1': title }">
        <Icon
          :name="iconVariant[variant!]"
          size="lg"
          :data-testid="`alert-icon-${variant}`"
        />
      </div>
      <div class="grid flex-1 grid-cols-[1fr_auto]">
        <div data-testid="alert-content">
          <AlertTitle v-if="props.title">{{ props.title }}</AlertTitle>
          <template v-else><slot /></template>
        </div>
        <div class="inline-flex items-start pl-3">
          <AlertClose
            v-if="props.showCloseButton"
            data-dd-action-name="Dismiss alert"
            @close="emit('closeButtonClicked')"
          />
        </div>
        <div class="col-span-2"><slot v-if="props.title" /></div>
      </div>
    </div>
    <slot name="action" />
  </div>
</template>
