<script setup lang="ts">
  import { AddressMeterPointDetails } from "@/api/model"
  import { ExternalRoute } from "@/constants/externalRoute"
  import { AddressFinderModel, AddressNotListed } from "@/types/AddressType"
  import { toTypedSchema } from "@vee-validate/zod"
  import { templateRef } from "@vueuse/core"
  import { useForm } from "vee-validate"
  import { computed, nextTick, ref, watch } from "vue"
  import { z } from "zod"
  import { Alert } from "@/components/ui/alert"
  import { Combobox } from "@/components/ui/inputs/combobox"
  import { PostcodeFinder } from "@/components/ui/inputs/postcode-finder"
  import Link from "@/components/ui/link/Link.vue"

  type AddressListItem = { label: string; value: AddressFinderModel }

  const props = defineProps<{
    modelValue?: AddressFinderModel
    postcode?: string
    defaultPostcode?: string
    defaultAddress?: AddressFinderModel
  }>()

  const emits = defineEmits({
    "update:modelValue": (_?: AddressFinderModel) => true,
  })

  const formSchema = toTypedSchema(
    z.object({
      address: z
        .object({
          label: z
            .string({
              required_error: "Please select your address from the list",
            })
            .min(1, "Please select your address from the list"),
        })
        .default({ label: "" }),
    }),
  )

  const modalPostcodeValue = ref()

  watch(
    () => props.defaultPostcode,
    async (newValue) => {
      if (newValue) {
        modalPostcodeValue.value = props.defaultPostcode
        await onSubmitPostcode()
        if (props.defaultAddress) {
          modelAddressValue.value = addressItems.value?.find(
            (address) =>
              (address.value as AddressMeterPointDetails).fullAddress ===
              (props.defaultAddress as AddressMeterPointDetails).fullAddress,
          )
        }
      }
    },
  )

  const onUpdatePostcode = (postcode: string) => {
    if (postcode) {
      addressItems.value = undefined
      modelAddressValue.value = undefined
    }
  }

  const addressItems = ref<AddressListItem[]>()
  const addressQueryError = ref()
  const modelAddressValue = computed({
    get() {
      if (!props.modelValue) {
        return undefined
      }

      if (props.modelValue === AddressNotListed) {
        return {
          label: AddressNotListed,
          value: AddressNotListed,
        }
      }

      return {
        label: props.modelValue.fullAddress || "",
        value: props.modelValue,
      }
    },
    async set(value?: AddressListItem) {
      emits("update:modelValue", value?.value)
      await nextTick()
      await validate()
    },
  })

  const setAddresses = (addressList: AddressMeterPointDetails[]) => {
    modelAddressValue.value = undefined
    addressItems.value = addressList.map((entry) => {
      return {
        label: entry.fullAddress || "",
        value: entry,
      }
    })
    if (!addressQueryError.value) {
      addressItems.value = [
        ...(addressItems.value || []),
        { label: AddressNotListed, value: AddressNotListed },
      ]
    }
  }

  const { validate } = useForm({
    validationSchema: formSchema,
  })

  const postcodeFinder = templateRef("postcodeFinder")
  const onSubmitPostcode = async () => {
    if (!addressItems.value?.length) {
      await postcodeFinder.value?.validateSubmitPostcode()
    }
    const postcodeValidation = await postcodeFinder.value?.validate()
    if (postcodeValidation?.valid) {
      validate()
    }
  }

  defineExpose({ onSubmitPostcode })
</script>

<template>
  <div class="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
    <PostcodeFinder
      ref="postcodeFinder"
      v-model="modalPostcodeValue"
      label="Postcode"
      name="postcode"
      placeholder="Enter your postcode"
      :default-value="postcode"
      @addresses="setAddresses($event)"
      @update:model-value="onUpdatePostcode"
      @query-error="addressQueryError = $event"
    />
    <Combobox
      v-if="addressItems?.length"
      v-model="modelAddressValue"
      autocomplete="off"
      class="col-span-1 sm:mt-8"
      data-testid="select-address"
      :items="addressItems"
      label="Select Address"
      :show-label="false"
      name="address"
      placeholder="Select Address"
    />
  </div>
  <Alert
    v-if="addressQueryError"
    variant="error"
    class="mt-4 w-auto text-left text-sm sm:text-base"
  >
    An unexpected error occurred while trying to load your postcode details.
    Please try again and if the error persists please
    <Link class="link" :to="ExternalRoute.getSupport">contact us</Link>.
  </Alert>
</template>
