import { Route } from "@/constants/route.ts"
import { Layouts } from "@/types/Layout"
import { Component } from "vue"
import { RouteRecordRaw } from "vue-router"

import LoginPage from "@/views/authentication/login/Login.vue"
import CotMoveInConfirmation from "@/views/cot-move-in/CotMoveInConfirmation.vue"
import CotMoveInContainer from "@/views/cot-move-in/CotMoveInContainer.vue"
import CotMoveInFdor from "@/views/cot-move-in/CotMoveInFdor.vue"
import CotMoveInFindAddress from "@/views/cot-move-in/CotMoveInFindAddress.vue"
import CotMoveInMonthlyPayment from "@/views/cot-move-in/CotMoveInMonthlyPayment.vue"
import CotMoveInPayments from "@/views/cot-move-in/CotMoveInPayments.vue"
import CotMoveInPersonalDetails from "@/views/cot-move-in/CotMoveInPersonalDetails.vue"
import CotMoveInReview from "@/views/cot-move-in/CotMoveInReview.vue"
import HomeView from "@/views/home/HomeView.vue"
import SpaygConfirmation from "@/views/smart-pay-as-you-go/SpaygConfirmation.vue"
import SpaygContainer from "@/views/smart-pay-as-you-go/SpaygContainer.vue"
import SpaygFuel from "@/views/smart-pay-as-you-go/SpaygFuel.vue"
import SpaygReview from "@/views/smart-pay-as-you-go/SpaygReview.vue"
import SpaygSuitability from "@/views/smart-pay-as-you-go/SpaygSuitability.vue"
import SpaygSwitchDate from "@/views/smart-pay-as-you-go/SpaygSwitchDate.vue"
import SpaygTariff from "@/views/smart-pay-as-you-go/SpaygTariff.vue"
import SpaygWhy from "@/views/smart-pay-as-you-go/SpaygWhy.vue"

const routesDefinition = [
  {
    path: Route.home,
    component: HomeView,
    meta: {
      requiresAuth: true,
      title: "Account Home",
      hideBackButton: true,
      layout: Layouts.SIDEBAR,
    },
  },
  {
    path: Route.readings,
    component: (): Promise<Component> =>
      import("@/views/readings/Readings.vue"),
    meta: {
      requiresAuth: true,
      title: "Meter Readings",
      layout: Layouts.SIDEBAR,
    },
  },
  {
    path: `${Route.readings}/submit/:id`,
    component: (): Promise<Component> =>
      import("@/views/readings/submit/Reading.vue"),
    meta: {
      requiresAuth: true,
      layout: Layouts.SIDEBAR,
    },
  },
  {
    path: `${Route.tariff}`,
    components: {
      default: (): Promise<Component> =>
        import("@/views/tariff/TariffView.vue"),
    },
    meta: {
      requiresAuth: true,
      title: "Tariff Details",
      layout: Layouts.SIDEBAR,
    },
  },
  {
    path: `${Route.usage}`,
    component: (): Promise<Component> =>
      import("@/views/energy-usage/EnergyUsageView.vue"),
    meta: {
      title: "Energy Usage",
      requiresAuth: true,
      layout: Layouts.SIDEBAR,
    },
  },
  {
    path: `${Route.profile}`,
    component: (): Promise<Component> => import("@/views/profile/Profile.vue"),
    meta: {
      title: "Your Personal Details",
      requiresAuth: true,
      layout: Layouts.SIDEBAR,
    },
  },
  {
    path: `${Route.help}`,
    component: (): Promise<Component> => import("@/views/help/Help.vue"),
    meta: {
      title: "Help & Advice",
      requiresAuth: true,
      layout: Layouts.SIDEBAR,
    },
  },
  {
    path: Route.login,
    component: LoginPage,
    meta: {
      requiresAnon: true,
      hideBackButton: true,
    },
  },
  {
    path: Route.moveIn,
    component: CotMoveInContainer,
    meta: {
      layout: Layouts.EMPTY,
      requiresAuth: false,
    },
    children: [
      {
        path: Route.cotMoveInFindAddress,
        component: CotMoveInFindAddress,
      },
      {
        path: Route.cotMoveInFdor,
        component: CotMoveInFdor,
      },
      {
        path: Route.cotMoveInPersonalDetails,
        component: CotMoveInPersonalDetails,
      },
      {
        path: Route.cotMoveInPayments,
        component: CotMoveInPayments,
      },
      {
        path: Route.cotMoveInReview,
        component: CotMoveInReview,
      },
      {
        path: Route.cotMoveInMonthlyPayment,
        component: CotMoveInMonthlyPayment,
      },
    ],
  },
  { path: Route.cotMoveInComplete, component: CotMoveInConfirmation },
  {
    path: Route.spayg,
    component: SpaygContainer,
    meta: {
      layout: Layouts.EMPTY,
      requiresAuth: true,
    },
    children: [
      { path: Route.spaygWhy, component: SpaygWhy },
      { path: Route.spaygSuitability, component: SpaygSuitability },
      { path: Route.spaygFuel, component: SpaygFuel },
      { path: Route.spaygSwitchDate, component: SpaygSwitchDate },
      { path: Route.spaygTariff, component: SpaygTariff },
      { path: Route.spaygReview, component: SpaygReview },
    ],
  },
  { path: Route.spaygComplete, component: SpaygConfirmation },
  {
    path: Route.maintenance,
    component: (): Promise<Component> =>
      import("@/views/maintenance/Maintenance.vue"),
    meta: {
      requiresAnon: false,
    },
  },
  {
    path: Route.passwordReset,
    component: (): Promise<Component> =>
      import("@/views/authentication/password-reset/PasswordReset.vue"),
    meta: {
      requiresAnon: true,
      layout: Layouts.EMPTY,
    },
  },
  {
    path: Route.passwordChange,
    component: (): Promise<Component> =>
      import("@/views/authentication/password-change/PasswordChange.vue"),
    meta: {
      requiresAnon: true,
    },
  },
  {
    path: Route.activateAccount,
    component: (): Promise<Component> =>
      import("@/views/authentication/activate-account/ActivateAccount.vue"),
    meta: {
      requiresAnon: true,
    },
  },
  {
    path: Route.setPassword,
    component: (): Promise<Component> =>
      import("@/views/authentication/set-password/SetPassword.vue"),
    meta: {
      requiresAnon: true,
    },
  },
  {
    path: Route.notFound,
    component: (): Promise<Component> => import("@/views/NotFound.vue"),
    meta: {
      hideBackButton: true,
    },
  },
] as const satisfies readonly RouteRecordRaw[]

export default routesDefinition
