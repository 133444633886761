<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import { NumberFieldInput } from "radix-vue"
  import type { HTMLAttributes } from "vue"

  const props = defineProps<{
    class?: HTMLAttributes["class"]
    error?: string
    hasIcon?: boolean
    ariaInvalid?: boolean
  }>()
</script>

<template>
  <NumberFieldInput
    data-slot="input"
    data-testid="number-input"
    :aria-invalid="ariaInvalid"
    :class="
      cn(
        'body-text flex h-12 w-full rounded-sm border px-4 py-3 sm:h-13',
        'placeholder:text-muted-foreground',
        'text-secondary-700 ring-primary-400 file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-secondary-400 hover:border-secondary-400 focus-visible:border-primary-400 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-offset-0',
        'disabled:cursor-not-allowed disabled:border-secondary-500 disabled:bg-secondary-300 disabled:text-secondary-400',
        error &&
          'border-red-400 ring-red-400 hover:border-red-400 focus-visible:border-red-400',

        hasIcon && 'pl-7',
        props.class,
      )
    "
  />
</template>
