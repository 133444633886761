<script lang="ts" setup>
  import { DateFormats } from "@/lib/dateHelper"
  import { cn } from "@/lib/utils"
  import { parseAbsoluteToLocal } from "@internationalized/date"
  import dayjs from "dayjs"
  import customParseFormat from "dayjs/plugin/customParseFormat"
  import {
    CalendarRoot,
    useEmitAsProps,
    useForwardProps,
    type CalendarRootEmits,
    type CalendarRootProps,
  } from "radix-vue"
  import { toDate } from "radix-vue/date"
  import { computed, type HTMLAttributes } from "vue"

  import {
    CalendarCell,
    CalendarCellTrigger,
    CalendarGrid,
    CalendarGridBody,
    CalendarGridHead,
    CalendarGridRow,
    CalendarHeadCell,
    CalendarHeader,
    CalendarHeading,
    CalendarNextButton,
    CalendarPrevButton,
  } from "."

  dayjs.extend(customParseFormat)

  const props = withDefaults(
    defineProps<
      Omit<CalendarRootProps, "modelValue" | "minValue" | "maxValue"> & {
        class?: HTMLAttributes["class"]
        dateFormat?: string
        modelValue?: string | undefined
        minValue?: string
        maxValue?: string
      }
    >(),
    { weekdayFormat: "short", dateFormat: DateFormats.default },
  )
  const emits = defineEmits<
    Omit<CalendarRootEmits, "update:modelValue"> & {
      "update:modelValue": [date: string | undefined]
    }
  >()

  const formatDate = (date?: string) => {
    if (!dayjs(date, props.dateFormat, true).isValid()) return undefined

    return parseAbsoluteToLocal(dayjs(date, props.dateFormat).toISOString())
  }

  const modelValue = computed({
    get() {
      return formatDate(props.modelValue)
    },
    set(val) {
      emits(
        "update:modelValue",
        val && dayjs(toDate(val)).format(props.dateFormat),
      )
    },
  })

  const delegatedProps = computed(() => {
    const minValue = formatDate(props.minValue)
    const maxValue = formatDate(props.maxValue)

    const { class: _, modelValue: __, ...delegated } = props
    return { ...delegated, minValue, maxValue }
  })
  const delegatedEmits = computed(() => {
    const { "onUpdate:modelValue": _, ...delegated } = useEmitAsProps(emits)
    return delegated
  })
  const forwarded = computed(() => ({
    ...useForwardProps(delegatedProps).value,
    ...delegatedEmits.value,
  }))
</script>

<template>
  <div class="flex">
    <CalendarRoot
      v-slot="{ grid, weekDays }"
      v-model="modelValue"
      v-bind="forwarded"
      :week-starts-on="1"
      :class="
        cn(
          'rounded-sm border bg-secondary-100 px-3 py-4 sm:px-4',
          'dark:border-secondary-400 dark:bg-secondary-500',
          props.class,
        )
      "
    >
      <CalendarHeader data-testid="calendar-header">
        <CalendarPrevButton />
        <CalendarHeading />
        <CalendarNextButton />
      </CalendarHeader>

      <div class="mt-3 flex flex-col gap-y-4 sm:flex-row sm:gap-x-4 sm:gap-y-0">
        <CalendarGrid v-for="month in grid" :key="month.value.toString()">
          <CalendarGridHead>
            <CalendarGridRow class="mb-3 h-10 items-center">
              <CalendarHeadCell v-for="day in weekDays" :key="day">
                {{ day.slice(0, 2) }}
              </CalendarHeadCell>
            </CalendarGridRow>
          </CalendarGridHead>
          <CalendarGridBody class="space-y-2">
            <CalendarGridRow
              v-for="(weekDates, index) in month.rows"
              :key="`weekDate-${index}`"
              class="w-full"
            >
              <CalendarCell
                v-for="weekDate in weekDates"
                :key="weekDate.toString()"
                :date="weekDate"
              >
                <CalendarCellTrigger :day="weekDate" :month="month.value" />
              </CalendarCell>
            </CalendarGridRow>
          </CalendarGridBody>
        </CalendarGrid>
      </div>
    </CalendarRoot>
  </div>
</template>
