<script setup lang="ts">
  import { ExternalRoute } from "@/constants/externalRoute.ts"
  import { Route } from "@/constants/route.ts"
  import { Button } from "@/components/ui/button"
  import { Link } from "@/components/ui/link"

  const props = defineProps<{
    to: Route | ExternalRoute
    label: string
  }>()
</script>

<template>
  <Button variant="text" as-child>
    <Link :to="props.to"> {{ props.label }} </Link>
  </Button>
</template>
