<script setup lang="ts">
  import { useSubmitChangeOfTenancyMoveIn } from "@/api/change-of-tenancy/change-of-tenancy"
  import { ExternalRoute } from "@/constants/externalRoute"
  import { Route } from "@/constants/route"
  import { formatDate } from "@/lib/dateHelper"
  import { useCotMoveIn } from "@/lib/useCotMoveIn"
  import { formatCurrency, maskAllExceptLast2 } from "@/lib/utils.ts"
  import TrackingService from "@/services/TrackingService"
  import { StepWizardReviewContent } from "@/types/StepWizard"
  import dayjs from "dayjs"
  import advancedFormat from "dayjs/plugin/advancedFormat"
  import customParseFormat from "dayjs/plugin/customParseFormat"
  import { computed } from "vue"
  import { useRouter } from "vue-router"
  import { Alert } from "@/components/ui/alert"
  import { Button } from "@/components/ui/button"
  import { StepWizardProgressCard } from "@/components/ui/step-wizard"
  import StepWizardReview from "@/components/ui/step-wizard/StepWizardReview.vue"

  dayjs.extend(customParseFormat)
  dayjs.extend(advancedFormat)

  const {
    cotMoveInRequest,
    moveInAddress,
    newOccupantContact,
    directDebitInfo,
    cotMoveInExtras,
  } = useCotMoveIn()

  const information = computed<StepWizardReviewContent[]>(() => [
    {
      stepTitle: "New Address",
      editStep: 0,
      content: [
        {
          title: "Address",
          text: [cotMoveInExtras.value.fullAddress],
        },
      ],
    },
    {
      stepTitle: "Moving in Date",
      editStep: 1,
      content: [
        {
          title: "First date of responsibility",
          text: [
            formatDate(
              cotMoveInRequest.value.firstDateOfResponsibility,
              "ordinal",
              "default",
            ),
          ],
        },
      ],
    },
    {
      stepTitle: "New Occupant Information",
      editStep: 2,
      content: [
        {
          title: "Name",
          text: [
            `${newOccupantContact.value.firstName} ${newOccupantContact.value.lastName}`,
          ],
        },
        {
          title: "Date of birth",
          text: [newOccupantContact.value.dateOfBirth],
        },
        { title: "Email", text: [newOccupantContact.value.email] },
        { title: "Phone", text: [newOccupantContact.value.phone1] },
      ],
    },
    {
      stepTitle: "Monthly Payment",
      editStep: 3,
      content: [
        {
          title: "Payment amount",
          text: [
            `${formatCurrency(directDebitInfo.value.paymentAmount, true)} per month`,
            `Day ${directDebitInfo.value.paymentDayOfMonth} of every month`,
          ],
        },
      ],
    },
    {
      stepTitle: "Payment Details",
      editStep: 4,
      content: [
        {
          title: "Bank details",
          text: [
            directDebitInfo.value.accountName,
            maskAllExceptLast2(directDebitInfo.value.accountNumber),
          ],
        },
      ],
    },
  ])

  function redirectToMoveInTypeform() {
    TrackingService.sendEvent("move-in-typeform-redirect")
    window.open(ExternalRoute.movingInTypeform, "blank")
  }

  const moveIn = useSubmitChangeOfTenancyMoveIn()
  const router = useRouter()

  const formatDefaultDateToApi = (date: string) =>
    formatDate(date, "api", "default")

  const submitMoveIn = async () => {
    await moveIn.mutateAsync({
      data: {
        billingAccountId: cotMoveInRequest.value.billingAccountId!,
        firstDateOfResponsibility: formatDefaultDateToApi(
          cotMoveInRequest.value.firstDateOfResponsibility,
        ),
        newOccupantContact: {
          ...newOccupantContact.value,
          dateOfBirth: formatDefaultDateToApi(
            newOccupantContact.value.dateOfBirth,
          ),
        },
        billingAddress: moveInAddress.value,
        supplyAddress: moveInAddress.value,
        directDebitInfo: directDebitInfo.value,
      },
    })
    if (!moveIn.error.value) {
      router.push(Route.cotMoveInComplete)
    }
  }
</script>

<template>
  <StepWizardProgressCard title="Review information">
    <StepWizardReview :content-information="information" />
    <Alert v-if="moveIn.isError.value" variant="error" class="body-text mt-4">
      We encountered an issue while processing your move-in request. Please take
      a moment to
      <button class="link" @click="redirectToMoveInTypeform()">
        complete this form
      </button>
      , and we'll take care of the rest.
    </Alert>
    <template #next_button>
      <Button
        variant="tonal"
        size="md"
        :loading="moveIn.isPending.value"
        @click="submitMoveIn"
        >Submit
      </Button>
    </template>
  </StepWizardProgressCard>
</template>
