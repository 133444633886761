<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import {
    SelectScrollUpButton,
    useForwardProps,
    type SelectScrollUpButtonProps,
  } from "radix-vue"
  import { computed, type HTMLAttributes } from "vue"
  import Icon from "@/components/ui/icons/Icon.vue"

  const props = defineProps<
    SelectScrollUpButtonProps & { class?: HTMLAttributes["class"] }
  >()

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
  })

  const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <SelectScrollUpButton
    v-bind="forwardedProps"
    :class="
      cn('flex cursor-default items-center justify-center py-1', props.class)
    "
  >
    <slot> <Icon name="ChevronUp" /> </slot>
  </SelectScrollUpButton>
</template>
