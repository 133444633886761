<script lang="ts" setup>
  import { cn } from "@/lib/utils"
  import {
    CalendarHeading,
    useForwardProps,
    type CalendarHeadingProps,
  } from "radix-vue"
  import { computed, type HTMLAttributes } from "vue"

  const props = defineProps<
    CalendarHeadingProps & { class?: HTMLAttributes["class"] }
  >()

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
  })

  const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <CalendarHeading
    :class="cn('body-text-strong', props.class)"
    v-bind="forwardedProps"
  />
</template>
