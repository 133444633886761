<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import { AccordionContent, type AccordionContentProps } from "radix-vue"
  import { computed, type HTMLAttributes } from "vue"

  const props = defineProps<
    AccordionContentProps & { class?: HTMLAttributes["class"] }
  >()

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
  })
</script>

<template>
  <AccordionContent
    v-bind="delegatedProps"
    class="body-text overflow-hidden transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
    data-testid="accordion-content"
  >
    <div :class="cn('pb-2 sm:pb-4', props.class)">
      <slot />
    </div>
  </AccordionContent>
</template>
