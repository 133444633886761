<script setup lang="ts">
  import { Route } from "@/constants/route"
  import { computed } from "vue"
  import { useRoute, useRouter } from "vue-router"
  import { PageHeader } from "@/components/ui/page-header"

  import MaintenanceBanner from "@/views/maintenance-banner/MaintenanceBanner.vue"

  const router = useRouter()
  const route = useRoute()
  const title = computed(() => (route.meta?.title as string) || "")
  const hideBackButton = computed(
    () => !!(route.meta?.hideBackButton as boolean),
  )
</script>

<template>
  <main class="container space-y-6 p-6 pb-12">
    <PageHeader
      v-if="title"
      :title="title"
      :has-back-button="!hideBackButton"
      class="mb-6"
      @back="router.push(Route.home)"
    />
    <MaintenanceBanner />
    <slot />
  </main>
</template>
