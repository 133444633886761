<script setup lang="ts">
  import { NativeNavbarOverflowItem } from "@/types/Navbar.ts"
  import { useAsyncState } from "@vueuse/core"
  import { Card } from "@/components/ui/card"
  import { Link } from "@/components/ui/link"

  const { item } = defineProps<{ item: NativeNavbarOverflowItem }>()
  const { state: imgSrc } = useAsyncState(
    import(`@/assets/illustrations/${item.illustration}.svg`).then(
      (i) => i.default,
    ),
    null,
  )
</script>

<template>
  <Link :key="item.to" :to="item.to" v-bind="$attrs">
    <Card class="relative">
      <h3 class="text-xl">{{ item.label }}</h3>
      <p class="text-sm font-light">{{ item.description }}</p>

      <img
        v-if="imgSrc"
        :src="imgSrc"
        alt=""
        class="absolute -top-3 right-6 h-16 w-16"
      />
    </Card>
  </Link>
</template>
