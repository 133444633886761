<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import {
    SelectItem as SelectItemType,
    SelectItemValue,
    SupportingLabel,
  } from "@/types/InputComponentTypes"
  import { useVModel } from "@vueuse/core"
  import {
    SelectRoot,
    useForwardPropsEmits,
    type SelectRootEmits,
    type SelectRootProps,
  } from "radix-vue"
  import { computed, HTMLAttributes } from "vue"
  import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
  } from "@/components/ui/form"
  import FormSupportingLabel from "@/components/ui/form/FormSupportingLabel.vue"
  import {
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
  } from "@/components/ui/select"

  const props = defineProps<
    Omit<SelectRootProps, "defaultValue" | "modelValue"> & {
      class?: HTMLAttributes["class"]
      name: string
      label: string
      items: SelectItemType[]
      defaultValue?: SelectItemType
      modelValue?: SelectItemType
      supportingLabels?: SupportingLabel[]
      extraLabel?: string
      placeholder?: string
      keepSupportingLabelsOnError?: boolean
    }
  >()

  const delegatedProps = computed(() => ({
    items: props.items,
    disabled: props.disabled,
  }))
  const emits = defineEmits<SelectRootEmits>()

  const modelValue = useVModel(props, "modelValue", emits, {
    passive: true,
    defaultValue: props.defaultValue,
  })

  const getAriaDescribedBy = (
    formDescriptionId: string,
    formMessageId: string,
    error: string,
  ) => {
    const supportingLabelsIds =
      props.supportingLabels
        ?.map((_, index) => `${formMessageId}-${index}`)
        .join(" ") || ""
    const ids = [
      props.extraLabel ? formDescriptionId : null,
      error ? formMessageId : supportingLabelsIds,
    ]
      .filter(Boolean)
      .join(" ")
    return ids.length ? ids : undefined
  }

  const forwarded = useForwardPropsEmits(delegatedProps, emits)

  const getItemAttr = (item: SelectItemType, attr: keyof SelectItemValue) => {
    if (typeof item === "string") return item

    return item[attr] as typeof attr
  }
  const isItemDisabled = (item: SelectItemType) => {
    if (typeof item === "string") return false

    return item.disabled
  }
</script>

<template>
  <FormField
    v-slot="{ field }"
    v-model="modelValue"
    :name="name"
    :validate-on-blur="false"
    :validate-on-change="false"
    :validate-on-model-update="false"
  >
    <FormItem v-bind="$attrs">
      <FormControl
        v-slot="{
          error,
          formItemId,
          ariaInvalid,
          formMessageId,
          formDescriptionId,
        }"
      >
        <div class="flex items-baseline justify-between">
          <FormLabel v-if="label" data-testid="form-label">{{
            label
          }}</FormLabel>
          <span
            v-if="extraLabel"
            :id="formDescriptionId"
            class="label-text-strong text-secondary-500"
            data-testid="form-extra-label"
            :aria-label="`${extraLabel}.`"
          >
            {{ extraLabel }}
          </span>
        </div>
        <SelectRoot
          data-testid="select"
          v-bind="{ ...forwarded, ...field }"
          :model-value="field.value"
        >
          <SelectTrigger
            :id="formItemId"
            :error="error"
            :aria-invalid="ariaInvalid"
            :aria-describedby="
              getAriaDescribedBy(formDescriptionId, formMessageId, error)
            "
            :class="cn(props.class)"
          >
            <SelectValue :placeholder="placeholder" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem
                v-for="item in items"
                :key="getItemAttr(item, 'value')"
                :data-testid="`select-item-${getItemAttr(item, 'value')}`"
                :value="getItemAttr(item, 'value')"
                :disabled="isItemDisabled(item)"
              >
                {{ getItemAttr(item, "label") }}
              </SelectItem>
            </SelectGroup>
          </SelectContent>
        </SelectRoot>
        <template
          v-if="
            supportingLabels?.length && (keepSupportingLabelsOnError || !error)
          "
        >
          <FormSupportingLabel
            v-for="(supportingLabel, index) in supportingLabels"
            :id="`${formMessageId}-${index}`"
            :key="`${formMessageId}-${index}`"
            :supporting-label="supportingLabel"
            :data-testid="`form-supporting-label-${index}`"
          >
          </FormSupportingLabel>
        </template>
        <FormMessage />
      </FormControl>
    </FormItem>
  </FormField>
</template>
