import { authSession, useLogin } from "@/authentication/authenticationService"
import ErrorReporter from "@/services/ErrorReporter"
import { useCookies } from "@vueuse/integrations/useCookies.mjs"
import type { NavigationGuard } from "vue-router"

const AUTO_LOGIN_QUERY_PARAM = "auto-login-from-cookie"
const AUTO_LOGIN_COOKIE_NAME = "myaccount-auto_login_credential"

export const processAutoLogin: NavigationGuard = async (to) => {
  if (!(AUTO_LOGIN_QUERY_PARAM in to.query)) return

  try {
    const credential = useCookies().get(AUTO_LOGIN_COOKIE_NAME)

    if (credential?.otp && credential?.email) {
      useCookies().remove(AUTO_LOGIN_COOKIE_NAME)

      await useLogin().mutateAsync({
        data: {
          email: credential.email,
          password: credential.otp,
          extendedSession: false,
        },
      })
    }
  } catch (error) {
    authSession.clearSessionData()
    ErrorReporter.report(error)
    console.error(error)
  }

  return { path: to.path, query: {}, replace: true }
}
