<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import {
    NumberFieldIncrement,
    useForwardProps,
    type NumberFieldIncrementProps,
  } from "radix-vue"
  import { computed, type HTMLAttributes } from "vue"
  import Icon from "@/components/ui/icons/Icon.vue"

  const props = defineProps<
    NumberFieldIncrementProps & { class?: HTMLAttributes["class"] }
  >()

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
  })

  const forwarded = useForwardProps(delegatedProps)
</script>

<template>
  <NumberFieldIncrement
    data-slot="increment"
    data-testid="increment"
    v-bind="forwarded"
    :class="cn('pl-4 disabled:cursor-not-allowed', props.class)"
  >
    <slot>
      <Icon name="Plus" size="md" class="h-4 w-4" />
    </slot>
  </NumberFieldIncrement>
</template>
