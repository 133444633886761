<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import {
    DialogClose,
    DialogContent,
    DialogOverlay,
    DialogPortal,
    useForwardPropsEmits,
    type DialogContentEmits,
    type DialogContentProps,
  } from "radix-vue"
  import { computed, provide, useSlots, type HTMLAttributes } from "vue"
  import { IconButton } from "@/components/ui/icon-button"

  const props = withDefaults(
    defineProps<
      DialogContentProps & {
        class?: HTMLAttributes["class"]
        hasCloseButton?: boolean
      }
    >(),
    { hasCloseButton: true },
  )
  const emits = defineEmits<DialogContentEmits>()

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
  })
  const forwarded = useForwardPropsEmits(delegatedProps, emits)

  const slots = useSlots()
  provide("hasIllustration", slots.illustration)
</script>

<template>
  <DialogPortal>
    <DialogOverlay
      class="data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 fixed inset-0 z-50 bg-dialogBackground"
    />

    <DialogContent
      v-bind="forwarded"
      :class="
        cn(
          'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 ' +
            'data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 ' +
            'data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] ' +
            'fixed left-1/2 top-1/2 z-50 rounded-md ' +
            'max-h-[90dvh] w-[320px] sm:w-[540px] ' +
            'grid -translate-x-1/2 -translate-y-1/2 grid-rows-[auto_minmax(0,1fr)_auto] gap-4 ' +
            'body-text bg-secondary-100 p-6 text-secondary-500 shadow-md',
          $slots.illustration ? 'text-center' : '',
          props.class,
        )
      "
      data-testid="dialog-content"
      :aria-describedby="undefined"
    >
      <slot name="illustration" />
      <slot />

      <DialogClose
        v-if="hasCloseButton"
        class="absolute right-4 top-4"
        data-testid="dialog-close"
        as-child
      >
        <IconButton icon="x" size="md" label="Close dialog" />
      </DialogClose>
    </DialogContent>
  </DialogPortal>
</template>
