import { PROJECT_BASE_URLS } from "@/constants/projectIds.ts"
import { removeLeadingSlash } from "@/lib/utils.ts"
import { NavigationGuardNext, RouteLocationNormalizedGeneric } from "vue-router"

const getProjectBasePath = (projectId: string) => PROJECT_BASE_URLS[projectId]

export const processRedirectParameter = async (
  to: RouteLocationNormalizedGeneric,
  _: unknown,
  next: NavigationGuardNext,
) => {
  const updatedQuery = { ...to.query }

  if (updatedQuery.redirect) {
    const redirectPath = removeLeadingSlash(updatedQuery.redirect.toString())
    const [projectId, ...routeParts] = redirectPath.split("/")
    const basePath = getProjectBasePath(projectId)

    if (basePath) {
      updatedQuery.redirect = encodeURIComponent(
        `${import.meta.env.VITE_PUBLIC_WEBSITE_URL}${basePath}/${routeParts.join("/")}`,
      )
    }
  }

  // If query parameters were updated, navigate with the new query
  if (JSON.stringify(updatedQuery) !== JSON.stringify(to.query)) {
    next({ path: to.path, query: updatedQuery, replace: true })
  } else {
    next()
  }
}
