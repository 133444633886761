<script setup lang="ts">
  import {
    useGetAccount,
    useGetMetersByBillingAccountId,
  } from "@/api/account/account.ts"
  import { CheckSpaygEligibilityParams } from "@/api/model"
  import { useCheckSmartPayAsYouGoEligibility } from "@/api/smart-pay-as-you-go/smart-pay-as-you-go"
  import { aggregateQueries } from "@/lib/aggregateQueries.ts"
  import { useGetAccountId } from "@/lib/useGetAccountId"
  import { useSmartPayAsYouGo } from "@/lib/useSmartPayAsYouGo"
  import { useStepWizard } from "@/lib/useStepWizard"
  import { SelectItemValue } from "@/types/InputComponentTypes"
  import { toTypedSchema } from "@vee-validate/zod"
  import { useForm } from "vee-validate"
  import { computed } from "vue"
  import { z } from "zod"
  import ErrorMessage from "@/components/ErrorMessage.vue"
  import { Alert } from "@/components/ui/alert"
  import { Button } from "@/components/ui/button"
  import {
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
  } from "@/components/ui/form"
  import { Checkbox } from "@/components/ui/inputs/checkbox"
  import { Link } from "@/components/ui/link"
  import { Skeleton } from "@/components/ui/skeleton"
  import { StepWizardProgressCard } from "@/components/ui/step-wizard"

  type FuelItemValue = SelectItemValue & {
    isEligible: boolean
  }
  const accountIdQuery = useGetAccountId()
  const account = useGetAccount(accountIdQuery.accountId)
  const metersQuery = useGetMetersByBillingAccountId(accountIdQuery.accountId)
  const spaygEligibilityParams = computed<CheckSpaygEligibilityParams>(() => ({
    billingAccountNumber: account.data.value?.accountNumber || "",
  }))
  const spaygEligibility = useCheckSmartPayAsYouGoEligibility(
    accountIdQuery.accountId,
    spaygEligibilityParams,
  )

  const { isLoading, isError } = aggregateQueries([
    accountIdQuery,
    account,
    metersQuery,
    spaygEligibility,
  ])

  const { selectedMeterpointIdentifiers, selectedMeterpointLabels } =
    useSmartPayAsYouGo()

  const meterpointItems = computed(
    () =>
      metersQuery.data?.value?.reduce<FuelItemValue[]>((prev, current) => {
        if (!current.meterpointIdentifier) return prev
        return [
          ...prev,
          {
            label: `${current.isElectricity ? "Electricity" : "Gas"} [${current.meterpointIdentifier}]`,
            value: current.meterpointIdentifier,
            isEligible: !!spaygEligibility.data?.value?.find(
              (eligibility) =>
                eligibility.mpxnIdentifier === current.meterpointIdentifier,
            )?.eligibleForSpayg,
          },
        ]
      }, []) || [],
  )

  const selectedMeterpointItems = computed<FuelItemValue[]>(() =>
    meterpointItems.value.filter((item) =>
      selectedMeterpointIdentifiers.value.includes(item.value),
    ),
  )

  const notEligibleMeterpointsText = computed(() => {
    const notEligibleMeterpoints = selectedMeterpointItems.value.filter(
      (item) => !item.isEligible,
    )

    if (notEligibleMeterpoints.length > 1) {
      const last = notEligibleMeterpoints.pop()
      return `${notEligibleMeterpoints.map((meter) => meter.label).join(", ")} and ${last?.label} meters`
    }

    return `${notEligibleMeterpoints.map((meter) => meter.label)} meter`
  })

  const eligibilityError = "This meter is not eligible"
  const formSchema = computed(() =>
    toTypedSchema(
      z.object({
        ...meterpointItems.value.reduce((prev, current) => {
          return {
            ...prev,
            [current.value]: z
              .boolean()
              .refine((val) => !val || current.isEligible, eligibilityError),
          }
        }, {}),
        selectedMeterpointIdentifiers: z
          .string()
          .array()
          .min(1, "This field is required"),
      }),
    ),
  )

  const { handleSubmit, errors } = useForm({
    validationSchema: formSchema,
  })

  const isEligibilityAlertVisible = computed(() => {
    const errorKeys = Object.keys(errors.value)
    return (
      !isError.value &&
      !isLoading.value &&
      errorKeys.length &&
      !errorKeys.includes("selectedMeterpointIdentifiers")
    )
  })

  const { goNext } = useStepWizard()
  const onSubmit = handleSubmit(() => {
    selectedMeterpointLabels.value = selectedMeterpointItems.value.map(
      ({ label }) => label,
    )
    goNext()
  })
</script>

<template>
  <StepWizardProgressCard title="Choose your SPAYG fuel type">
    <p class="mb-8">
      Select which energy supply you'd like to manage with Smart Pay As You Go:
    </p>

    <div v-if="isLoading" class="max-w-[320px] space-y-4">
      <Skeleton class="h-6" />
      <Skeleton class="h-6" />
    </div>
    <ErrorMessage v-else-if="isError" />
    <form v-else>
      <FormField
        v-model="selectedMeterpointIdentifiers"
        name="selectedMeterpointIdentifiers"
      >
        <FormItem class="space-y-4">
          <FormLabel data-testid="form-label">Select fuel(s)</FormLabel>
          <FormField
            v-for="meterpointItem in meterpointItems"
            :key="meterpointItem.value"
            v-slot="{ value, handleChange }"
            type="checkbox"
            :value="meterpointItem.value"
            :unchecked-value="false"
            name="selectedMeterpointIdentifiers"
          >
            <Checkbox
              :label="meterpointItem.label"
              :value="meterpointItem.value"
              :name="meterpointItem.value"
              :checked="value.includes(meterpointItem.value)"
              @update:checked="handleChange"
            />
          </FormField>
          <FormMessage class="col-start-2 pl-0 pt-2" />
        </FormItem>
      </FormField>
    </form>

    <Alert
      v-if="isEligibilityAlertVisible"
      class="mt-6 sm:mt-8"
      variant="warning"
    >
      <p>
        We can't complete your switch to Pay As You Go because there's an issue
        with your
        {{ notEligibleMeterpointsText }}. To fix this and avoid delays, please
        reach out to our team
        <Link class="link text-primary-600" to="tel:0330 111 5050"
          >0330 111 5050</Link
        >
        as soon as possible.
      </p>
    </Alert>
    <template #next_button>
      <Button variant="tonal" size="md" @click="onSubmit">Next</Button>
    </template>
  </StepWizardProgressCard>
</template>
