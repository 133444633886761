import { ExternalRoute } from "@/constants/externalRoute.ts"
import { Route as InternalRoute } from "@/constants/route.ts"
import {
  NativeNavbarMainItem,
  NativeNavbarOverflowItem,
  NavbarItem,
} from "@/types/Navbar.ts"

export const NAV_LABELS = {
  menu: "Menu",
  account: "Account",
  accountHome: "Account Home",
  home: "Home",
  energyUsage: "Energy Usage",
  usage: "Usage",
  billsAndStatements: "Bills & Statements",
  payments: "Payments",
  tariff: "Tariff",
  tariffDetails: "Tariff details",
  personalDetails: "Personal Details",
  referAFriend: "Refer a Friend",
  readings: "Readings",
  meterReadings: "Meter Readings",
  movingHome: "Moving Home",
  aboutUs: "About Us",
  aboutSoEnergy: "About So Energy",
  reviews: "Customer Reviews",
  news: "News & Views",
  careers: "Work for So",
  ourEnergy: "Our Energy",
  smartMeterBooking: "Smart Meter Booking",
  warmHomeDiscount: "Warm Home Discount",
  getSupport: "Get Support",
  energySaving: "Energy Saving Tips",
  unhappy: "Unhappy with Us?",
  emergency: "Emergency",
  priceCap: "Price Cap",
  troubleshooting: "IHD Troubleshooting",
  help: "Help",
  products: "Products",
  solar: "Solar",
  homeCover: "Home Cover",
  heatPumps: "Heat Pumps",
  smartMeters: "Smart Meters",
  renewal: "Renewal",
  profile: "Profile",
} as const

export type NavLabel = (typeof NAV_LABELS)[keyof typeof NAV_LABELS]

export const MAIN_NAVIGATION_ITEMS: NavbarItem[] = [
  { to: ExternalRoute.movingHome, label: NAV_LABELS.movingHome },
  { to: ExternalRoute.meterReadings, label: NAV_LABELS.meterReadings },
]

export const SIDEBAR_NAVIGATION_ITEMS: NavbarItem[] = [
  { to: ExternalRoute.renewal, label: NAV_LABELS.renewal },
  { to: InternalRoute.home, label: NAV_LABELS.accountHome },
  { to: InternalRoute.usage, label: NAV_LABELS.energyUsage },
  { to: ExternalRoute.meterReadings, label: NAV_LABELS.meterReadings },
  {
    to: ExternalRoute.billsAndStatements,
    label: NAV_LABELS.billsAndStatements,
  },
  { to: ExternalRoute.payments, label: NAV_LABELS.payments },
  {
    to: import.meta.env.VITE_USE_NEXUS_TARIFF_PAGE
      ? InternalRoute.tariff
      : ExternalRoute.tariff,
    label: NAV_LABELS.tariff,
  },
  { to: ExternalRoute.personalDetails, label: NAV_LABELS.personalDetails },
  { to: ExternalRoute.referAFriend, label: NAV_LABELS.referAFriend },
  { to: ExternalRoute.movingHome, label: NAV_LABELS.movingHome },
  { to: ExternalRoute.smartMeterBooking, label: NAV_LABELS.smartMeterBooking },
  { to: ExternalRoute.warmHomeDiscount, label: NAV_LABELS.warmHomeDiscount },
]

export const ENABLED_LINKS_ON_CLOSED_ACCOUNT: (
  | InternalRoute
  | ExternalRoute
)[] = [InternalRoute.home, ExternalRoute.billsAndStatements]

export const ACCOUNT_NAVIGATION_ITEM = {
  label: NAV_LABELS.account,
  children: SIDEBAR_NAVIGATION_ITEMS,
}

// Navbar on desktop as submenu on tablet as sub-submenu and in mobile menu as submenu.
export const ABOUT_US_NAVIGATION_ITEM = {
  label: NAV_LABELS.aboutUs,
  children: [
    {
      to: ExternalRoute.aboutUs,
      label: NAV_LABELS.aboutSoEnergy,
    },
    {
      to: ExternalRoute.reviews,
      label: NAV_LABELS.reviews,
    },
    {
      to: ExternalRoute.news,
      label: NAV_LABELS.news,
    },
    {
      to: ExternalRoute.careers,
      label: NAV_LABELS.careers,
    },
    {
      to: ExternalRoute.ourEnergy,
      label: NAV_LABELS.ourEnergy,
    },
  ],
}

// Navbar on desktop as submenu, on tablet as sub-submenu and in mobile menu as submenu.
export const HELP_NAVIGATION_ITEM = {
  label: NAV_LABELS.help,
  children: [
    { to: ExternalRoute.getSupport, label: NAV_LABELS.getSupport },
    { to: ExternalRoute.energySaving, label: NAV_LABELS.energySaving },
    { to: ExternalRoute.unhappy, label: NAV_LABELS.unhappy },
    { to: ExternalRoute.emergency, label: NAV_LABELS.emergency },
    { to: ExternalRoute.priceCap, label: NAV_LABELS.priceCap },
    { to: ExternalRoute.troubleshooting, label: NAV_LABELS.troubleshooting },
  ],
}

export const PRODUCTS_NAVIGATION_ITEM = {
  label: NAV_LABELS.products,
  children: [
    {
      to: `${ExternalRoute.solar}?utm_source=nav_bar&utm_medium=website`,
      label: NAV_LABELS.solar,
    },
    {
      to: ExternalRoute.homeCover,
      label: NAV_LABELS.homeCover,
    },
    {
      to: ExternalRoute.heatPumps,
      label: NAV_LABELS.heatPumps,
    },
    {
      to: ExternalRoute.smartMeters,
      label: NAV_LABELS.smartMeters,
    },
  ],
}

// Desktop and mobile view only
export const LOGGED_OUT_NAVIGATION_ITEMS: NavbarItem[] = [
  ACCOUNT_NAVIGATION_ITEM,
  ...MAIN_NAVIGATION_ITEMS,
  ABOUT_US_NAVIGATION_ITEM,
  HELP_NAVIGATION_ITEM,
  PRODUCTS_NAVIGATION_ITEM,
]

export const LOGGED_IN_NAVIGATION_ITEMS: NavbarItem[] = [
  ACCOUNT_NAVIGATION_ITEM,
  ABOUT_US_NAVIGATION_ITEM,
  HELP_NAVIGATION_ITEM,
  PRODUCTS_NAVIGATION_ITEM,
]

export const LOGGED_OUT_TABLET_NAVIGATION_ITEMS: NavbarItem[] = [
  {
    label: NAV_LABELS.menu,
    children: [
      ACCOUNT_NAVIGATION_ITEM,
      ...MAIN_NAVIGATION_ITEMS,
      ABOUT_US_NAVIGATION_ITEM,
      HELP_NAVIGATION_ITEM,
      PRODUCTS_NAVIGATION_ITEM,
    ],
  },
]

export const LOGGED_IN_TABLET_NAVIGATION_ITEMS: NavbarItem[] = [
  {
    label: NAV_LABELS.menu,
    children: [
      ACCOUNT_NAVIGATION_ITEM,
      ABOUT_US_NAVIGATION_ITEM,
      HELP_NAVIGATION_ITEM,
      PRODUCTS_NAVIGATION_ITEM,
    ],
  },
]

export const MOBILE_NAVIGATION_ITEMS: NavbarItem[] = [
  ...SIDEBAR_NAVIGATION_ITEMS,
  ABOUT_US_NAVIGATION_ITEM,
  HELP_NAVIGATION_ITEM,
  PRODUCTS_NAVIGATION_ITEM,
]

export const NATIVE_NAVIGATION_ITEMS: Array<
  NativeNavbarMainItem | NativeNavbarOverflowItem
> = [
  {
    label: NAV_LABELS.home,
    to: InternalRoute.home,
    icon: "MobileHome",
    showOnMainNav: true,
  },
  {
    label: NAV_LABELS.readings,
    to: InternalRoute.readings,
    icon: "MobileReadings",
    showOnMainNav: true,
  },
  {
    label: NAV_LABELS.payments,
    to: InternalRoute.payments,
    icon: "MobilePayments",
    showOnMainNav: true,
  },
  {
    label: NAV_LABELS.usage,
    to: ExternalRoute.usage,
    icon: "MobileUsage",
    showOnMainNav: true,
  },
  {
    label: NAV_LABELS.profile,
    description:
      "Personal details, payment details, address, log in, contact preferences",
    illustration: "solarDesignExperts",
    to: InternalRoute.profile,
    showOnMainNav: false,
  },
  {
    label: NAV_LABELS.tariffDetails,
    description:
      "Your tariff, start and end date, unit rate, standing charge, early exit fee",
    illustration: "variableTariff",
    to: InternalRoute.tariff,
    showOnMainNav: false,
  },
  {
    label: NAV_LABELS.referAFriend,
    description:
      "Share your referral link with friends and family, and we'll credit both your accounts with £50.",
    illustration: "referAFriend",
    to: ExternalRoute.referAFriend,
    showOnMainNav: false,
  },
  {
    label: NAV_LABELS.movingHome,
    description:
      "Just moved into a new property or out of one that's supplied by So Energy?",
    illustration: "movingOut",
    to: ExternalRoute.movingHome,
    showOnMainNav: false,
  },
]
