import {
  Address,
  AddressMeterPointDetails,
  ChangeOfTenancyMoveInRequest,
  DirectDebitInfo,
  OccupantContact,
} from "@/api/model"
import { useCookies } from "@vueuse/integrations/useCookies.mjs"
import { ref } from "vue"

export type CotMoveInRequest = Omit<
  ChangeOfTenancyMoveInRequest,
  | "billingAccountId"
  | "directDebitInfo"
  | "newOccupantContact"
  | "billingAddress"
  | "supplyAddress"
> & {
  billingAccountId?: number
}

// Extra information we use in the ui but it's not needed it in the move-in payload
export type CotMoveInExtras = {
  privacyAuthorisation: boolean
  directDebitAuthorisation: boolean
  candidateFDOR?: string
  fullAddress: string
}

const cotMoveInExtras = ref<CotMoveInExtras>({
  privacyAuthorisation: false,
  directDebitAuthorisation: false,
  candidateFDOR: undefined,
  fullAddress: "",
})

const cotMoveInRequest = ref<CotMoveInRequest>({
  billingAccountId: undefined,
  firstDateOfResponsibility: "",
})

const newOccupantContact = ref<OccupantContact>({
  dateOfBirth: "",
  email: "",
  firstName: "",
  lastName: "",
  marketingOptIn: false,
  phone1: "",
  title: undefined,
})

const moveInAddress = ref<Address>({
  addressLine1: "",
  addressLine2: undefined,
  addressLine3: undefined,
  countryCode: "",
  postcode: "",
})

const directDebitInfo = ref<DirectDebitInfo>({
  accountName: "",
  accountNumber: "",
  paymentAmount: 100,
  paymentDayOfMonth: 0,
  sortCode: "",
})

const fetchMoveInAddressFromCookie = async (): Promise<
  AddressMeterPointDetails | undefined
> => {
  const moveInAddress = await useCookies().get("move_in_address")
  if (!moveInAddress) return undefined
  return {
    address: {
      addressLine1: moveInAddress.addressLine1,
      addressLine2: moveInAddress.addressLine2,
      addressLine3: moveInAddress.addressLine3,
      countryCode: moveInAddress.countryCode,
      postcode: moveInAddress.postcode,
    },
    billingAccountId: moveInAddress.billingAccountId,
    candidateFDOR: moveInAddress.candidateFDOR,
    fullAddress: moveInAddress.fullAddress,
    mpan: moveInAddress.mpan,
    mprn: moveInAddress.mprn,
  }
}

export const useCotMoveIn = () => {
  const updateMoveInRequest = (data: Partial<CotMoveInRequest>) => {
    cotMoveInRequest.value = { ...cotMoveInRequest.value, ...data }
  }

  const updateAddress = (data: AddressMeterPointDetails) => {
    cotMoveInRequest.value = {
      ...cotMoveInRequest.value,
      billingAccountId: data.billingAccountId,
    }
    moveInAddress.value = data.address
    cotMoveInExtras.value = {
      ...cotMoveInExtras.value,
      candidateFDOR: data.candidateFDOR,
      fullAddress: data.fullAddress ?? "",
    }
  }

  const updateNewOccupantContact = (data: OccupantContact) => {
    newOccupantContact.value = data
  }

  const updateDirectDebitInfo = (data: Partial<DirectDebitInfo>) => {
    directDebitInfo.value = {
      ...directDebitInfo.value,
      ...data,
    }
  }

  const updateMoveInExtras = (data: Partial<CotMoveInExtras>) => {
    cotMoveInExtras.value = { ...cotMoveInExtras.value, ...data }
  }

  return {
    cotMoveInRequest,
    newOccupantContact,
    moveInAddress,
    directDebitInfo,
    cotMoveInExtras,
    updateMoveInRequest,
    updateAddress,
    updateMoveInExtras,
    updateNewOccupantContact,
    updateDirectDebitInfo,
    fetchMoveInAddressFromCookie,
  }
}
