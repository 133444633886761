<script setup lang="ts">
  import { Route } from "@/constants/route.ts"
  import { removeLeadingSlash } from "@/lib/utils.ts"
  import { getMyAccountPath } from "@/lib/websiteUrls.ts"
  import { Button } from "@/components/ui/button"
  import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
  } from "@/components/ui/dialog"

  const emit = defineEmits<(e: "dialogClosed") => void>()
</script>

<template>
  <Dialog :open="true" @update:open="emit('dialogClosed')">
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Authentication error</DialogTitle>
      </DialogHeader>
      <p>
        Oops! It seems like your session has expired or you're not signed in.
        Please log in again to continue.
      </p>
      <DialogFooter>
        <Button as-child variant="primary">
          <a :href="`${getMyAccountPath()}${removeLeadingSlash(Route.login)}`">
            Login
          </a>
        </Button>
        <DialogClose as-child>
          <Button variant="outline">Close</Button>
        </DialogClose>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
