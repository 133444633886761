<script setup lang="ts">
  import {
    authSession,
    refreshToken,
  } from "@/authentication/authenticationService"
  import { Route } from "@/constants/route.ts"
  import { FIVE_MINUTES, ONE_MINUTE } from "@/constants/time.ts"
  import { removeLeadingSlash } from "@/lib/utils.ts"
  import { getMyAccountPath } from "@/lib/websiteUrls.ts"
  import { useIdle } from "@vueuse/core"
  import { ref, watch } from "vue"
  import { useRoute } from "vue-router"
  import { Button } from "@/components/ui/button"
  import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
  } from "@/components/ui/dialog"

  const isTimeoutModalOpen = ref(false)
  const { idle } = useIdle(ONE_MINUTE)

  const handleModalState = (newState: boolean) => {
    isTimeoutModalOpen.value = newState
  }

  const { isExpired } = authSession
  const route = useRoute()

  // Notify that session has terminated
  watch(isExpired, async () => {
    if (!route.meta.requiresAuth || !isExpired.value) return
    isTimeoutModalOpen.value = true
  })

  // Extend session on user interaction
  watch(idle, async (isIdle) => {
    const isAuthenticated = authSession.isAuthenticated.value
    const sessionExpiry = authSession.sessionExpiry.value
    const requiresAuth = route.meta.requiresAuth

    if (!isIdle && isAuthenticated && sessionExpiry && requiresAuth) {
      const timeRemaining = sessionExpiry.getTime() - new Date().getTime()
      const isExpiringSoon = timeRemaining < FIVE_MINUTES

      if (isExpiringSoon) await refreshToken()
    }
  })
</script>

<template>
  <Dialog
    v-if="isTimeoutModalOpen"
    :open="true"
    @update:open="handleModalState"
  >
    <DialogContent class="text-center">
      <DialogHeader>
        <DialogTitle class="text-center" data-testid="dialog-title">
          Session Timeout!
        </DialogTitle>

        <p class="text-center">You have been logged out due to inactivity</p>
      </DialogHeader>

      <DialogFooter class="flex sm:justify-center">
        <Button as-child data-testid="dialog-action">
          <a :href="`${getMyAccountPath()}${removeLeadingSlash(Route.login)}`">
            Login
          </a>
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
