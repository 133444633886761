<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import {
    SelectIcon,
    SelectTrigger,
    useForwardProps,
    type SelectTriggerProps,
  } from "radix-vue"
  import { computed, type HTMLAttributes } from "vue"
  import Icon from "@/components/ui/icons/Icon.vue"

  const props = defineProps<
    SelectTriggerProps & {
      class?: HTMLAttributes["class"]
      loading?: boolean
      error?: string
    }
  >()

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
  })

  const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <SelectTrigger
    v-bind="forwardedProps"
    :class="
      cn(
        'flex h-12 w-full items-center justify-between rounded-sm border border-input bg-secondary-100 px-4 py-1 text-start text-xs sm:h-13 sm:text-base',
        'text-secondary-700 ring-primary-400 ring-offset-background hover:border-secondary-400 focus:outline-none focus-visible:border-primary-400 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-offset-0 disabled:cursor-not-allowed disabled:border-secondary-500 disabled:bg-secondary-300 disabled:text-secondary-400 [&>span]:truncate',
        error && [
          'border-red-400 ring-red-400 hover:border-red-400 focus:ring-1',
        ],
        props.class,
      )
    "
  >
    <slot />
    <SelectIcon as-child>
      <Icon v-if="loading" class="mr-2 animate-spin" name="Spinner" />
      <Icon v-else name="ChevronDown" />
    </SelectIcon>
  </SelectTrigger>
</template>
