<script setup lang="ts">
  import { useGetAlertsAndBanners } from "@/api/alerts/alerts"
  import { type IllustrationName } from "@/constants/illustrations"
  import { useGetAccountId } from "@/lib/useGetAccountId"
  import { ensureResolvedWebsiteUrl } from "@/lib/websiteUrls"
  import TrackingService from "@/services/TrackingService"
  import { whenever } from "@vueuse/core"
  import { computed } from "vue"
  import { Banner } from "@/components/ui/banner"
  import { Skeleton } from "@/components/ui/skeleton"

  const { showSkeleton } = defineProps<{
    showSkeleton?: boolean
  }>()

  const getAccountIdQuery = useGetAccountId()
  const getAlertsAndBannersQuery = useGetAlertsAndBanners(
    getAccountIdQuery.accountId,
  )
  const banner = computed(() => {
    return getAlertsAndBannersQuery.data.value?.marketingBanner
  })

  const onBannerButtonClicked = (link: string) => {
    TrackingService.sendEvent("marketing-banner_action-click", {
      entityName: banner.value?.name,
      eventAction: link,
    })
    const normalizedUrl = ensureResolvedWebsiteUrl(link)
    window.location.assign(normalizedUrl)
  }

  whenever(
    banner,
    (banner) => {
      TrackingService.sendEvent("marketing-banner_impression", {
        entityName: banner.name,
      })
    },
    { once: true },
  )
</script>

<template>
  <div v-if="banner" class="space-y-4 sm:space-y-6">
    <Banner
      :label="banner.buttonText"
      :title="banner.title"
      :content="banner.subtitle"
      :illustration="banner.illustrationName as IllustrationName"
      :show-close-button="false"
      @action-button-click="onBannerButtonClicked(banner.buttonLink)"
    />
  </div>
  <Skeleton
    v-else-if="showSkeleton && getAlertsAndBannersQuery.isLoading.value"
    class="mt-2 h-[203px] w-full"
  />
</template>
