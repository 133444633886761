import { TariffRate } from "@/api/model"
import { RateNames } from "@/types/RateNames"

export const getTariffRateTitle = (tariffRate: TariffRate) => {
  const title = "Unit rate"
  const mappedNames = {
    [RateNames.EvOffPeak]: "off-peak",
    [RateNames.EvPeak]: "peak",
  }

  const rateName =
    mappedNames[tariffRate.name as keyof typeof mappedNames] ?? tariffRate.name

  if (tariffRate.name === RateNames.Standard) return title

  if (tariffRate.startHour && tariffRate.endHour) {
    return `${title} ${rateName} (${tariffRate.startHour} - ${tariffRate.endHour})`
  }

  return `${title} (${rateName})`
}
