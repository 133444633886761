<script setup lang="ts">
  import { getPublicWebsiteUrl } from "@/lib/websiteUrls"
  import { openPreferencesModal } from "@/services/cookieConsent/CookieConsentPlugin"
  import { Link } from "@/components/ui/link"

  const PUBLIC_WEBSITE_URL = getPublicWebsiteUrl()

  const ourCompanyLinks = [
    {
      label: "About So Energy",
      href: `${PUBLIC_WEBSITE_URL}about-us`,
    },
    {
      label: "Our Tariffs",
      href: `${PUBLIC_WEBSITE_URL}energy/tariffs`,
    },
    {
      label: "Feed In Tariff",
      href: `${PUBLIC_WEBSITE_URL}support/solutions/articles/7000070564-feed-in-tariff-fit-faqs`,
    },
    {
      label: "Emergency",
      href: `${PUBLIC_WEBSITE_URL}help/emergency`,
    },
    {
      label: "Careers",
      href: "https://careers.so.energy/",
    },
  ]

  const importantInformationLinksList = [
    [
      {
        label: "T&Cs",
        href: `${PUBLIC_WEBSITE_URL}terms`,
      },
      {
        label: "Privacy Policy",
        href: `${PUBLIC_WEBSITE_URL}privacy`,
      },
      {
        label: "Cookie Policy",
        href: `${PUBLIC_WEBSITE_URL}cookies`,
      },
    ],
    [
      {
        label: "Modern Slavery Statement",
        href: `${PUBLIC_WEBSITE_URL}modern-slavery`,
      },
      {
        label: "Gender & Ethnicity Pay Report",
        href: `${PUBLIC_WEBSITE_URL}pay-report`,
      },
      {
        label: "Accessibility Statement",
        href: `${PUBLIC_WEBSITE_URL}accessibility-statement`,
      },
    ],
  ]
</script>

<template>
  <footer
    class="m-3 mt-auto grid grid-cols-1 gap-4 rounded-md bg-secondary-700 p-6 text-secondary-100 sm:grid-cols-[min-content_1fr_1fr_1fr] sm:gap-y-12 lg:pr-36 hover:[&_a]:underline"
  >
    <div>
      <div class="label-text-strong mb-2.5">Our company</div>
      <div class="label-text flex flex-col">
        <Link v-for="link in ourCompanyLinks" :key="link.label" :to="link.href">
          {{ link.label }}
        </Link>
      </div>
    </div>
    <div class="sm:col-span-2">
      <div class="label-text-strong mb-2.5">Important Information</div>
      <div class="label-text grid grid-cols-2">
        <div
          v-for="(links, index) in importantInformationLinksList"
          :key="index"
          class="flex flex-col"
        >
          <Link v-for="link in links" :key="link.label" :to="link.href">
            {{ link.label }}
          </Link>

          <Link
            v-if="index === 0"
            to="javascript:;"
            @click="openPreferencesModal"
          >
            Cookie preferences
          </Link>
        </div>
      </div>
    </div>
    <div class="label-text sm:col-span-3">
      <div class="mb-2.5">&copy; {{ new Date().getFullYear() }} So Energy</div>
      <p class="label-text">
        So Energy Trading Limited is registered in England and Wales. Registered
        number: 09263295
      </p>
      <p class="label-text">
        Registered address: Studio 2, Power Road Studios, 114 Power Road,
        Chiswick, W4 5PY
      </p>
      <p class="label-text">
        So Energy are authorised and regulated by the Financial Conduct
        Authority, under reference number 935278 to act as a credit broker, not
        a lender, and offer consumer finance exclusively from Tandem Bank
        Limited.
      </p>
    </div>
    <div class="w-full sm:col-start-1 sm:row-span-2 sm:row-start-1 sm:pr-24">
      <img
        src="@/assets/images/logo-light.svg"
        alt="So Energy logo"
        class="relative w-full sm:min-w-36"
      />
    </div>
  </footer>
</template>
