<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import type { HTMLAttributes } from "vue"

  const props = defineProps<{
    class?: HTMLAttributes["class"]
  }>()
</script>

<template>
  <div
    :class="
      cn(
        'mt-auto flex flex-col-reverse gap-3 pt-3 sm:flex-row sm:justify-end',
        props.class,
      )
    "
    data-testid="card-footer"
  >
    <slot />
  </div>
</template>
