<script setup lang="ts">
  import { sortCodeRegexInput } from "@/constants/input"
  import { useVModel } from "@vueuse/core"
  import { useForwardProps } from "radix-vue"
  import { computed, onMounted } from "vue"
  import TextInput from "@/components/ui/inputs/text-input/TextInput.vue"

  const props = defineProps<{
    modelValue?: string
    label: string
    name: string
  }>()

  const emits =
    defineEmits<(e: "update:modelValue", payload: string | undefined) => void>()

  const modelValue = useVModel(props, "modelValue", emits, {
    passive: true,
  })

  const formatSortCode = () => {
    const value = modelValue.value?.toString()
    const segments = value && sortCodeRegexInput.exec(value)

    if (!value || !segments) {
      modelValue.value = value
      return
    }

    const formattedSortCode = [segments[1], segments[2], segments[3]]
      .filter(Boolean)
      .join("-")

    modelValue.value = formattedSortCode + (segments[4] || "")
  }

  onMounted(formatSortCode)

  const delegatedProps = computed(() => {
    const { modelValue: _, ...delegated } = props

    return delegated
  })

  const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <TextInput
    v-model="modelValue"
    v-bind="forwardedProps"
    placeholder="00-00-00"
    @blur="formatSortCode"
  />
</template>
