import { cva, type VariantProps } from "class-variance-authority"

export { default as Icon } from "@/components/ui/icons/Icon.vue"

const iconConfig = {
  variants: {
    variant: {
      primary: "stroke-secondary-700 dark:stroke-secondary-100",
      secondary: "stroke-secondary-700",
      inverted: "stroke-secondary-100",
    },
    size: {
      sm: "h-3 w-3",
      md: "h-4.5 w-4.5",
      lg: "h-6 w-6",
    },
  },
  defaultVariants: {
    variant: "primary",
    size: "md",
  },
} as const

export const iconVariants = cva(
  "icon flex justify-center items-center",
  iconConfig,
)

export type IconVariants = VariantProps<typeof iconVariants>

export const iconVariantKeys = Object.keys(
  iconConfig.variants.variant,
) as Array<keyof typeof iconConfig.variants.variant>

export const iconSizeKeys = Object.keys(iconConfig.variants.size) as Array<
  keyof typeof iconConfig.variants.size
>
