<script setup lang="ts">
  import { useGetAccount } from "@/api/account/account"
  import { usePostSmartPayAsYouGo } from "@/api/smart-pay-as-you-go/smart-pay-as-you-go"
  import { Route } from "@/constants/route"
  import { aggregateQueries } from "@/lib/aggregateQueries"
  import { formatDate } from "@/lib/dateHelper"
  import { useGetAccountId } from "@/lib/useGetAccountId"
  import { useSmartPayAsYouGo } from "@/lib/useSmartPayAsYouGo"
  import ErrorReporter from "@/services/ErrorReporter"
  import { StepWizardReviewContent } from "@/types/StepWizard"
  import { computed } from "vue"
  import { useRouter } from "vue-router"
  import ErrorMessage from "@/components/ErrorMessage.vue"
  import { AlertDescription } from "@/components/ui/alert"
  import Alert from "@/components/ui/alert/Alert.vue"
  import Button from "@/components/ui/button/Button.vue"
  import { Link } from "@/components/ui/link"
  import { StepWizardProgressCard } from "@/components/ui/step-wizard"
  import StepWizardReview from "@/components/ui/step-wizard/StepWizardReview.vue"

  const router = useRouter()
  const accountIdQuery = useGetAccountId()
  const account = useGetAccount(accountIdQuery.accountId)

  const { isLoading, isError } = aggregateQueries([accountIdQuery, account])

  const {
    mutateAsync: submitSmartPayAsYouGo,
    isError: isSpaygError,
    isPending: isSpaygLoading,
  } = usePostSmartPayAsYouGo()

  const {
    selectedMeterpointLabels,
    switchDate,
    spaygTariffName,
    spaygRequest,
  } = useSmartPayAsYouGo()

  const formattedDate = computed(() =>
    switchDate.value
      ? formatDate(switchDate.value, "ordinal", "default")
      : "--",
  )

  const reviewInformation = computed<StepWizardReviewContent[]>(() => [
    {
      content: [
        {
          title: "Fuel type",
          editStep: 2,
          text: selectedMeterpointLabels.value,
        },
      ],
    },
    {
      content: [
        {
          title: "Switch date",
          editStep: 3,
          text: [formattedDate.value],
        },
      ],
    },
    {
      content: [
        {
          title: "Your tariff",
          text: [spaygTariffName.value],
        },
      ],
    },
  ])

  const onSubmit = async () => {
    try {
      await submitSmartPayAsYouGo({
        accountId: accountIdQuery.accountId.value,
        params: {
          billingAccountNumber: account.data.value?.accountNumber || "",
        },
        data: spaygRequest.value,
      })

      router.push(Route.spaygReview)
    } catch (error) {
      ErrorReporter.report(error, {
        accountId: accountIdQuery.accountId.value,
        billingAccountNumber: account.data.value?.accountNumber,
      })
    }
  }
</script>

<template>
  <StepWizardProgressCard title="Review information">
    <StepWizardReview :content-information="reviewInformation" />

    <ErrorMessage v-if="isError" />
    <Alert v-if="isSpaygError" variant="error" class="body-text mt-6 sm:mt-4">
      <AlertDescription>
        We're having trouble processing your request. Please try again later. If
        the issue continues, call our team at
        <Link class="link text-primary-600" to="tel:0330 111 5050"
          >0330 111 5050</Link
        >.
      </AlertDescription>
    </Alert>
    <template #next_button>
      <Button
        :disabled="isLoading || isError"
        :loading="isSpaygLoading"
        variant="tonal"
        size="md"
        @click="onSubmit"
        >Submit</Button
      >
    </template>
  </StepWizardProgressCard>
</template>
