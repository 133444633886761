<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import {
    SelectScrollDownButton,
    useForwardProps,
    type SelectScrollDownButtonProps,
  } from "radix-vue"
  import { computed, type HTMLAttributes } from "vue"
  import Icon from "@/components/ui/icons/Icon.vue"

  const props = defineProps<
    SelectScrollDownButtonProps & { class?: HTMLAttributes["class"] }
  >()

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
  })

  const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <SelectScrollDownButton
    v-bind="forwardedProps"
    :class="
      cn('flex cursor-default items-center justify-center py-1', props.class)
    "
  >
    <slot>
      <Icon name="ChevronDown" />
    </slot>
  </SelectScrollDownButton>
</template>
