<script lang="ts" setup>
  import { cn } from "@/lib/utils"
  import { Card, CardContent, CardFooter } from "@/components/ui/card"
  import { Link } from "@/components/ui/link"

  import successCheck from "@/assets/images/success-check.svg"
  import trustpilotScore from "@/assets/images/trustpilot-score.svg"

  const props = defineProps<{
    title: string
  }>()
</script>

<template>
  <div class="flex justify-center py-5">
    <Card class="sm:max-w-3xl">
      <CardContent
        class="flex flex-col items-center text-secondary-500 sm:px-12"
      >
        <img
          :src="successCheck"
          alt="Success Check"
          class="mb-6 w-40 sm:w-48"
        />
        <h3 class="mb-6 text-center sm:mb-8">
          {{ props.title }}
        </h3>
        <div class="item-left" data-testid="default-slot">
          <slot />
        </div>
        <Link
          class="mb-3 sm:mb-0"
          to="https://ie.trustpilot.com/review/so.energy"
        >
          <img
            :class="cn('w-32')"
            :src="trustpilotScore"
            alt="Trustpilot Score"
        /></Link>
      </CardContent>
      <CardFooter class="sm:justify-center" data-testid="footer-slot">
        <slot name="footer" />
      </CardFooter>
    </Card>
  </div>
</template>
