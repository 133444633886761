<script setup lang="ts">
  import { type IllustrationName } from "@/constants/illustrations"
  import { computed } from "vue"
  import { Button } from "@/components/ui/button"
  import { IconButton } from "@/components/ui/icon-button"

  const emits = defineEmits(["actionButtonClick", "closeButtonClick"])

  const props = defineProps<{
    title: string
    content: string
    label: string
    illustration?: IllustrationName
    showCloseButton: boolean
  }>()

  const onCloseButtonClick = () => {
    emits("closeButtonClick")
  }
  const onActionButtonClick = () => {
    emits("actionButtonClick")
  }
  const imageUrl = computed(
    () =>
      new URL(
        `../../../assets/illustrations/${props.illustration}.svg`,
        import.meta.url,
      ).href,
  )
</script>

<template>
  <div
    class="flex flex-wrap justify-between rounded border border-primary-300 bg-secondary-500 p-4 sm:p-8"
  >
    <div
      class="right-8 top-8 mb-1 flex shrink-0 grow-0 basis-full justify-between sm:absolute"
    >
      <p class="h3 text-secondary-100 sm:hidden" data-testid="banner-title">
        {{ props.title }}
      </p>
      <div v-if="showCloseButton" class="inline-flex">
        <IconButton
          inverted
          icon="x"
          label="Close sheet"
          size="md"
          class="p-0 hover:bg-transparent active:bg-transparent"
          data-testid="banner-close-btn"
          @click="onCloseButtonClick"
        />
      </div>
    </div>
    <div class="flex-1">
      <p
        data-testid="banner-title-desktop"
        class="h1 hidden text-secondary-100 sm:mb-2 sm:block"
      >
        {{ props.title }}
      </p>
      <p
        data-testid="banner-content"
        class="mb-4 text-sm leading-6 text-secondary-100 sm:mb-6 sm:text-base sm:leading-7"
      >
        {{ props.content }}
      </p>
      <Button
        data-testid="banner-cta-btn-desktop"
        class="hidden sm:block"
        size="lg"
        @click="onActionButtonClick"
        >{{ props.label }}</Button
      >
    </div>
    <div class="sm:align-center flex">
      <img
        data-testid="banner-illustration"
        class="ml-2 h-[92px] max-w-fit sm:ml-6 sm:mr-4 sm:h-[140px]"
        :src="imageUrl"
        alt=""
      />
    </div>
    <Button
      class="mt-2 w-full sm:hidden"
      data-testid="banner-cta-btn"
      size="md"
      @click="onActionButtonClick"
      >{{ props.label }}</Button
    >
  </div>
</template>
