<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import type { HTMLAttributes } from "vue"

  const props = defineProps<{
    class?: HTMLAttributes["class"]
  }>()
</script>

<template>
  <tr :class="cn('body-text border-b-2', props.class)">
    <slot />
  </tr>
</template>
