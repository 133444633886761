<script setup lang="ts">
  import { authSession } from "@/authentication/authenticationService"
  import { ExternalRoute } from "@/constants/externalRoute.ts"
  import { Route } from "@/constants/route.ts"
  import { useNavigationItems } from "@/lib/useNavigationItems.ts"
  import { useSwitchAccountModal } from "@/lib/useSwitchAccountModal"
  import { computed } from "vue"
  import { useRoute } from "vue-router"
  import LogoutButton from "@/components/shared/LogoutButton.vue"
  import { Button } from "@/components/ui/button"
  import { Link } from "@/components/ui/link"

  import { Navbar } from "."

  const { navigationItems, extendedNavigationItems, mobileNavigationItems } =
    useNavigationItems()
  const route = useRoute()
  const { isAuthenticated } = authSession

  const isMaintenanceRoute = computed(() => route.path === Route.maintenance)

  const { hasMultipleAccounts, openModal } = useSwitchAccountModal()
</script>
<template>
  <Navbar
    :desktop-navigation-items="navigationItems"
    :tablet-navigation-items="extendedNavigationItems"
    :mobile-navigation-items="mobileNavigationItems"
  >
    <Button
      v-if="!isAuthenticated"
      as-child
      variant="primary"
      data-testid="get-a-quote-button"
    >
      <Link :to="ExternalRoute.getAQuote">Get a Quote</Link>
    </Button>
    <template v-if="!isMaintenanceRoute">
      <Button
        v-if="hasMultipleAccounts"
        variant="outline"
        class="sm:hidden"
        @click="openModal"
      >
        Switch Account
      </Button>
      <LogoutButton v-if="isAuthenticated" variant="outline" />
      <Button v-else as-child variant="outline">
        <Link :to="Route.login" data-testid="login-button">Account</Link>
      </Button>
    </template>
    <Button v-else disabled variant="outline" data-testid="login-button">
      Account
    </Button>
  </Navbar>
</template>
