<script setup lang="ts">
  import { authSession } from "@/authentication/authenticationService.ts"
  import { useAnalyticsTracking } from "@/lib/useAnalyticsTracking"
  import useAuthErrorDialog from "@/lib/useAuthErrorDialog"
  import { useBreakpoint } from "@/lib/useBreakpoint.ts"
  import { useSwitchAccountModal } from "@/lib/useSwitchAccountModal"
  import { useColorMode } from "@vueuse/core"
  import { computed, inject, onMounted } from "vue"
  import { useRoute } from "vue-router"
  import AuthErrorDialog from "@/components/AuthErrorDialog.vue"
  import SwitchAccountModal from "@/components/dialogs/SwitchAccountModal.vue"
  import NavbarContainer from "@/components/navbar/NavbarContainer.vue"
  import OnboardingDialog from "@/components/OnboardingDialog.vue"
  import TimeoutModal from "@/components/TimeoutModal.vue"
  import Footer from "@/components/ui/footer/Footer.vue"

  import { LayoutComponent, Layouts } from "./types/Layout"

  const mode = useColorMode()
  const isStaging = inject<boolean>("isStaging")
  const isTablet = useBreakpoint("sm")
  const { isAuthenticated } = authSession
  const isDialogEnabled =
    import.meta.env.VITE_SHOW_ONBOARDING_DIALOG === "true" &&
    import.meta.env.VITE_USE_NEXUS_HOMEPAGE === "true"
  const route = useRoute()
  const layoutComponent = computed(
    () =>
      LayoutComponent[route.meta?.layout as keyof typeof Layouts] ||
      LayoutComponent[Layouts.DEFAULT],
  )

  const { isModalOpen, hasMultipleAccounts } = useSwitchAccountModal()

  onMounted(() => {
    mode.value = "light"
  })

  const { isErrorDialogShown } = useAuthErrorDialog()

  useAnalyticsTracking()
</script>

<template>
  <div class="flex min-h-full w-full flex-col">
    <div class="sticky top-0 z-50">
      <p
        v-if="isStaging"
        role="alert"
        data-testid="staging-banner"
        class="top-0 w-full bg-yellow-300 p-2 text-center text-black"
      >
        <span class="font-medium">Staging preview:</span> Some changes may be
        unfinished.
        <br v-if="!isTablet" />
        Please use the
        <a class="link text-black" href="https://fe-nexus.sandbox.soenergy.co/"
          >sandbox environment</a
        >.
      </p>
      <NavbarContainer />
    </div>

    <component :is="layoutComponent">
      <RouterView :data-pagetitle="String($route?.meta?.title)" />
    </component>

    <Footer />
    <SwitchAccountModal v-if="hasMultipleAccounts" v-model:open="isModalOpen" />
    <AuthErrorDialog
      v-if="isErrorDialogShown"
      @dialog-closed="isErrorDialogShown = false"
    />
    <TimeoutModal />
    <OnboardingDialog v-if="isDialogEnabled && isAuthenticated" />
  </div>
</template>
