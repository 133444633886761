<script setup lang="ts">
  import { useLogout } from "@/authentication/authenticationService"
  import { Route } from "@/constants/route"
  import { useQueryClient } from "@tanstack/vue-query"
  import { HTMLAttributes, ref } from "vue"
  import { useRouter } from "vue-router"
  import LogoutErrorDialog from "@/components/shared/LogoutErrorDialog.vue"
  import { Button, type ButtonVariants } from "@/components/ui/button"

  export interface LogoutButtonProps {
    variant?: ButtonVariants["variant"]
    class?: HTMLAttributes["class"]
  }

  const props = defineProps<LogoutButtonProps>()
  const isLogoutErrorShown = ref(false)

  const queryClient = useQueryClient()
  const router = useRouter()

  const logout = useLogout({
    onSuccess: () => {
      queryClient.removeQueries()
      router.push(Route.login)
    },
    onError: () => {
      isLogoutErrorShown.value = true
    },
  })
</script>

<template>
  <Button
    :class="props.class"
    :variant="props.variant"
    :loading="logout.isPending.value"
    @click="logout.mutate()"
  >
    Log out
  </Button>
  <LogoutErrorDialog
    v-if="isLogoutErrorShown"
    @dialog-closed="isLogoutErrorShown = false"
  />
</template>
