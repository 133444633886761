import { GenericEventParams } from "@/services/TrackingService.ts"

interface GTMCustomEvent {
  event: string
  userId?: string
  [key: string]: unknown
}

type GTMEvent = {
  eventName: string
} & GenericEventParams

export default {
  sendGenericEvent(params: GTMEvent) {
    const {
      eventName,
      eventCategory = "N/A",
      eventAction = "N/A",
      entityName = "N/A",
      entityType = "N/A",
      entitySubtype = "N/A",
      entityVariant = "N/A",
      statusType = "N/A",
      value = "N/A",
    } = params

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "genericEvent",
      event_name: eventName,
      event_category: eventCategory,
      event_action: eventAction,
      entity_name: entityName,
      entity_type: entityType,
      entity_subtype: entitySubtype,
      entity_variant: entityVariant,
      status_type: statusType,
      value,
    })
  },
  sendCustomEvent(eventData: GTMCustomEvent): void {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(eventData)
  },
}
