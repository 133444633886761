<script setup lang="ts">
  import { ErrorCode } from "@/api/model"
  import {
    useValidateBankingDetails,
    ValidateBankingDetailsMutationError,
  } from "@/api/payments/payments"
  import { sortCodeRegexValidation } from "@/constants/input"
  import { onlyNumbersRegex, onlyTextRegex } from "@/constants/validation"
  import { useCotMoveIn } from "@/lib/useCotMoveIn"
  import { useStepWizard } from "@/lib/useStepWizard"
  import { toTypedSchema } from "@vee-validate/zod"
  import { useForm } from "vee-validate"
  import { computed } from "vue"
  import { z } from "zod"
  import Alert from "@/components/ui/alert/Alert.vue"
  import { Button } from "@/components/ui/button"
  import Checkbox from "@/components/ui/inputs/checkbox/Checkbox.vue"
  import SortCodeInput from "@/components/ui/inputs/sort-code-input/SortCodeInput.vue"
  import TextInput from "@/components/ui/inputs/text-input/TextInput.vue"
  import { StepWizardProgressCard } from "@/components/ui/step-wizard"

  const { goNext } = useStepWizard()
  const {
    directDebitInfo,
    updateDirectDebitInfo,
    cotMoveInExtras,
    updateMoveInExtras,
  } = useCotMoveIn()

  const formSchema = toTypedSchema(
    z.object({
      directDebitAuthorisation: z.literal<boolean>(true, {
        errorMap: () => ({
          message:
            "You must be authorised to setup direct debits on this bank account",
        }),
      }),
      privacyAuthorisation: z.literal<boolean>(true, {
        errorMap: () => ({
          message: "Please accept the terms and conditions before continuing",
        }),
      }),
      accountName: z
        .string({
          required_error: "This field is required",
          invalid_type_error: "This field is required",
        })
        .min(1, "This field is required")
        .min(2, "Account holder name must be at least 2 characters long")
        .regex(
          onlyTextRegex,
          "Account holder name should contain only letters and spaces",
        ),
      accountNumber: z
        .string({
          required_error: "This field is required",
          invalid_type_error: "This field is required",
        })
        .min(1, "This field is required")
        .regex(onlyNumbersRegex, "Account number should contain only numbers.")
        .min(7, "Account number must be 7 or 8 numbers long")
        .max(8, "Account number must be 7 or 8 numbers long"),
      sortCode: z
        .string({
          required_error: "This field is required",
          invalid_type_error: "This field is required",
        })
        .min(1, "This field is required")
        .length(8, "Sort code must be exactly 6 digits long")
        .regex(
          sortCodeRegexValidation,
          "Sort code can only contain numbers and dashes",
        ),
    }),
  )

  const { handleSubmit, setErrors } = useForm({
    validationSchema: formSchema,
  })

  const DDValidation = useValidateBankingDetails()
  const isDDError = computed(
    () =>
      DDValidation.isError.value ||
      (DDValidation.isSuccess.value && !DDValidation.data.value?.success),
  )
  const submit = handleSubmit(async (data) => {
    updateDirectDebitInfo({
      accountName: data.accountName,
      accountNumber: data.accountNumber,
      sortCode: data.sortCode,
    })
    updateMoveInExtras({
      privacyAuthorisation: data.privacyAuthorisation,
      directDebitAuthorisation: data.directDebitAuthorisation,
    })

    try {
      await DDValidation.mutateAsync({
        data: {
          sortCode: data.sortCode,
          accountNumber: data.accountNumber,
        },
      })

      if (!isDDError.value) {
        goNext()
      }
    } catch (error) {
      const typedError = error as ValidateBankingDetailsMutationError
      const errorCode = typedError.response?.data.errorCode

      if (errorCode === ErrorCode.invalidBankingDetails) {
        setErrors({
          sortCode: "The account number and sort code do not match",
          accountNumber: "The account number and sort code do not match",
        })
      }
    }
  })
</script>

<template>
  <StepWizardProgressCard title="Payment details">
    <form class="space-y-6">
      <div class="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2 sm:gap-y-4">
        <TextInput
          v-model="directDebitInfo.accountName"
          name="accountName"
          label="Account holder name"
        />
        <TextInput
          v-model="directDebitInfo.accountNumber"
          name="accountNumber"
          label="Account number"
        />
        <SortCodeInput
          v-model="directDebitInfo.sortCode"
          name="sortCode"
          label="Sort code"
        />
      </div>
      <div class="flex items-center gap-4">
        <img
          src="@/assets/images/direct-debit.svg"
          alt="Direct debit logo"
          class="w-[97px] sm:w-[122px]"
        />
        <div class="label-text">
          Your payment is protected by the
          <a
            href="https://www.directdebit.co.uk/direct-debit-guarantee/"
            class="label-text block text-primary-600 underline max-[500px]:inline-block max-[375px]:inline min-[375px]:whitespace-nowrap"
            target="_blank"
            >Direct Debit Guarantee</a
          >
        </div>
      </div>
      <div class="space-y-3">
        <Checkbox
          v-model="cotMoveInExtras.directDebitAuthorisation"
          label="I am authorised to setup Direct Debits on this bank account"
          name="directDebitAuthorisation"
        />
        <Checkbox
          v-model="cotMoveInExtras.privacyAuthorisation"
          label="I agree to So Energy’s Terms & Conditions and Privacy Policy"
          name="privacyAuthorisation"
        >
          <template #label>
            I agree to So Energy’s
            <a
              href="https://www.so.energy/terms"
              class="label-text whitespace-nowrap text-primary-600 underline"
              target="_blank"
              >Terms & Conditions
            </a>
            and
            <a
              href="https://www.so.energy/privacy/"
              class="label-text whitespace-nowrap text-primary-600 underline"
              target="_blank"
              >Privacy Policy
            </a>
          </template>
        </Checkbox>
      </div>
      <Alert variant="info">
        Your direct debit will be set up once you’ve completed signing up to So
        Energy
      </Alert>
      <Alert v-if="isDDError" variant="error">
        We’re unable to process your payment details at the moment. Please check
        your information and try again.
      </Alert>
    </form>
    <template #next_button>
      <Button variant="tonal" size="md" @click="submit">Next</Button>
    </template>
  </StepWizardProgressCard>
</template>
