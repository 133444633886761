import { ExternalRoute } from "@/constants/externalRoute.ts"
import { getPublicWebsiteUrl } from "@/lib/websiteUrls.ts"
import shouldServeNexus from "@/middleware/shouldServeNexus.ts"
import { InAppBrowser, OpenWebViewOptions, UrlEvent } from "@capgo/inappbrowser"

const openBrowser = async (url: ExternalRoute) => {
  const options: OpenWebViewOptions = {
    activeNativeNavigationForWebview: false,
    disableGoBackOnNativeApplication: true,
    showArrow: false,
    showReloadButton: false,
    visibleTitle: false,
    title: "", // empty title to completely hide the top bar
    url,
  }

  await InAppBrowser.openWebView(options)
  await InAppBrowser.addListener("closeEvent", async () => {
    await InAppBrowser.removeAllListeners()
  })
}

/**
 * once the in-app browser navigates to a page on the same parent domain
 * as the app but which is not an external route, we can assume it's done
 * whatever it needed to do, close it, but then we'll potentially be in
 * the wrong location so let's navigate to the url the browser attempted
 * to hit. In order to make sure we gert fresh state we'll force a reload
 */
const onUrlChanged = async (event: UrlEvent) => {
  const url = new URL(event.url)
  const publicWebsiteUrl = new URL(getPublicWebsiteUrl())

  if (
    url.hostname === publicWebsiteUrl.hostname &&
    shouldServeNexus(url.pathname, url.hostname, url.hostname, import.meta.env)
  ) {
    await InAppBrowser.removeAllListeners()
    await InAppBrowser.close()

    window.location.assign(`${url.pathname}${url.search}`)
  }
}

export default {
  openBrowser,
  onUrlChanged,
}
