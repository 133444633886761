export const Route = {
  home: `/`,
  profile: `/profile`,
  login: `/login`,
  moveIn: `/move-in`,
  usage: `/usage`,
  cotMoveInPersonalDetails: `personal-details`,
  cotMoveInFindAddress: `move-in-address`,
  cotMoveInMonthlyPayment: `monthly-payment`,
  cotMoveInFdor: "fdor",
  cotMoveInPayments: "payments",
  cotMoveInReview: "review",
  cotMoveInComplete: "/move-in-complete",
  spayg: `/smart-pay-as-you-go`,
  spaygWhy: `why`,
  spaygSuitability: `suitability`,
  spaygComplete: `/smart-pay-as-you-go-complete`,
  spaygFuel: `choose-fuel`,
  spaygSwitchDate: `switch-date`,
  spaygTariff: `tariff`,
  spaygReview: `review`,
  maintenance: `/maintenance`,
  passwordReset: `/password-reset`,
  passwordChange: `/password-change`,
  activateAccount: `/activate-account`,
  setPassword: `/set-password`,
  readings: "/readings",
  tariff: "/tariff",
  help: "/help",
  payments: "/payments",
  directDebit: "/direct-debit",
  notFound: `/:pathMatch(.*)*`,
} as const

export type Route = (typeof Route)[keyof typeof Route]
