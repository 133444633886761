/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Portal API Gateway
 * OpenAPI spec version: 2.0.0
 */
import {
  useMutation,
  useQuery,
  type MutationFunction,
  type QueryFunction,
  type QueryKey,
  type UseMutationOptions,
  type UseMutationReturnType,
  type UseQueryOptions,
  type UseQueryReturnType,
} from "@tanstack/vue-query"
import { unref, type MaybeRef } from "vue"

import { customInstance, type ErrorType } from ".././client"
import type {
  CheckSmartPayAsYouGoEligibilityParams,
  Error,
  GetSmartPayAsYouGoExecutionDateParams,
  GetSmartPayAsYouGoStatusParams,
  PostSmartPayAsYouGoParams,
  SmartPayAsYouGoEligibilityResponse,
  SmartPayAsYouGoExecutionDateResponse,
  SmartPayAsYouGoQuoteRequest,
  SmartPayAsYouGoQuoteResponse,
} from ".././model"
import type {
  SmartPayAsYouGoRequest,
  SmartPayAsYouGoStatusResponse,
} from ".././model/omponents"
import { useMutationOptions } from ".././mutationOptions"
import { useQueryOptions } from ".././queryOptions"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary Retrieve So Pay As You Go Eligibility
 */
export const checkSmartPayAsYouGoEligibility = (
  accountId: MaybeRef<number>,
  params: MaybeRef<CheckSmartPayAsYouGoEligibilityParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  accountId = unref(accountId)
  params = unref(params)

  return customInstance<SmartPayAsYouGoEligibilityResponse[]>(
    {
      url: `/api/v1/accounts/${accountId}/smartPayAsYouGo/eligibility`,
      method: "GET",
      params: unref(params),
      signal,
    },
    options,
  )
}

export const getCheckSmartPayAsYouGoEligibilityQueryKey = (
  accountId: MaybeRef<number>,
  params: MaybeRef<CheckSmartPayAsYouGoEligibilityParams>,
) => {
  return [
    "api",
    "v1",
    "accounts",
    accountId,
    "smartPayAsYouGo",
    "eligibility",
    ...(params ? [params] : []),
  ] as const
}

export const useCheckSmartPayAsYouGoEligibilityQueryOptions = <
  TData = Awaited<ReturnType<typeof checkSmartPayAsYouGoEligibility>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  params: MaybeRef<CheckSmartPayAsYouGoEligibilityParams>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof checkSmartPayAsYouGoEligibility>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getCheckSmartPayAsYouGoEligibilityQueryKey(accountId, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof checkSmartPayAsYouGoEligibility>>
  > = ({ signal }) =>
    checkSmartPayAsYouGoEligibility(accountId, params, requestOptions, signal)

  const customOptions = useQueryOptions(
    { ...queryOptions, queryKey, queryFn },
    { accountId, params },
  )

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof checkSmartPayAsYouGoEligibility>>,
    TError,
    TData
  >
}

export type CheckSmartPayAsYouGoEligibilityQueryResult = NonNullable<
  Awaited<ReturnType<typeof checkSmartPayAsYouGoEligibility>>
>
export type CheckSmartPayAsYouGoEligibilityQueryError = ErrorType<Error>

/**
 * @summary Retrieve So Pay As You Go Eligibility
 */
export const useCheckSmartPayAsYouGoEligibility = <
  TData = Awaited<ReturnType<typeof checkSmartPayAsYouGoEligibility>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  params: MaybeRef<CheckSmartPayAsYouGoEligibilityParams>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof checkSmartPayAsYouGoEligibility>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useCheckSmartPayAsYouGoEligibilityQueryOptions(
    accountId,
    params,
    options,
  )

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

/**
 * @summary Calculate So Pay As You Go Execution date based in configured number of working days
 */
export const getSmartPayAsYouGoExecutionDate = (
  accountId: MaybeRef<number>,
  params?: MaybeRef<GetSmartPayAsYouGoExecutionDateParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  accountId = unref(accountId)
  params = unref(params)

  return customInstance<SmartPayAsYouGoExecutionDateResponse>(
    {
      url: `/api/v1/accounts/${accountId}/smartPayAsYouGo/executionDate`,
      method: "GET",
      params: unref(params),
      signal,
    },
    options,
  )
}

export const getGetSmartPayAsYouGoExecutionDateQueryKey = (
  accountId: MaybeRef<number>,
  params?: MaybeRef<GetSmartPayAsYouGoExecutionDateParams>,
) => {
  return [
    "api",
    "v1",
    "accounts",
    accountId,
    "smartPayAsYouGo",
    "executionDate",
    ...(params ? [params] : []),
  ] as const
}

export const useGetSmartPayAsYouGoExecutionDateQueryOptions = <
  TData = Awaited<ReturnType<typeof getSmartPayAsYouGoExecutionDate>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  params?: MaybeRef<GetSmartPayAsYouGoExecutionDateParams>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getSmartPayAsYouGoExecutionDate>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetSmartPayAsYouGoExecutionDateQueryKey(accountId, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSmartPayAsYouGoExecutionDate>>
  > = ({ signal }) =>
    getSmartPayAsYouGoExecutionDate(accountId, params, requestOptions, signal)

  const customOptions = useQueryOptions(
    { ...queryOptions, queryKey, queryFn },
    { accountId, params },
  )

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getSmartPayAsYouGoExecutionDate>>,
    TError,
    TData
  >
}

export type GetSmartPayAsYouGoExecutionDateQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSmartPayAsYouGoExecutionDate>>
>
export type GetSmartPayAsYouGoExecutionDateQueryError = ErrorType<Error>

/**
 * @summary Calculate So Pay As You Go Execution date based in configured number of working days
 */
export const useGetSmartPayAsYouGoExecutionDate = <
  TData = Awaited<ReturnType<typeof getSmartPayAsYouGoExecutionDate>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  params?: MaybeRef<GetSmartPayAsYouGoExecutionDateParams>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getSmartPayAsYouGoExecutionDate>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetSmartPayAsYouGoExecutionDateQueryOptions(
    accountId,
    params,
    options,
  )

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

/**
 * @summary create a quote for Smart Pay As You Go
 */
export const createSmartPayAsYouGoQuote = (
  accountId: MaybeRef<number>,
  smartPayAsYouGoQuoteRequest: MaybeRef<SmartPayAsYouGoQuoteRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  accountId = unref(accountId)
  smartPayAsYouGoQuoteRequest = unref(smartPayAsYouGoQuoteRequest)

  return customInstance<SmartPayAsYouGoQuoteResponse>(
    {
      url: `/api/v1/accounts/${accountId}/smartPayAsYouGo/quote`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: smartPayAsYouGoQuoteRequest,
    },
    options,
  )
}

export const useCreateSmartPayAsYouGoQuoteMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createSmartPayAsYouGoQuote>>,
    TError,
    { accountId: number; data: SmartPayAsYouGoQuoteRequest },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof createSmartPayAsYouGoQuote>>,
  TError,
  { accountId: number; data: SmartPayAsYouGoQuoteRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createSmartPayAsYouGoQuote>>,
    { accountId: number; data: SmartPayAsYouGoQuoteRequest }
  > = (props) => {
    const { accountId, data } = props ?? {}

    return createSmartPayAsYouGoQuote(accountId, data, requestOptions)
  }

  const customOptions = useMutationOptions({ ...mutationOptions, mutationFn })

  return customOptions
}

export type CreateSmartPayAsYouGoQuoteMutationResult = NonNullable<
  Awaited<ReturnType<typeof createSmartPayAsYouGoQuote>>
>
export type CreateSmartPayAsYouGoQuoteMutationBody = SmartPayAsYouGoQuoteRequest
export type CreateSmartPayAsYouGoQuoteMutationError = ErrorType<Error>

/**
 * @summary create a quote for Smart Pay As You Go
 */
export const useCreateSmartPayAsYouGoQuote = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createSmartPayAsYouGoQuote>>,
    TError,
    { accountId: number; data: SmartPayAsYouGoQuoteRequest },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof createSmartPayAsYouGoQuote>>,
  TError,
  { accountId: number; data: SmartPayAsYouGoQuoteRequest },
  TContext
> => {
  const mutationOptions = useCreateSmartPayAsYouGoQuoteMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary register for Smart Pay As You Go
 */
export const postSmartPayAsYouGo = (
  accountId: MaybeRef<number>,
  smartPayAsYouGoRequest: MaybeRef<SmartPayAsYouGoRequest>,
  params: MaybeRef<PostSmartPayAsYouGoParams>,
  options?: SecondParameter<typeof customInstance>,
) => {
  accountId = unref(accountId)
  smartPayAsYouGoRequest = unref(smartPayAsYouGoRequest)
  params = unref(params)

  return customInstance<void>(
    {
      url: `/api/v1/accounts/${accountId}/smartPayAsYouGo`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: smartPayAsYouGoRequest,
      params: unref(params),
    },
    options,
  )
}

export const usePostSmartPayAsYouGoMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSmartPayAsYouGo>>,
    TError,
    {
      accountId: number
      data: SmartPayAsYouGoRequest
      params: PostSmartPayAsYouGoParams
    },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof postSmartPayAsYouGo>>,
  TError,
  {
    accountId: number
    data: SmartPayAsYouGoRequest
    params: PostSmartPayAsYouGoParams
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSmartPayAsYouGo>>,
    {
      accountId: number
      data: SmartPayAsYouGoRequest
      params: PostSmartPayAsYouGoParams
    }
  > = (props) => {
    const { accountId, data, params } = props ?? {}

    return postSmartPayAsYouGo(accountId, data, params, requestOptions)
  }

  const customOptions = useMutationOptions({ ...mutationOptions, mutationFn })

  return customOptions
}

export type PostSmartPayAsYouGoMutationResult = NonNullable<
  Awaited<ReturnType<typeof postSmartPayAsYouGo>>
>
export type PostSmartPayAsYouGoMutationBody = SmartPayAsYouGoRequest
export type PostSmartPayAsYouGoMutationError = ErrorType<Error>

/**
 * @summary register for Smart Pay As You Go
 */
export const usePostSmartPayAsYouGo = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSmartPayAsYouGo>>,
    TError,
    {
      accountId: number
      data: SmartPayAsYouGoRequest
      params: PostSmartPayAsYouGoParams
    },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof postSmartPayAsYouGo>>,
  TError,
  {
    accountId: number
    data: SmartPayAsYouGoRequest
    params: PostSmartPayAsYouGoParams
  },
  TContext
> => {
  const mutationOptions = usePostSmartPayAsYouGoMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Retrieve So Pay As You Go Status
 */
export const getSmartPayAsYouGoStatus = (
  accountId: MaybeRef<number>,
  params: MaybeRef<GetSmartPayAsYouGoStatusParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  accountId = unref(accountId)
  params = unref(params)

  return customInstance<SmartPayAsYouGoStatusResponse>(
    {
      url: `/api/v1/accounts/${accountId}/smartPayAsYouGo/status`,
      method: "GET",
      params: unref(params),
      signal,
    },
    options,
  )
}

export const getGetSmartPayAsYouGoStatusQueryKey = (
  accountId: MaybeRef<number>,
  params: MaybeRef<GetSmartPayAsYouGoStatusParams>,
) => {
  return [
    "api",
    "v1",
    "accounts",
    accountId,
    "smartPayAsYouGo",
    "status",
    ...(params ? [params] : []),
  ] as const
}

export const useGetSmartPayAsYouGoStatusQueryOptions = <
  TData = Awaited<ReturnType<typeof getSmartPayAsYouGoStatus>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  params: MaybeRef<GetSmartPayAsYouGoStatusParams>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getSmartPayAsYouGoStatus>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetSmartPayAsYouGoStatusQueryKey(accountId, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSmartPayAsYouGoStatus>>
  > = ({ signal }) =>
    getSmartPayAsYouGoStatus(accountId, params, requestOptions, signal)

  const customOptions = useQueryOptions(
    { ...queryOptions, queryKey, queryFn },
    { accountId, params },
  )

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getSmartPayAsYouGoStatus>>,
    TError,
    TData
  >
}

export type GetSmartPayAsYouGoStatusQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSmartPayAsYouGoStatus>>
>
export type GetSmartPayAsYouGoStatusQueryError = ErrorType<Error>

/**
 * @summary Retrieve So Pay As You Go Status
 */
export const useGetSmartPayAsYouGoStatus = <
  TData = Awaited<ReturnType<typeof getSmartPayAsYouGoStatus>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  params: MaybeRef<GetSmartPayAsYouGoStatusParams>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getSmartPayAsYouGoStatus>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetSmartPayAsYouGoStatusQueryOptions(
    accountId,
    params,
    options,
  )

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}
