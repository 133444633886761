<script setup lang="ts">
  import { useStepWizard } from "@/lib/useStepWizard"
  import ProgressCard from "@/components/cards/progress-card/ProgressCard.vue"
  import { Button } from "@/components/ui/button"

  defineProps<{
    title: string
  }>()

  const { progress, goNext, goBack, isFirstStep, steps, modelValue } =
    useStepWizard()
</script>

<template>
  <ProgressCard :title="title" :progress="progress">
    <slot />

    <template #supporting_title>
      <span class="h4 text-nowrap">
        Step {{ modelValue + 1 }} of {{ steps.length }}</span
      >
    </template>

    <template #footer>
      <slot name="footer">
        <Button
          v-if="!isFirstStep"
          variant="outline"
          size="md"
          @click.stop="goBack"
        >
          Back
        </Button>
        <slot name="next_button">
          <Button variant="tonal" size="md" @click="goNext">Next</Button>
        </slot>
      </slot>
    </template>
  </ProgressCard>
</template>
