<script setup lang="ts">
  import { DateFormats } from "@/lib/dateHelper"
  import { getInputAriaDescribedBy } from "@/lib/inputComponentUtils"
  import { cn } from "@/lib/utils"
  import { SupportingLabel } from "@/types/InputComponentTypes.ts"
  import { parseAbsoluteToLocal } from "@internationalized/date"
  import { useVModel } from "@vueuse/core"
  import dayjs from "dayjs"
  import customParseFormat from "dayjs/plugin/customParseFormat"
  import {
    DateFieldInput,
    DateFieldRoot,
    DateFieldRootEmits,
    DateFieldRootProps,
    useEmitAsProps,
    useForwardProps,
  } from "radix-vue"
  import { toDate } from "radix-vue/date"
  import { computed, HTMLAttributes, ref } from "vue"
  import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
  } from "@/components/ui/form"
  import FormSupportingLabel from "@/components/ui/form/FormSupportingLabel.vue"

  dayjs.extend(customParseFormat)

  const props = withDefaults(
    defineProps<
      Omit<DateFieldRootProps, "modelValue" | "name" | "locale"> & {
        class?: HTMLAttributes["class"]
        name: string
        dateFormat?: string
        modelValue?: string
        label: string
        extraLabel?: string
        supportingLabels?: SupportingLabel[]
        keepSupportingLabelsOnError?: boolean
      }
    >(),
    { dateFormat: DateFormats.default, granularity: "day" },
  )

  const emits = defineEmits<
    Omit<DateFieldRootEmits, "update:modelValue"> & {
      "update:modelValue": [date: string | undefined]
    }
  >()

  const dateFieldRef = ref()

  const modelValue = useVModel(props, "modelValue", emits, {
    passive: true,
  })

  const internalModelValue = computed({
    get() {
      if (!modelValue.value) return undefined

      return parseAbsoluteToLocal(
        dayjs(modelValue.value, props.dateFormat).toISOString(),
      )
    },
    set(val) {
      const hasValidYear = val?.year && val.year > 1000

      modelValue.value =
        val && hasValidYear
          ? dayjs(toDate(val)).format(props.dateFormat)
          : undefined
    },
  })

  const focusFirstSegment = (e: Event) => {
    const target = e.target as HTMLInputElement
    if (target?.getAttribute("data-date-field-segment")) return

    dateFieldRef.value.setFocusedElement(
      target.querySelector("#date_field_input_day"),
    )

    const daySegment = target.querySelector(
      "#date_field_input_day",
    ) as HTMLInputElement

    daySegment?.focus()
  }

  const getAriaDescribedBy = (
    formDescriptionId: string,
    formMessageId: string,
    error: string,
  ) =>
    getInputAriaDescribedBy(
      {
        supportingLabels: props.supportingLabels,
        extraLabel: props.extraLabel,
        error,
      },
      formDescriptionId,
      formMessageId,
    )

  const delegatedProps = computed(() => {
    const { class: _, modelValue: __, id: ___, ...delegated } = props
    return delegated
  })
  const delegatedEmits = computed(() => {
    const { "onUpdate:modelValue": _, ...delegated } = useEmitAsProps(emits)
    return delegated
  })

  const forwarded = computed(() => ({
    ...useForwardProps(delegatedProps).value,
    ...delegatedEmits.value,
  }))
</script>

<template>
  <FormField
    v-slot="{ field }"
    :model-value="modelValue"
    :name="name"
    :validate-on-blur="false"
    :validate-on-change="false"
    :validate-on-model-update="false"
  >
    <FormItem v-bind="$attrs">
      <FormControl
        v-slot="{
          error,
          formItemId,
          ariaInvalid,
          formDescriptionId,
          formMessageId,
        }"
      >
        <div class="flex items-baseline justify-between">
          <FormLabel v-if="label" data-testid="form-label">{{
            label
          }}</FormLabel>
          <span
            v-if="extraLabel"
            :id="formDescriptionId"
            class="label-text-strong text-secondary-500 dark:text-secondary-300"
            data-testid="form-extra-label"
            :aria-label="`${extraLabel}.`"
          >
            {{ extraLabel }}
          </span>
        </div>

        <input
          :id="formItemId"
          v-bind="{ ...field }"
          :value="modelValue"
          :aria-describedby="
            getAriaDescribedBy(formDescriptionId, formMessageId, error)
          "
          :aria-invalid="ariaInvalid"
          :disabled="disabled"
          :aria-required="required"
          class="pointer-events-none absolute m-0 translate-x-[-100%] opacity-0"
        />

        <DateFieldRoot
          v-slot="{ segments }"
          ref="dateFieldRef"
          v-model="internalModelValue"
          v-bind="forwarded"
          locale="en-GB"
          name="radixDateField"
          data-testid="date-input-root"
          :class="
            cn(
              'flex h-12 w-full items-center rounded-sm border px-4 py-3 text-xs sm:h-13 sm:text-base',
              'bg-secondary-100 text-secondary-700 ring-primary-400 placeholder:text-secondary-400',
              !disabled && 'hover:border-secondary-400',
              'focus-visible:border-primary-400 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-offset-0',
              disabled &&
                'cursor-not-allowed border-secondary-500 bg-secondary-300 text-secondary-400',
              modelValue && 'border-secondary-400',
              error && [
                'border-red-400 ring-red-400 hover:border-red-400 focus-visible:border-red-400',
              ],
            )
          "
          @click="focusFirstSegment"
        >
          <template v-for="item in segments" :key="item.part">
            <DateFieldInput v-if="item.part === 'literal'" :part="item.part">
              {{ item.value }}
            </DateFieldInput>
            <DateFieldInput
              v-else
              :id="`date_field_input_${item.part}`"
              :data-date-field-segment="item.part"
              :part="item.part"
              :class="
                cn(
                  ['dd', 'mm', 'yyyy'].includes(item.value)
                    ? 'text-secondary-400'
                    : '',
                  'rounded-xs border border-transparent p-0.5 ring-primary-400',
                  'focus-visible:border-primary-400 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-offset-0',
                )
              "
            >
              {{ item.value }}
            </DateFieldInput>
          </template>
        </DateFieldRoot>
        <template
          v-if="
            supportingLabels?.length && (keepSupportingLabelsOnError || !error)
          "
        >
          <FormSupportingLabel
            v-for="(supportingLabel, index) in supportingLabels"
            :id="`${formMessageId}-${index}`"
            :key="`${formMessageId}-${index}`"
            :supporting-label="supportingLabel"
            :data-testid="`form-supporting-label-${index}`"
          >
          </FormSupportingLabel>
        </template>
        <FormMessage />
      </FormControl>
    </FormItem>
  </FormField>
</template>
