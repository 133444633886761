import type { SmartPayAsYouGoRequest } from "@/api/model/omponents"
import { ref } from "vue"

const spaygRequest = ref<SmartPayAsYouGoRequest>([])

const selectedMeterpointIdentifiers = ref<string[]>([])
const selectedMeterpointLabels = ref<string[]>([])
const switchDate = ref<string>()
const spaygTariffName = ref<string>("")

export const useSmartPayAsYouGo = () => {
  return {
    selectedMeterpointIdentifiers,
    selectedMeterpointLabels,
    switchDate,
    spaygTariffName,
    spaygRequest,
  }
}
