<script setup lang="ts">
  import { useGetAccountStates } from "@/api/account-state/account-state.ts"
  import {
    useGetPaymentScheduleDetails,
    useGetVariablePaymentDetails,
  } from "@/api/account/account.ts"
  import { DirectDebitState } from "@/api/model"
  import { ExternalRoute } from "@/constants/externalRoute.ts"
  import { aggregateQueries } from "@/lib/aggregateQueries.ts"
  import { isSummerMonth } from "@/lib/seasonalHelper.ts"
  import { useGetAccountId } from "@/lib/useGetAccountId.ts"
  import { formatCurrency } from "@/lib/utils.ts"
  import dayjs from "dayjs"
  import { computed } from "vue"
  import ErrorMessage from "@/components/ErrorMessage.vue"
  import { Button, ButtonVariants } from "@/components/ui/button"
  import {
    Card,
    CardContent,
    CardFooter,
    CardHeader,
    CardTitle,
  } from "@/components/ui/card"
  import { Link } from "@/components/ui/link"

  import DirectDebitCardSkeleton from "./DirectDebitCardSkeleton.vue"

  const accountIdQuery = useGetAccountId()
  const accountId = accountIdQuery.accountId

  const accountStatesQuery = useGetAccountStates(accountId)
  const directDebitState = computed(
    () => accountStatesQuery.data.value?.directDebit,
  )

  const paymentScheduleDetailsQuery = useGetPaymentScheduleDetails(accountId, {
    query: {
      enabled: computed(
        () => !!accountId.value && isDDEligible(directDebitState.value),
      ),
    },
  })
  const paymentSchedule = paymentScheduleDetailsQuery.data

  const ddVariableNextPaymentDateQuery = useGetVariablePaymentDetails(
    accountId,
    {
      query: {
        enabled: computed(
          () =>
            !!accountId.value &&
            directDebitState.value === DirectDebitState.activeVariable,
        ),
      },
    },
  )
  const ddVariableNextPaymentDate = ddVariableNextPaymentDateQuery.data

  const { isError, isFetching, isLoading } = aggregateQueries([
    accountIdQuery,
    accountStatesQuery,
    paymentScheduleDetailsQuery,
    ddVariableNextPaymentDateQuery,
  ])

  function isDDEligible(ddState?: DirectDebitState) {
    return (
      ddState === DirectDebitState.activeFixed ||
      ddState === DirectDebitState.pendingActivation ||
      ddState === DirectDebitState.failedPayment
    )
  }

  const paymentAmountText = computed(() => {
    if (directDebitState.value === DirectDebitState.activeVariable) {
      return "On receipt of bill"
    }

    if (!isDDEligible(directDebitState.value)) {
      return "Not active"
    }

    const schedule = paymentSchedule.value?.currentSchedule || {}
    const {
      nextPaymentAmountInPounds,
      nextPaymentDate,
      isSeasonal,
      paymentAmount,
    } = schedule

    if (!nextPaymentAmountInPounds) return "-"

    if (
      isSeasonal &&
      paymentAmount?.summerPaymentAmountInPounds &&
      paymentAmount?.winterPaymentAmountInPounds
    ) {
      const seasonalAmount = isSummerMonth(dayjs(nextPaymentDate))
        ? paymentAmount.summerPaymentAmountInPounds
        : paymentAmount.winterPaymentAmountInPounds
      return formatCurrency(seasonalAmount)
    }

    return formatCurrency(nextPaymentAmountInPounds)
  })

  const directDebitTitleCopy = computed(() => {
    return paymentSchedule.value?.currentSchedule?.isSeasonal
      ? "Your next Direct Debit"
      : "Your Direct Debit"
  })

  const getDiffText = (diffInDays: number) => {
    if (diffInDays === 0) return "(today)"
    if (diffInDays === 1) return "(in 1 day)"
    return `(in ${diffInDays} days)`
  }

  const formatNextPaymentDate = (nextPaymentDate: string) => {
    const targetDate = dayjs(nextPaymentDate)

    return {
      date: targetDate.format("D MMM"),
      diffInDays: getDiffText(
        targetDate.diff(dayjs().format("DD MMM YYYY"), "day"),
      ),
    }
  }

  const nextPaymentDate = computed(() => {
    if (directDebitState.value === DirectDebitState.pendingActivation) {
      return {
        date: "Pending activation",
        diffInDays: "",
      }
    }

    const isDirectDebitActive =
      directDebitState.value === DirectDebitState.activeFixed ||
      directDebitState.value === DirectDebitState.activeVariable ||
      directDebitState.value === DirectDebitState.failedPayment

    if (!isDirectDebitActive) {
      return null
    }

    const nextPaymentDate =
      directDebitState.value === DirectDebitState.activeVariable
        ? ddVariableNextPaymentDate.value?.nextPaymentDate
        : paymentSchedule.value?.currentSchedule?.nextPaymentDate

    if (!nextPaymentDate) return null

    return formatNextPaymentDate(nextPaymentDate)
  })

  const actionButton = computed<{
    text: string
    variant: ButtonVariants["variant"]
  }>(() => {
    switch (directDebitState.value) {
      case DirectDebitState.activeFixed:
      case DirectDebitState.activeVariable:
      case DirectDebitState.pendingActivation:
      case DirectDebitState.failedPayment:
        return {
          text: "Adjust your Direct Debit",
          variant: "tonal",
        }
      default:
        return {
          text: "Set your Direct Debit",
          variant: "primary",
        }
    }
  })
</script>

<template>
  <DirectDebitCardSkeleton v-if="isLoading" />
  <Card v-else data-testid="direct-debit-card">
    <CardHeader>
      <CardTitle>{{ directDebitTitleCopy }}</CardTitle>
    </CardHeader>
    <CardContent>
      <ErrorMessage v-if="isError && !isFetching" />
      <dl v-if="!isError" class="grid grid-cols-[1fr_max-content] gap-2">
        <dt>{{ directDebitTitleCopy }}:</dt>
        <dd class="text-right font-medium" data-testid="next-payment-amount">
          {{ paymentAmountText }}
        </dd>
        <dt>Next payment due:</dt>
        <dd class="text-right font-medium" data-testid="next-payment-date">
          <span v-if="nextPaymentDate">
            {{ nextPaymentDate.date }}
            <span v-if="nextPaymentDate.diffInDays" class="font-normal">
              {{ nextPaymentDate.diffInDays }}
            </span>
          </span>
          <span v-else>Not active</span>
        </dd>
      </dl>
    </CardContent>
    <CardFooter>
      <Button
        :variant="actionButton.variant"
        :disabled="isFetching"
        :as-child="!isFetching"
        data-testid="go-to-payments-button"
      >
        <span v-if="isFetching">{{ actionButton.text }}</span>
        <Link v-else :to="ExternalRoute.payments">
          {{ actionButton.text }}
        </Link>
      </Button>
    </CardFooter>
  </Card>
</template>
