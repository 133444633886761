<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import { HTMLAttributes } from "vue"
  import { IconButton } from "@/components/ui/icon-button"

  const props = defineProps<{
    class?: HTMLAttributes["class"]
  }>()

  const emits = defineEmits(["close"])

  const closeAlert = () => {
    emits("close")
  }
</script>

<template>
  <IconButton
    variant="text"
    icon="x"
    label="Close alert"
    size="responsive"
    :class="
      cn(
        'p-0 text-current hover:bg-transparent active:bg-transparent dark:hover:bg-transparent dark:active:bg-transparent',
        props.class,
      )
    "
    data-testid="alert-close"
    @click="closeAlert"
  >
  </IconButton>
</template>
