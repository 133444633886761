<script setup lang="ts">
  import { useBreakpoint } from "@/lib/useBreakpoint.ts"
  import { cn } from "@/lib/utils"
  import { Primitive } from "radix-vue"
  import { computed, HTMLAttributes } from "vue"
  import Icon from "@/components/ui/icons/Icon.vue"
  import { IconType } from "@/components/ui/icons/iconsMap.ts"

  import { iconButtonVariants, type IconButtonVariants } from "."

  interface Props {
    icon: IconType
    disabled?: boolean
    size?: IconButtonVariants["size"]
    class?: HTMLAttributes["class"]
    inverted?: boolean
    label: string
  }

  const props = withDefaults(defineProps<Props>(), {
    disabled: false,
    size: "responsive",
  })

  const iconSize = computed(() => {
    switch (props.size) {
      case "sm":
        return "md"
      case "md":
        return "lg"
      case "responsive":
        return useBreakpoint("sm").value ? "lg" : "md"
      default:
        return "lg"
    }
  })

  defineExpose({
    iconSize,
  })
</script>

<template>
  <Primitive
    as="button"
    :class="cn(iconButtonVariants({ size }), props.class)"
    :disabled="disabled"
    :aria-label="label"
    data-testid="icon-button"
  >
    <Icon
      :name="icon"
      :size="iconSize"
      aria-hidden="true"
      data-testid="icon"
      :variant="inverted ? 'inverted' : 'primary'"
    />
    <slot></slot>
  </Primitive>
</template>
