import { GetAccountResponse } from "@/api/model"
import { computed, ComputedRef, Ref } from "vue"

export default (
  accountData: Ref<GetAccountResponse | undefined>,
): ComputedRef<string> =>
  computed(() => {
    if (!accountData.value?.supplyAddress) return "-"
    return [
      accountData.value?.supplyAddress?.addressLine1,
      accountData.value?.supplyAddress?.addressLine2,
      accountData.value?.supplyAddress?.addressLine3,
      accountData.value?.supplyAddress?.postcode,
    ]
      .filter(Boolean)
      .join(", ")
  })
