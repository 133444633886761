/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Portal API Gateway
 * OpenAPI spec version: 2.0.0
 */
import {
  useMutation,
  useQuery,
  type MutationFunction,
  type QueryFunction,
  type QueryKey,
  type UseMutationOptions,
  type UseMutationReturnType,
  type UseQueryOptions,
  type UseQueryReturnType,
} from "@tanstack/vue-query"
import { unref, type MaybeRef } from "vue"

import { customInstance, type ErrorType } from ".././client"
import type {
  AgreementsResponse,
  Bill,
  BillFileLinkResponse,
  CancelSwitchRequest,
  EnergyUsageResponse,
  Error,
  GetAccountResponse,
  GetEnergyUsageParams,
  GetMeterAppointmentResponse,
  GetMeterReadingsParams,
  GetMeterReadingsRequest,
  GetMeterReadingsResponse,
  GetUnbilledParams,
  GetVariablePaymentDetailsResponse,
  MeterReadingReminderResponse,
  MeterReadingWithTechnicalDetails,
  MeterResponse,
  PaymentScheduleDetails,
  TransactionResponse,
  Unbilled,
  UpdateMeterReadingReminderRequest,
} from ".././model"
import { useMutationOptions } from ".././mutationOptions"
import { useQueryOptions } from ".././queryOptions"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary Get transactions for account
 */
export const getTransactions = (
  accountId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  accountId = unref(accountId)

  return customInstance<TransactionResponse[]>(
    {
      url: `/api/v1/accounts/${accountId}/transactions`,
      method: "GET",
      signal,
    },
    options,
  )
}

export const getGetTransactionsQueryKey = (accountId: MaybeRef<number>) => {
  return ["api", "v1", "accounts", accountId, "transactions"] as const
}

export const useGetTransactionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getTransactions>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getTransactions>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetTransactionsQueryKey(accountId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransactions>>> = ({
    signal,
  }) => getTransactions(accountId, requestOptions, signal)

  const customOptions = useQueryOptions(
    { ...queryOptions, queryKey, queryFn },
    { accountId },
  )

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getTransactions>>,
    TError,
    TData
  >
}

export type GetTransactionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTransactions>>
>
export type GetTransactionsQueryError = ErrorType<Error>

/**
 * @summary Get transactions for account
 */
export const useGetTransactions = <
  TData = Awaited<ReturnType<typeof getTransactions>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getTransactions>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetTransactionsQueryOptions(accountId, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

/**
 * @summary Get agreements for account
 */
export const getAgreements = (
  accountId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  accountId = unref(accountId)

  return customInstance<AgreementsResponse[]>(
    { url: `/api/v1/accounts/${accountId}/agreements`, method: "GET", signal },
    options,
  )
}

export const getGetAgreementsQueryKey = (accountId: MaybeRef<number>) => {
  return ["api", "v1", "accounts", accountId, "agreements"] as const
}

export const useGetAgreementsQueryOptions = <
  TData = Awaited<ReturnType<typeof getAgreements>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAgreements>>, TError, TData>
    >
    request?: SecondParameter<typeof customInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetAgreementsQueryKey(accountId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAgreements>>> = ({
    signal,
  }) => getAgreements(accountId, requestOptions, signal)

  const customOptions = useQueryOptions(
    { ...queryOptions, queryKey, queryFn },
    { accountId },
  )

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getAgreements>>,
    TError,
    TData
  >
}

export type GetAgreementsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAgreements>>
>
export type GetAgreementsQueryError = ErrorType<Error>

/**
 * @summary Get agreements for account
 */
export const useGetAgreements = <
  TData = Awaited<ReturnType<typeof getAgreements>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAgreements>>, TError, TData>
    >
    request?: SecondParameter<typeof customInstance>
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetAgreementsQueryOptions(accountId, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

/**
 * @summary Get account information
 */
export const getAccount = (
  accountId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  accountId = unref(accountId)

  return customInstance<GetAccountResponse>(
    { url: `/api/v1/accounts/${accountId}`, method: "GET", signal },
    options,
  )
}

export const getGetAccountQueryKey = (accountId: MaybeRef<number>) => {
  return ["api", "v1", "accounts", accountId] as const
}

export const useGetAccountQueryOptions = <
  TData = Awaited<ReturnType<typeof getAccount>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAccount>>, TError, TData>
    >
    request?: SecondParameter<typeof customInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetAccountQueryKey(accountId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccount>>> = ({
    signal,
  }) => getAccount(accountId, requestOptions, signal)

  const customOptions = useQueryOptions(
    { ...queryOptions, queryKey, queryFn },
    { accountId },
  )

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getAccount>>,
    TError,
    TData
  >
}

export type GetAccountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAccount>>
>
export type GetAccountQueryError = ErrorType<Error>

/**
 * @summary Get account information
 */
export const useGetAccount = <
  TData = Awaited<ReturnType<typeof getAccount>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAccount>>, TError, TData>
    >
    request?: SecondParameter<typeof customInstance>
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetAccountQueryOptions(accountId, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

/**
 * @summary Get an account's bill by id and account id
 */
export const getBillByIdAndAccountId = (
  accountId: MaybeRef<number>,
  billId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  accountId = unref(accountId)
  billId = unref(billId)

  return customInstance<BillFileLinkResponse>(
    {
      url: `/api/v1/accounts/${accountId}/bills/${billId}`,
      method: "GET",
      signal,
    },
    options,
  )
}

export const getGetBillByIdAndAccountIdQueryKey = (
  accountId: MaybeRef<number>,
  billId: MaybeRef<number>,
) => {
  return ["api", "v1", "accounts", accountId, "bills", billId] as const
}

export const useGetBillByIdAndAccountIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getBillByIdAndAccountId>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  billId: MaybeRef<number>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getBillByIdAndAccountId>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetBillByIdAndAccountIdQueryKey(accountId, billId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getBillByIdAndAccountId>>
  > = ({ signal }) =>
    getBillByIdAndAccountId(accountId, billId, requestOptions, signal)

  const customOptions = useQueryOptions(
    { ...queryOptions, queryKey, queryFn },
    { accountId, billId },
  )

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getBillByIdAndAccountId>>,
    TError,
    TData
  >
}

export type GetBillByIdAndAccountIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBillByIdAndAccountId>>
>
export type GetBillByIdAndAccountIdQueryError = ErrorType<Error>

/**
 * @summary Get an account's bill by id and account id
 */
export const useGetBillByIdAndAccountId = <
  TData = Awaited<ReturnType<typeof getBillByIdAndAccountId>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  billId: MaybeRef<number>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getBillByIdAndAccountId>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetBillByIdAndAccountIdQueryOptions(
    accountId,
    billId,
    options,
  )

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

/**
 * @summary Get bills for an account
 */
export const getBillsByAccountId = (
  accountId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  accountId = unref(accountId)

  return customInstance<Bill[]>(
    { url: `/api/v1/accounts/${accountId}/bills`, method: "GET", signal },
    options,
  )
}

export const getGetBillsByAccountIdQueryKey = (accountId: MaybeRef<number>) => {
  return ["api", "v1", "accounts", accountId, "bills"] as const
}

export const useGetBillsByAccountIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getBillsByAccountId>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getBillsByAccountId>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetBillsByAccountIdQueryKey(accountId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getBillsByAccountId>>
  > = ({ signal }) => getBillsByAccountId(accountId, requestOptions, signal)

  const customOptions = useQueryOptions(
    { ...queryOptions, queryKey, queryFn },
    { accountId },
  )

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getBillsByAccountId>>,
    TError,
    TData
  >
}

export type GetBillsByAccountIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBillsByAccountId>>
>
export type GetBillsByAccountIdQueryError = ErrorType<Error>

/**
 * @summary Get bills for an account
 */
export const useGetBillsByAccountId = <
  TData = Awaited<ReturnType<typeof getBillsByAccountId>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getBillsByAccountId>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetBillsByAccountIdQueryOptions(accountId, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

/**
 * @summary Get meter reading reminder frequency for an account
 */
export const getMeterReadingReminder = (
  accountId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  accountId = unref(accountId)

  return customInstance<MeterReadingReminderResponse>(
    {
      url: `/api/v1/accounts/${accountId}/meterReadingReminder`,
      method: "GET",
      signal,
    },
    options,
  )
}

export const getGetMeterReadingReminderQueryKey = (
  accountId: MaybeRef<number>,
) => {
  return ["api", "v1", "accounts", accountId, "meterReadingReminder"] as const
}

export const useGetMeterReadingReminderQueryOptions = <
  TData = Awaited<ReturnType<typeof getMeterReadingReminder>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getMeterReadingReminder>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetMeterReadingReminderQueryKey(accountId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getMeterReadingReminder>>
  > = ({ signal }) => getMeterReadingReminder(accountId, requestOptions, signal)

  const customOptions = useQueryOptions(
    { ...queryOptions, queryKey, queryFn },
    { accountId },
  )

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getMeterReadingReminder>>,
    TError,
    TData
  >
}

export type GetMeterReadingReminderQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMeterReadingReminder>>
>
export type GetMeterReadingReminderQueryError = ErrorType<Error>

/**
 * @summary Get meter reading reminder frequency for an account
 */
export const useGetMeterReadingReminder = <
  TData = Awaited<ReturnType<typeof getMeterReadingReminder>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getMeterReadingReminder>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetMeterReadingReminderQueryOptions(
    accountId,
    options,
  )

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

/**
 * @summary Update meter reading reminder for an account
 */
export const updateMeterReadingReminder = (
  accountId: MaybeRef<number>,
  updateMeterReadingReminderRequest: MaybeRef<UpdateMeterReadingReminderRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  accountId = unref(accountId)
  updateMeterReadingReminderRequest = unref(updateMeterReadingReminderRequest)

  return customInstance<MeterReadingReminderResponse>(
    {
      url: `/api/v1/accounts/${accountId}/meterReadingReminder`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: updateMeterReadingReminderRequest,
    },
    options,
  )
}

export const useUpdateMeterReadingReminderMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateMeterReadingReminder>>,
    TError,
    { accountId: number; data: UpdateMeterReadingReminderRequest },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateMeterReadingReminder>>,
  TError,
  { accountId: number; data: UpdateMeterReadingReminderRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateMeterReadingReminder>>,
    { accountId: number; data: UpdateMeterReadingReminderRequest }
  > = (props) => {
    const { accountId, data } = props ?? {}

    return updateMeterReadingReminder(accountId, data, requestOptions)
  }

  const customOptions = useMutationOptions({ ...mutationOptions, mutationFn })

  return customOptions
}

export type UpdateMeterReadingReminderMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateMeterReadingReminder>>
>
export type UpdateMeterReadingReminderMutationBody =
  UpdateMeterReadingReminderRequest
export type UpdateMeterReadingReminderMutationError = ErrorType<Error>

/**
 * @summary Update meter reading reminder for an account
 */
export const useUpdateMeterReadingReminder = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateMeterReadingReminder>>,
    TError,
    { accountId: number; data: UpdateMeterReadingReminderRequest },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof updateMeterReadingReminder>>,
  TError,
  { accountId: number; data: UpdateMeterReadingReminderRequest },
  TContext
> => {
  const mutationOptions = useUpdateMeterReadingReminderMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Get a list of filtered meter readings for the provided account. Readings are returned in a paginated manner based on `offset` and `limit`
 */
export const getMeterReadings = (
  accountId: MaybeRef<number>,
  getMeterReadingsRequest: MaybeRef<GetMeterReadingsRequest>,
  params: MaybeRef<GetMeterReadingsParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  accountId = unref(accountId)
  getMeterReadingsRequest = unref(getMeterReadingsRequest)
  params = unref(params)

  return customInstance<GetMeterReadingsResponse>(
    {
      url: `/api/v1/accounts/${accountId}/readings`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
      params: unref(params),
      signal,
    },
    options,
  )
}

export const getGetMeterReadingsQueryKey = (
  accountId: MaybeRef<number>,
  getMeterReadingsRequest: MaybeRef<GetMeterReadingsRequest>,
  params: MaybeRef<GetMeterReadingsParams>,
) => {
  return [
    "api",
    "v1",
    "accounts",
    accountId,
    "readings",
    ...(params ? [params] : []),
    getMeterReadingsRequest,
  ] as const
}

export const useGetMeterReadingsQueryOptions = <
  TData = Awaited<ReturnType<typeof getMeterReadings>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  getMeterReadingsRequest: MaybeRef<GetMeterReadingsRequest>,
  params: MaybeRef<GetMeterReadingsParams>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getMeterReadings>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetMeterReadingsQueryKey(
    accountId,
    getMeterReadingsRequest,
    params,
  )

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getMeterReadings>>
  > = ({ signal }) =>
    getMeterReadings(
      accountId,
      getMeterReadingsRequest,
      params,
      requestOptions,
      signal,
    )

  const customOptions = useQueryOptions(
    { ...queryOptions, queryKey, queryFn },
    { accountId, getMeterReadingsRequest, params },
  )

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getMeterReadings>>,
    TError,
    TData
  >
}

export type GetMeterReadingsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMeterReadings>>
>
export type GetMeterReadingsQueryError = ErrorType<Error>

/**
 * @summary Get a list of filtered meter readings for the provided account. Readings are returned in a paginated manner based on `offset` and `limit`
 */
export const useGetMeterReadings = <
  TData = Awaited<ReturnType<typeof getMeterReadings>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  getMeterReadingsRequest: MaybeRef<GetMeterReadingsRequest>,
  params: MaybeRef<GetMeterReadingsParams>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getMeterReadings>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetMeterReadingsQueryOptions(
    accountId,
    getMeterReadingsRequest,
    params,
    options,
  )

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

/**
 * @summary Get a list of meter appointments created for the account
 */
export const getMeterAppointments = (
  accountId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  accountId = unref(accountId)

  return customInstance<GetMeterAppointmentResponse[]>(
    {
      url: `/api/v1/accounts/${accountId}/appointments`,
      method: "GET",
      signal,
    },
    options,
  )
}

export const getGetMeterAppointmentsQueryKey = (
  accountId: MaybeRef<number>,
) => {
  return ["api", "v1", "accounts", accountId, "appointments"] as const
}

export const useGetMeterAppointmentsQueryOptions = <
  TData = Awaited<ReturnType<typeof getMeterAppointments>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getMeterAppointments>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetMeterAppointmentsQueryKey(accountId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getMeterAppointments>>
  > = ({ signal }) => getMeterAppointments(accountId, requestOptions, signal)

  const customOptions = useQueryOptions(
    { ...queryOptions, queryKey, queryFn },
    { accountId },
  )

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getMeterAppointments>>,
    TError,
    TData
  >
}

export type GetMeterAppointmentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMeterAppointments>>
>
export type GetMeterAppointmentsQueryError = ErrorType<Error>

/**
 * @summary Get a list of meter appointments created for the account
 */
export const useGetMeterAppointments = <
  TData = Awaited<ReturnType<typeof getMeterAppointments>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getMeterAppointments>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetMeterAppointmentsQueryOptions(accountId, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

/**
 * @summary Cancels the meter switches of the account
 */
export const cancelSwitch = (
  accountId: MaybeRef<number>,
  cancelSwitchRequest: MaybeRef<CancelSwitchRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  accountId = unref(accountId)
  cancelSwitchRequest = unref(cancelSwitchRequest)

  return customInstance<void>(
    {
      url: `/api/v1/accounts/${accountId}/cancelSwitch`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: cancelSwitchRequest,
    },
    options,
  )
}

export const useCancelSwitchMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelSwitch>>,
    TError,
    { accountId: number; data: CancelSwitchRequest },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof cancelSwitch>>,
  TError,
  { accountId: number; data: CancelSwitchRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cancelSwitch>>,
    { accountId: number; data: CancelSwitchRequest }
  > = (props) => {
    const { accountId, data } = props ?? {}

    return cancelSwitch(accountId, data, requestOptions)
  }

  const customOptions = useMutationOptions({ ...mutationOptions, mutationFn })

  return customOptions
}

export type CancelSwitchMutationResult = NonNullable<
  Awaited<ReturnType<typeof cancelSwitch>>
>
export type CancelSwitchMutationBody = CancelSwitchRequest
export type CancelSwitchMutationError = ErrorType<Error>

/**
 * @summary Cancels the meter switches of the account
 */
export const useCancelSwitch = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelSwitch>>,
    TError,
    { accountId: number; data: CancelSwitchRequest },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof cancelSwitch>>,
  TError,
  { accountId: number; data: CancelSwitchRequest },
  TContext
> => {
  const mutationOptions = useCancelSwitchMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Download the PDF file for direct debit mandate of the account
 */
export const downloadDirectDebitMandatePdf = (
  accountId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  accountId = unref(accountId)

  return customInstance<Blob>(
    {
      url: `/api/v1/accounts/${accountId}/directDebitMandate`,
      method: "GET",
      responseType: "blob",
      signal,
    },
    options,
  )
}

export const getDownloadDirectDebitMandatePdfQueryKey = (
  accountId: MaybeRef<number>,
) => {
  return ["api", "v1", "accounts", accountId, "directDebitMandate"] as const
}

export const useDownloadDirectDebitMandatePdfQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadDirectDebitMandatePdf>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof downloadDirectDebitMandatePdf>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getDownloadDirectDebitMandatePdfQueryKey(accountId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof downloadDirectDebitMandatePdf>>
  > = ({ signal }) =>
    downloadDirectDebitMandatePdf(accountId, requestOptions, signal)

  const customOptions = useQueryOptions(
    { ...queryOptions, queryKey, queryFn },
    { accountId },
  )

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof downloadDirectDebitMandatePdf>>,
    TError,
    TData
  >
}

export type DownloadDirectDebitMandatePdfQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadDirectDebitMandatePdf>>
>
export type DownloadDirectDebitMandatePdfQueryError = ErrorType<Error>

/**
 * @summary Download the PDF file for direct debit mandate of the account
 */
export const useDownloadDirectDebitMandatePdf = <
  TData = Awaited<ReturnType<typeof downloadDirectDebitMandatePdf>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof downloadDirectDebitMandatePdf>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useDownloadDirectDebitMandatePdfQueryOptions(
    accountId,
    options,
  )

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

/**
 * @summary Get current and future paymentSchedules for an account.
 */
export const getPaymentScheduleDetails = (
  accountId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  accountId = unref(accountId)

  return customInstance<PaymentScheduleDetails>(
    {
      url: `/api/v1/accounts/${accountId}/paymentScheduleDetails`,
      method: "GET",
      signal,
    },
    options,
  )
}

export const getGetPaymentScheduleDetailsQueryKey = (
  accountId: MaybeRef<number>,
) => {
  return ["api", "v1", "accounts", accountId, "paymentScheduleDetails"] as const
}

export const useGetPaymentScheduleDetailsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPaymentScheduleDetails>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPaymentScheduleDetails>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetPaymentScheduleDetailsQueryKey(accountId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPaymentScheduleDetails>>
  > = ({ signal }) =>
    getPaymentScheduleDetails(accountId, requestOptions, signal)

  const customOptions = useQueryOptions(
    { ...queryOptions, queryKey, queryFn },
    { accountId },
  )

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getPaymentScheduleDetails>>,
    TError,
    TData
  >
}

export type GetPaymentScheduleDetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPaymentScheduleDetails>>
>
export type GetPaymentScheduleDetailsQueryError = ErrorType<Error>

/**
 * @summary Get current and future paymentSchedules for an account.
 */
export const useGetPaymentScheduleDetails = <
  TData = Awaited<ReturnType<typeof getPaymentScheduleDetails>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPaymentScheduleDetails>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetPaymentScheduleDetailsQueryOptions(
    accountId,
    options,
  )

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

/**
 * @summary Retrieve payment information related to variable customers
 */
export const getVariablePaymentDetails = (
  accountId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  accountId = unref(accountId)

  return customInstance<GetVariablePaymentDetailsResponse>(
    {
      url: `/api/v1/accounts/${accountId}/variable-payment-details`,
      method: "GET",
      signal,
    },
    options,
  )
}

export const getGetVariablePaymentDetailsQueryKey = (
  accountId: MaybeRef<number>,
) => {
  return [
    "api",
    "v1",
    "accounts",
    accountId,
    "variable-payment-details",
  ] as const
}

export const useGetVariablePaymentDetailsQueryOptions = <
  TData = Awaited<ReturnType<typeof getVariablePaymentDetails>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getVariablePaymentDetails>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetVariablePaymentDetailsQueryKey(accountId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getVariablePaymentDetails>>
  > = ({ signal }) =>
    getVariablePaymentDetails(accountId, requestOptions, signal)

  const customOptions = useQueryOptions(
    { ...queryOptions, queryKey, queryFn },
    { accountId },
  )

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getVariablePaymentDetails>>,
    TError,
    TData
  >
}

export type GetVariablePaymentDetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getVariablePaymentDetails>>
>
export type GetVariablePaymentDetailsQueryError = ErrorType<Error>

/**
 * @summary Retrieve payment information related to variable customers
 */
export const useGetVariablePaymentDetails = <
  TData = Awaited<ReturnType<typeof getVariablePaymentDetails>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getVariablePaymentDetails>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetVariablePaymentDetailsQueryOptions(
    accountId,
    options,
  )

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

/**
 * @summary Retrieve list of meters for given account
 */
export const getMetersByBillingAccountId = (
  accountId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  accountId = unref(accountId)

  return customInstance<MeterResponse[]>(
    { url: `/api/v1/accounts/${accountId}/meters`, method: "GET", signal },
    options,
  )
}

export const getGetMetersByBillingAccountIdQueryKey = (
  accountId: MaybeRef<number>,
) => {
  return ["api", "v1", "accounts", accountId, "meters"] as const
}

export const useGetMetersByBillingAccountIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getMetersByBillingAccountId>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getMetersByBillingAccountId>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetMetersByBillingAccountIdQueryKey(accountId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getMetersByBillingAccountId>>
  > = ({ signal }) =>
    getMetersByBillingAccountId(accountId, requestOptions, signal)

  const customOptions = useQueryOptions(
    { ...queryOptions, queryKey, queryFn },
    { accountId },
  )

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getMetersByBillingAccountId>>,
    TError,
    TData
  >
}

export type GetMetersByBillingAccountIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMetersByBillingAccountId>>
>
export type GetMetersByBillingAccountIdQueryError = ErrorType<Error>

/**
 * @summary Retrieve list of meters for given account
 */
export const useGetMetersByBillingAccountId = <
  TData = Awaited<ReturnType<typeof getMetersByBillingAccountId>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getMetersByBillingAccountId>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetMetersByBillingAccountIdQueryOptions(
    accountId,
    options,
  )

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

/**
 * @summary Retrieve details about current charges present in the next bill, excluding standing charges unless requested.
 */
export const getUnbilled = (
  accountId: MaybeRef<number>,
  params?: MaybeRef<GetUnbilledParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  accountId = unref(accountId)
  params = unref(params)

  return customInstance<Unbilled>(
    {
      url: `/api/v1/accounts/${accountId}/unbilled`,
      method: "GET",
      params: unref(params),
      signal,
    },
    options,
  )
}

export const getGetUnbilledQueryKey = (
  accountId: MaybeRef<number>,
  params?: MaybeRef<GetUnbilledParams>,
) => {
  return [
    "api",
    "v1",
    "accounts",
    accountId,
    "unbilled",
    ...(params ? [params] : []),
  ] as const
}

export const useGetUnbilledQueryOptions = <
  TData = Awaited<ReturnType<typeof getUnbilled>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  params?: MaybeRef<GetUnbilledParams>,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getUnbilled>>, TError, TData>
    >
    request?: SecondParameter<typeof customInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetUnbilledQueryKey(accountId, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUnbilled>>> = ({
    signal,
  }) => getUnbilled(accountId, params, requestOptions, signal)

  const customOptions = useQueryOptions(
    { ...queryOptions, queryKey, queryFn },
    { accountId, params },
  )

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getUnbilled>>,
    TError,
    TData
  >
}

export type GetUnbilledQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUnbilled>>
>
export type GetUnbilledQueryError = ErrorType<Error>

/**
 * @summary Retrieve details about current charges present in the next bill, excluding standing charges unless requested.
 */
export const useGetUnbilled = <
  TData = Awaited<ReturnType<typeof getUnbilled>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  params?: MaybeRef<GetUnbilledParams>,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getUnbilled>>, TError, TData>
    >
    request?: SecondParameter<typeof customInstance>
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUnbilledQueryOptions(accountId, params, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}

/**
 * @summary Submit a meter reading
 */
export const submitReadings = (
  accountId: MaybeRef<number>,
  meterReadingWithTechnicalDetails: MaybeRef<
    MeterReadingWithTechnicalDetails[]
  >,
  options?: SecondParameter<typeof customInstance>,
) => {
  accountId = unref(accountId)
  meterReadingWithTechnicalDetails = unref(meterReadingWithTechnicalDetails)

  return customInstance<void>(
    {
      url: `/api/v1/accounts/${accountId}/submitReadings`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: meterReadingWithTechnicalDetails,
    },
    options,
  )
}

export const useSubmitReadingsMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitReadings>>,
    TError,
    { accountId: number; data: MeterReadingWithTechnicalDetails[] },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof submitReadings>>,
  TError,
  { accountId: number; data: MeterReadingWithTechnicalDetails[] },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof submitReadings>>,
    { accountId: number; data: MeterReadingWithTechnicalDetails[] }
  > = (props) => {
    const { accountId, data } = props ?? {}

    return submitReadings(accountId, data, requestOptions)
  }

  const customOptions = useMutationOptions({ ...mutationOptions, mutationFn })

  return customOptions
}

export type SubmitReadingsMutationResult = NonNullable<
  Awaited<ReturnType<typeof submitReadings>>
>
export type SubmitReadingsMutationBody = MeterReadingWithTechnicalDetails[]
export type SubmitReadingsMutationError = ErrorType<Error>

/**
 * @summary Submit a meter reading
 */
export const useSubmitReadings = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitReadings>>,
    TError,
    { accountId: number; data: MeterReadingWithTechnicalDetails[] },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof submitReadings>>,
  TError,
  { accountId: number; data: MeterReadingWithTechnicalDetails[] },
  TContext
> => {
  const mutationOptions = useSubmitReadingsMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Retrieve an accounts energy usage, across a given period, aggregated to a given value.
 */
export const getEnergyUsage = (
  accountId: MaybeRef<number>,
  params: MaybeRef<GetEnergyUsageParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  accountId = unref(accountId)
  params = unref(params)

  return customInstance<EnergyUsageResponse>(
    {
      url: `/api/v1/accounts/${accountId}/energyUsage`,
      method: "GET",
      params: unref(params),
      signal,
    },
    options,
  )
}

export const getGetEnergyUsageQueryKey = (
  accountId: MaybeRef<number>,
  params: MaybeRef<GetEnergyUsageParams>,
) => {
  return [
    "api",
    "v1",
    "accounts",
    accountId,
    "energyUsage",
    ...(params ? [params] : []),
  ] as const
}

export const useGetEnergyUsageQueryOptions = <
  TData = Awaited<ReturnType<typeof getEnergyUsage>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  params: MaybeRef<GetEnergyUsageParams>,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getEnergyUsage>>, TError, TData>
    >
    request?: SecondParameter<typeof customInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetEnergyUsageQueryKey(accountId, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEnergyUsage>>> = ({
    signal,
  }) => getEnergyUsage(accountId, params, requestOptions, signal)

  const customOptions = useQueryOptions(
    { ...queryOptions, queryKey, queryFn },
    { accountId, params },
  )

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getEnergyUsage>>,
    TError,
    TData
  >
}

export type GetEnergyUsageQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEnergyUsage>>
>
export type GetEnergyUsageQueryError = ErrorType<Error>

/**
 * @summary Retrieve an accounts energy usage, across a given period, aggregated to a given value.
 */
export const useGetEnergyUsage = <
  TData = Awaited<ReturnType<typeof getEnergyUsage>>,
  TError = ErrorType<Error>,
>(
  accountId: MaybeRef<number>,
  params: MaybeRef<GetEnergyUsageParams>,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getEnergyUsage>>, TError, TData>
    >
    request?: SecondParameter<typeof customInstance>
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetEnergyUsageQueryOptions(accountId, params, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}
