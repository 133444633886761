<script setup lang="ts">
  import MaintenanceBanner from "@/views/maintenance-banner/MaintenanceBanner.vue"
  import { usePageLevelMaintenance } from "@/views/maintenance-banner/usePageLevelMaintenance"

  const { isUnderMaintenance } = usePageLevelMaintenance()
</script>

<template>
  <main>
    <div v-if="isUnderMaintenance" class="p-6 pb-12">
      <MaintenanceBanner />
    </div>
    <slot />
  </main>
</template>
