<script setup lang="ts">
  import { useStepWizard } from "@/lib/useStepWizard"
  import { toTypedSchema } from "@vee-validate/zod"
  import { useForm } from "vee-validate"
  import { z } from "zod"
  import { Button } from "@/components/ui/button"
  import { Checkbox } from "@/components/ui/inputs/checkbox"
  import { StepWizardProgressCard } from "@/components/ui/step-wizard"

  const formSchema = toTypedSchema(
    z.object({
      requirementsAuthorisation: z.literal<boolean>(true, {
        errorMap: () => ({
          message:
            "You must confirm that you have read and understood the above requirements to continue",
        }),
      }),
    }),
  )

  const { handleSubmit } = useForm({
    validationSchema: formSchema,
  })
  const { goNext } = useStepWizard()
  const onSubmit = handleSubmit(() => {
    goNext()
  })
</script>

<template>
  <StepWizardProgressCard title="Is Smart Pay As You Go Right for You?">
    <p>
      Please confirm you understand these points to ensure Smart Pay As You Go
      is the right choice for your household. Before switching, please confirm
      that:
    </p>

    <ul class="mb-6 mt-8 list-disc px-6">
      <li>
        No one in your household relies on uninterrupted energy supply for
        medical equipment
      </li>
      <li>
        You understand that your supply may pause if your balance runs out
      </li>
      <li>
        You're able to top up your credit as needed via the app, or at a Pay
        Point, as required
      </li>
    </ul>
    <form>
      <Checkbox
        label="I confirm that I’ve read and understood the above requirements"
        name="requirementsAuthorisation"
      />
    </form>
    <template #next_button>
      <Button variant="tonal" size="md" @click="onSubmit">Next</Button>
    </template>
  </StepWizardProgressCard>
</template>
