<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import type { HTMLAttributes } from "vue"

  const props = defineProps<{
    class?: HTMLAttributes["class"]
  }>()
</script>

<template>
  <th :class="cn('pb-3 text-left align-top', props.class)">
    <div class="body-text-strong">
      <slot />
    </div>

    <div v-if="$slots.subheader" class="body-text font-normal">
      <slot name="subheader"></slot>
    </div>
  </th>
</template>
