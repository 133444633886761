<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import {
    SelectContent,
    SelectPortal,
    SelectViewport,
    useForwardPropsEmits,
    type SelectContentEmits,
    type SelectContentProps,
  } from "radix-vue"
  import { computed, type HTMLAttributes } from "vue"

  import { SelectScrollDownButton, SelectScrollUpButton } from "."

  defineOptions({
    inheritAttrs: false,
  })

  const props = withDefaults(
    defineProps<SelectContentProps & { class?: HTMLAttributes["class"] }>(),
    {
      position: "popper",
    },
  )
  const emits = defineEmits<SelectContentEmits>()

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
  })

  const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <SelectPortal>
    <SelectContent
      v-bind="{ ...forwarded, ...$attrs }"
      side="bottom"
      :body-lock="false"
      :avoid-collisions="false"
      :class="
        cn(
          'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 relative z-50 max-h-96 min-w-32 overflow-hidden rounded-sm border text-popover-foreground',
          position === 'popper' &&
            'data-[side=bottom]:translate-y-2 data-[side=left]:-translate-x-2 data-[side=right]:translate-x-2 data-[side=top]:-translate-y-2',
          'bg-secondary-100',
          props.class,
        )
      "
    >
      <SelectScrollUpButton />
      <SelectViewport
        :class="
          cn(
            'p-1',
            position === 'popper' &&
              'h-[--radix-select-trigger-height] w-full min-w-[--radix-select-trigger-width]',
          )
        "
      >
        <slot />
      </SelectViewport>
      <SelectScrollDownButton />
    </SelectContent>
  </SelectPortal>
</template>
