<script setup lang="ts">
  import { Route } from "@/constants/route"
  import { formatDate } from "@/lib/dateHelper"
  import { useSmartPayAsYouGo } from "@/lib/useSmartPayAsYouGo"
  import dayjs from "dayjs"
  import ConfirmationLayout from "@/components/confirmation-layout/ConfirmationLayout.vue"
  import { Button } from "@/components/ui/button"
  import { Link } from "@/components/ui/link"

  const { switchDate } = useSmartPayAsYouGo()
</script>

<template>
  <ConfirmationLayout title="You're all set to switch to Smart Pay As You Go">
    <template #default>
      <p class="mb-6 text-secondary-500 sm:mb-8">
        We'll switch your account on
        {{ formatDate(dayjs(switchDate), "ordinal") }}.
      </p>
      <p class="w-full">What happens next:</p>
      <ol class="mb-6 list-decimal pl-4 sm:mb-8">
        <li>
          Keep an eye out for two emails: one to reset your password and
          activate your online account, and another with steps to complete your
          account setup
        </li>
        <li>
          You can then submit your meter readings and set up a Direct Debit
          online
        </li>
        <li>Explore our fixed tariff options</li>
        <li>
          Don't forget to check your Welcome Pack email for important details.
        </li>
      </ol>
      <p class="w-full">Need to make changes?</p>
      <ol class="b-6 list-disc pl-7 sm:mb-8">
        <li>Change your switch date</li>
        <li>Cancel your switch</li>
        <li>Explore our fixed tariff options</li>
      </ol>
      <p class="mb-6 text-secondary-500">
        If you have any questions, please check our FAQs. You can also reach us
        via email at
        <Link class="link text-primary-600" to="mailto:help@so.energy"
          >help@so.energy</Link
        >
        or chat online with an agent (available weekdays from 9 am to 5 pm).
      </p>
    </template>
    <template #footer>
      <Button size="md" as-child>
        <Link :to="Route.home" data-testid="to-dashboard-btn"
          >Back to Dashboard</Link
        >
      </Button>
    </template>
  </ConfirmationLayout>
</template>
