<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import { HTMLAttributes } from "vue"

  interface AccountCardProps {
    compact?: boolean
    class?: HTMLAttributes["class"]
  }
  const props = withDefaults(defineProps<AccountCardProps>(), {
    compact: false,
  })
</script>

<template>
  <div
    :class="
      cn(
        'flex w-full flex-col rounded border border-primary-400 bg-primary-300',
        props.compact ? 'p-4 sm:p-6' : 'p-6',
        props.class,
      )
    "
  >
    <slot></slot>
  </div>
</template>
