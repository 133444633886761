import { canInitializeDatadog } from "@/plugins/datadog/datadogHelper.ts"
import GoogleTagManager from "@/services/GoogleTagManager.ts"
import { datadogRum } from "@datadog/browser-rum"

/**
 * Generic event for Google Tag Manager
 *
 * Represents a structured event for tracking user interactions in Google Tag Manager.
 *
 * @interface GTMEvent
 *
 * @fields
 * {string} eventName - name of the event (GA4 has a limitation of 40 characters)
 *
 * {string, optional} eventCategory - meter_reading, payment, navigation
 *
 * {string, optional} eventAction - submit, click, view, error, impression
 *
 * {string, optional} entityName - missingReads
 *
 * {string, optional} entityType - smart_meter, payment, faq, statement, file
 *
 * {string, optional} entitySubtype - multi_rate, traditional, online, image, pdf
 *
 * {string, optional} entityVariant - electricity_single_rate, gas_dual_rate, checkout, upload_error
 *
 * {string, optional} statusType - first, opening_reading, last
 *
 * {string, optional} value - any value used for e.g. conversion
 */
export type GenericEventParams = {
  eventCategory?: string
  eventAction?: string
  entityName?: string
  entityType?: string
  entitySubtype?: string
  entityVariant?: string
  statusType?: string
  value?: string
}

export type EventTypes = {
  "login-auth-attempt": {
    email: string
    extendedSession: boolean
  }
  "login-auth-successful": {
    redirectUrl: string | undefined
    loginIntoNexus: boolean
  }
  "password-reset-email-success": {
    email: string
  }
}

type EventTypesWithoutMetadata =
  | "password-reset-update-success"
  | "move-in-typeform-redirect"
  | "redirectToLogin"
  | "loginRedirectSuccessful"
  | "login-fetch-data-successful"

/**
 * @deprecated This method is deprecated, use sendGenericEvent instead
 */
function sendEvent(eventName: EventTypesWithoutMetadata): void

/**
 * @deprecated This method is deprecated, use sendGenericEvent instead
 */
function sendEvent<T extends keyof EventTypes>(
  eventName: T,
  metadata: EventTypes[T],
): void

function sendEvent<T extends keyof EventTypes>(
  eventName: T | string,
  metadata?:
    | EventTypes[T]
    | (GenericEventParams & { datadogMetadata?: EventTypes[T] }),
): void

function sendEvent<T extends keyof EventTypes>(
  eventName: T | string,
  metadata?:
    | EventTypes[T]
    | (GenericEventParams & { datadogMetadata?: EventTypes[T] }),
): void

function sendEvent<T extends keyof EventTypes>(
  eventName: T | string,
  metadata?: GenericEventParams & { datadogMetadata?: EventTypes[T] },
): void

function sendEvent<T extends keyof EventTypes>(
  eventName: T | string,
  metadata?: GenericEventParams & { datadogMetadata?: EventTypes[T] },
): void {
  if (canInitializeDatadog()) {
    datadogRum.addAction(eventName, {
      ...(metadata ?? {}),
      ...(metadata?.datadogMetadata ?? {}),
    })
  }
  GoogleTagManager.sendGenericEvent({ eventName: eventName, ...metadata })
}

export default {
  sendEvent,
}
