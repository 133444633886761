<script setup lang="ts">
  import { useGetAccount, useGetAgreements } from "@/api/account/account"
  import {
    AgreementsResponseFuelType,
    SmartPayAsYouGoQuoteResponse,
  } from "@/api/model"
  import { useCreateSmartPayAsYouGoQuote } from "@/api/smart-pay-as-you-go/smart-pay-as-you-go"
  import { aggregateQueries } from "@/lib/aggregateQueries"
  import { formatDate } from "@/lib/dateHelper"
  import { useGetAccountId } from "@/lib/useGetAccountId"
  import { useSmartPayAsYouGo } from "@/lib/useSmartPayAsYouGo"
  import { useStepWizard } from "@/lib/useStepWizard"
  import dayjs from "dayjs"
  import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
  import { computed, ref, watch } from "vue"
  import ErrorMessage from "@/components/ErrorMessage.vue"
  import { Button } from "@/components/ui/button"
  import { StepWizardProgressCard } from "@/components/ui/step-wizard"
  import TableSkeleton from "@/components/ui/table/TableSkeleton.vue"

  import SpaygFaq from "./SpaygFaq.vue"
  import SpaygTariffTable from "./SpaygTariffTable.vue"

  dayjs.extend(isSameOrAfter)

  const {
    switchDate,
    selectedMeterpointIdentifiers,
    spaygRequest,
    spaygTariffName,
  } = useSmartPayAsYouGo()

  const { accountId } = useGetAccountId()
  const account = useGetAccount(accountId)
  const agreements = useGetAgreements(accountId)

  const spaygQuoteMutation = useCreateSmartPayAsYouGoQuote()

  const { isLoading, isError } = aggregateQueries([account, agreements])

  const spaygQuote = ref<SmartPayAsYouGoQuoteResponse>()
  const isSpaygQuoteError = ref(false)

  const selectedAgreements = computed(() =>
    agreements.data.value?.filter(
      (agreement) =>
        agreement.meterpointIdentifier &&
        selectedMeterpointIdentifiers.value.includes(
          agreement.meterpointIdentifier.toString(),
        ),
    ),
  )

  const isTableLoading = computed(
    () => isLoading.value || spaygQuoteMutation.isPending.value,
  )

  watch(
    switchDate,
    async (val) => {
      if (!val) return

      try {
        isSpaygQuoteError.value = false
        spaygQuote.value = await spaygQuoteMutation.mutateAsync({
          accountId: accountId.value,
          data: { activeStartDate: formatDate(val, "api", "default") },
        })
        isSpaygQuoteError.value = !spaygQuote.value
      } catch {
        isSpaygQuoteError.value = true
      }
    },
    { immediate: true },
  )

  const { goNext, goBack } = useStepWizard()

  const onSubmit = () => {
    if (!spaygQuote.value) return

    spaygRequest.value = (selectedAgreements.value || []).map((agreement) => ({
      executionDate: switchDate.value!,
      meterPointIdentifier: agreement.meterpointIdentifier!.toString(),
      productRef:
        agreement.fuelType === AgreementsResponseFuelType.Electricity
          ? spaygQuote.value!.elecProductCode || ""
          : spaygQuote.value!.gasProductCode || "",
    }))

    spaygTariffName.value = spaygQuote.value.tariffName

    goNext()
  }
</script>

<template>
  <StepWizardProgressCard title="Understanding your new tariff">
    <div class="mb-6 sm:mb-8">
      <h3 class="h4 sm:mb-1">So Pay As You Go:</h3>
      <p>Here's how your rates will change:</p>
    </div>

    <ErrorMessage v-if="isSpaygQuoteError || isError" text-size="small" />
    <TableSkeleton v-else-if="isTableLoading" :number-of-rows="6" />
    <SpaygTariffTable
      v-else
      :spayg-quote="spaygQuote"
      :agreements="selectedAgreements"
    />

    <template #footer>
      <template v-if="!isTableLoading">
        <Button variant="outline" size="md" @click.stop="goBack"> Back </Button>
        <Button variant="tonal" size="md" @click="onSubmit"
          >Select Tariff</Button
        >
      </template>
      <div v-else />
    </template>
  </StepWizardProgressCard>

  <SpaygFaq />
</template>
