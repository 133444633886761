/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Portal API Gateway
 * OpenAPI spec version: 2.0.0
 */

export type ErrorCode = (typeof ErrorCode)[keyof typeof ErrorCode]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ErrorCode = {
  invalidCredentials: "invalidCredentials",
  accountLocked: "accountLocked",
  unknownError: "unknownError",
  readSubmissionError: "readSubmissionError",
  invalidBankingDetails: "invalidBankingDetails",
  invalidFieldFormat: "invalidFieldFormat",
  notFound: "notFound",
  NUMBER_100: "100",
  NUMBER_101: "101",
  NUMBER_001: "001",
  NUMBER_002: "002",
  NUMBER_003: "003",
  NUMBER_004: "004",
  NUMBER_999: "999",
  NUMBER_200: "200",
  NUMBER_102: "102",
  NUMBER_103: "103",
  NUMBER_104: "104",
  NUMBER_105: "105",
  NUMBER_106: "106",
  NUMBER_107: "107",
  NUMBER_108: "108",
  NUMBER_109: "109",
  NUMBER_110: "110",
  NUMBER_111: "111",
  NUMBER_112: "112",
  NUMBER_113: "113",
  NUMBER_114: "114",
  NUMBER_115: "115",
  NUMBER_117: "117",
  NUMBER_118: "118",
  NUMBER_119: "119",
  NUMBER_120: "120",
  NUMBER_121: "121",
  NUMBER_122: "122",
  NUMBER_123: "123",
  NUMBER_124: "124",
  NUMBER_125: "125",
  NUMBER_126: "126",
  NUMBER_127: "127",
  NUMBER_400: "400",
  NUMBER_404: "404",
  NUMBER_429: "429",
  NUMBER_503: "503",
  NUMBER_500: "500",
} as const
