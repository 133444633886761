<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import {
    SelectItem,
    SelectItemText,
    useForwardProps,
    type SelectItemProps,
  } from "radix-vue"
  import { computed, type HTMLAttributes } from "vue"

  const props = defineProps<
    SelectItemProps & {
      class?: HTMLAttributes["class"]
    }
  >()

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
  })

  const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <SelectItem
    v-bind="forwardedProps"
    :class="
      cn(
        'label-text relative mb-2 flex cursor-default select-none items-center rounded-full border border-transparent px-4 py-1 outline-none last:mb-0',
        'data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
        'hover:bg-secondary-300 hover:data-[highlighted]:data-[state=checked]:bg-secondary-700',
        'data-[highlighted]:bg-secondary-300 data-[highlighted]:data-[state=checked]:bg-secondary-700 data-[state=checked]:bg-secondary-700 data-[state=checked]:text-secondary-100',
        props.class,
      )
    "
  >
    <SelectItemText>
      <slot />
    </SelectItemText>
  </SelectItem>
</template>
