import { useGetAlertsAndBanners } from "@/api/alerts/alerts"
import { AlertName } from "@/api/model/alertName"
import { useGetAccountId } from "@/lib/useGetAccountId"
import { useLocalStorage } from "@vueuse/core"
import { computed, watch } from "vue"

interface SeenAlertRecords {
  [alertName: string]: boolean
}
export interface SeenAlerts {
  [accountId: number]: SeenAlertRecords
}

const useAlertReadStatus = () => {
  const { accountId } = useGetAccountId()
  const { data } = useGetAlertsAndBanners(accountId)

  const alerts = computed(() => data.value?.alerts)

  const seenAlerts = useLocalStorage<SeenAlerts>("seenAlerts", {})

  const unreadAlertCount = computed(() => {
    const currentAlerts = alerts.value
    if (!currentAlerts) return 0

    const unread = currentAlerts.filter(
      (alert) => !seenAlerts.value[accountId.value]?.[alert.name],
    )
    return unread.length
  })

  const markAlertAsRead = (alertName: string) => {
    if (!seenAlerts.value[accountId.value]) {
      seenAlerts.value[accountId.value] = {}
    }
    seenAlerts.value[accountId.value][alertName] = true
  }

  // Remove locally stored alerts that are no longer reported by the backend
  watch(
    alerts,
    (currentAlerts) => {
      const accountSeenAlerts = seenAlerts.value[accountId.value]
      if (!currentAlerts || !accountSeenAlerts) return

      const currentAlertNames = currentAlerts.map((alert) => alert.name)

      Object.keys(accountSeenAlerts).forEach((name) => {
        if (!currentAlertNames.includes(name as AlertName)) {
          delete accountSeenAlerts[name]
        }
      })
    },
    { immediate: true },
  )

  return {
    unreadAlertCount,
    markAlertAsRead,
  }
}

export default useAlertReadStatus
