import {
  ExternalRoute as ExternalRouteDefinitions,
  type ExternalRoute,
} from "@/constants/externalRoute.ts"
import { Route as RouteDefinitions, type Route } from "@/constants/route.ts"

export const isInternalRoute = (routeName: string): routeName is Route =>
  Object.entries(RouteDefinitions).some(([_, value]) => value === routeName)

export function isExternalRoute(route: string): route is ExternalRoute {
  // make sure query params don't interfere with route matching
  const url = route.split("?")[0]

  return !!Object.values(ExternalRouteDefinitions).find((item) => item === url)
}
